import {MdCheck, MdClose, MdPriorityHigh} from "react-icons/md";
import "../../styles/tags-modal.scss";
import {useTheme} from "../../Providers/ThemeContext";
import {useTranslation} from "react-i18next";

const MessageModal = ({
    isOpen,
    handleCloseModal,
    heading,
    message,
    mode
}) => {

    const theme = useTheme();

    const {t} = useTranslation();

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className={`modal ${theme.getThemeClass()} message-modal`}>
            <div className="modal-header">
              <div className="title">
                  {t("message")}
              </div>
              <div className="close-btn" onClick={handleCloseModal}>
                <MdClose />
              </div>
            </div>
              <div className="modal-body">
                  <div className="insights-container">
                      <div className={`insights-icon ${mode}`}>
                          {
                              mode === 'success' ?
                                  <MdCheck /> :
                                  <MdPriorityHigh />
                          }
                      </div>
                      <div className="insights-message">
                          <div className="header">
                              {heading}
                          </div>
                          <div className="message">
                              {message}
                          </div>
                      </div>
                  </div>
              </div>
              <div className="modal-footer">
                  <button className="secondary-btn" onClick={handleCloseModal}>
                    Ok
                  </button>
              </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageModal;
