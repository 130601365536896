import { MdContentCopy } from "react-icons/md";
import {FaFacebookF, FaRegEnvelope, FaTwitter} from 'react-icons/fa';
import Checkbox from "../checkbox";
import Modal from "./Modal";
import { useCallback, useEffect, useState } from "react";
import {toast} from 'react-toastify';
import {sendGet, sendPost} from '../../../common/request';
import Urls from '../../../common/links';
import Loader from '../Loader';
import {useTranslation} from "react-i18next";

function TradeShareModal({
    tradeId,
    subAccountId,
    isShown,
    onClose
}) {
    
    const [isLoading, setIsLoading] = useState(false);
    const [showNetPNL, setShowNetPNL] = useState(false);
    const [showTradeNotes, setShowTradeNotes] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [showPlaybooks, setShowPlaybooks] = useState(false);
    const [showEntryExitImages, setShowEntryExitImages] = useState(false);
    const [isTradeShared, setIsTradeShared] = useState(false);
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const url = `${baseUrl}/share/trade/${subAccountId}/${tradeId}`
    const {t} = useTranslation();

    const getSharedTrade = useCallback(async () => {
        if(tradeId && subAccountId) {
            setIsLoading(true)
            const response = await sendGet(Urls.GetSharedTrade(tradeId, subAccountId), false);
            if(response.error) {
                setIsTradeShared(false);
            } else {
                const trade = response.trade;
                setShowNetPNL(trade.showNetPNL);
                setShowTradeNotes(trade.showTradeNotes);
                setShowTags(trade.showTags);
                setShowPlaybooks(trade.showPlaybooks);
                setShowEntryExitImages(trade.showEntryExitImages)
                setIsTradeShared(true);
            }
            setIsLoading(false)
        }
    }, [tradeId, subAccountId])

    useEffect(() => {
        getSharedTrade();
    }, [getSharedTrade, isShown])

    useEffect(() => {
        setShowNetPNL(false);
        setShowTradeNotes(false);
        setShowTags(false);
        setShowPlaybooks(false);
        setShowEntryExitImages(false);
    }, [isShown])

    const copyUrl = () => {
        navigator.clipboard.writeText(url);
        toast.info('Url copied to clipboard')
    }

    const onShareTrade = async () => {
        setIsLoading(true);
        const response = await sendPost(Urls.ShareTrade(tradeId, subAccountId), {
            showNetPNL: showNetPNL,
            showPlaybooks: showPlaybooks,
            showTradeNotes: showTradeNotes,
            showTags: showTags,
            showEntryExitImages: showEntryExitImages,
        }, true, 'application/json', true);
        if(response.error) {
            toast.error(response.error);
        } else {
            toast.success('Trade Shared Successfully');
            onClose(true);
        }
        setIsLoading(false);
    }

    const onStopShareTrade = async () => {
        setIsLoading(true);
        const response = await sendPost(Urls.StopShareTrade(tradeId, subAccountId), {}, true, '', false);
        if(response.error) {
            toast.error(response.error);
        } else {
            toast.success('Trade Stopped from Sharing Successfully');
            onClose(true);
        }
        setIsLoading(false);
    }

    return (
        <Modal isShown={isShown} onClose={onClose} title={t("shareTradeModal.title")} className={'share-modal'}>
            <Modal.Body>
                <div className="body-content-container">
                    {
                        isLoading ? 
                            <div className={'mt-2'}>
                                <Loader text={'Hold Tight! We are loading your data'} height={30} width={30} />
                            </div> :
                            <>
                                <div className="heading">{t("shareTradeModal.description")}</div>
                                <div className="sub-heading">{t("shareTradeModal.tagline")}</div>
                                <div className="privacy-elements-container">
                                    <Checkbox title={t("shareTradeModal.options.netPnL")} value={showNetPNL}
                                              onChange={value => setShowNetPNL(value)}/>
                                    <Checkbox title={t("shareTradeModal.options.images")} value={showEntryExitImages}
                                              onChange={value => setShowEntryExitImages(value)}/>
                                    <Checkbox title={t("shareTradeModal.options.tradeNotes")} value={showTradeNotes}
                                              onChange={value => setShowTradeNotes(value)}/>
                                    <Checkbox title={t("shareTradeModal.options.tags")} value={showTags}
                                              onChange={value => setShowTags(value)}/>
                                    <Checkbox title={t("shareTradeModal.options.playbooks")} value={showPlaybooks}
                                              onChange={value => setShowPlaybooks(value)}/>
                                </div>
                                <div className="link-container">
                                    <div className="heading">{t("shareTradeModal.tradeLink")}</div>
                                    <div className="link-item">
                                        <div className="text">{url}</div>
                                        <div className="icon" onClick={copyUrl}>
                                            <MdContentCopy/>
                                        </div>
                                    </div>
                                </div>
                                <div className="social-media-container">
                                    <div className="heading">{t("shareTradeModal.socialMedia")}</div>
                                    <div className="social-media-icons">
                                        <div className="social-media-icon facebook">
                                            <FaFacebookF/>
                                        </div>
                                        <div className="social-media-icon twitter">
                                            <FaTwitter/>
                                        </div>
                                        <div className="social-media-icon email">
                                            <FaRegEnvelope/>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="footer">
                    <div className="button-container">
                        {
                            isTradeShared ?
                                <div className="danger-btn" onClick={onStopShareTrade}>
                                    {t("shareTradeModal.stopSharing")}
                                </div> : <div></div>
                        }
                        <div className="horizontal-container">
                            <div className="secondary-btn" onClick={() => onClose(false)}>
                                {t("shareTradeModal.cancelBtn")}
                            </div>
                            <div className="primary-btn" onClick={onShareTrade}>
                                {t("shareTradeModal.shareBtn")}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default TradeShareModal;