import Atas from "../../images/atas-logo.png";
import Rithmic from '../../images/rithmic-logo.png';
import CQG from '../../images/cqg-logo.png'
import Ninja from '../../images/ninja-logo.png'
import Line from "./Line";
import {useTranslation} from "react-i18next";
import {MdEast} from "react-icons/md";
import {useNavigate} from "react-router-dom";

const BrokersSection = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="broker-section front">
            <div className="content">
                <div className="title">
                    <div className="sub-heading"><span className="text">{t("landing_page.brokers_section.subTitle")}</span></div>
                    <div className="heading">{t("landing_page.brokers_section.title")}</div>
                    <div className="description">
                        {t("landing_page.brokers_section.description")}
                    </div>
                    <div className="button filled-btn mt-2" onClick={() => navigate('/brokers')}>
                        {t("landing_page.hero_section.view_more_button")} <MdEast />
                    </div>
                </div>
                <div className="brokers">
                    <div className="box-grid">
                        <Line className="line" />
                        <div className="row">
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                        </div>
                        <div className="row">
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                        </div>
                        <div className="row">
                            <div className="box"></div>
                            <div className="box box-2">
                                <div className="box-content">
                                    <img src={Rithmic} alt={'Logo'} />
                                    Rithmic
                                </div>
                            </div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                        </div>
                        <div className="row">
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box box-2">
                                <div className="box-content">
                                    <img src={Atas} alt={'Logo'}/>
                                    ATAS
                                </div>
                            </div>
                            <div className="box"></div>
                            <div className="box"></div>
                        </div>
                        <div className="row">
                            <div className="box"></div>
                            <div className="box box-3">
                                <div className="box-content">
                                    <img src={Ninja} alt={'Logo'}/>
                                    NINJA TRADER
                                </div>
                            </div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                        </div>
                        <div className="row">
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box box-2">
                                <div className="box-content">
                                    <img src={CQG} alt={'Logo'}/>
                                    CQG
                                </div>
                            </div>
                            <div className="box"></div>
                            <div className="box"></div>
                        </div>
                        <div className="row">
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                        </div>
                        <div className="row">
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                            <div className="box"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrokersSection;