import '../styles/outer.scss';
import '../styles/common.scss';
import LoginImage from '../images/LoginImage.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {sendPost} from '../../common/request';
import links from '../../common/links';
import Loader from '../components/Loader';

function TwoFactorAuthenticationPage() {
    let navigation = useNavigate();
    let {purpose, email} = useParams();
    let [otpCode, setOtpCode] = useState();
    let [error, setError] = useState('');
    let [currentTime, setCurrentTime] = useState(60);
    let [canResendCode, setCanResendCode] = useState(false);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let interval = setInterval(() => {
            if(currentTime > 0) {
                setCurrentTime(currentTime - 1);
            } else {
                setCanResendCode(true);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    })

    let resendCode = async () => {
        if(!email) {
            setError('Invalid Email');
            return;
        }
        setError('');
        setIsLoading(true);
        let response = await sendPost(links.SendTwoFactorCode, {email: email, purpose: purpose}, false);

        if(response.error) {
            setError(response.error);
        } else {
            setCanResendCode(false);
            setCurrentTime(60);
        }
        setIsLoading(false);
    }

    let verifyCode = async () => {
        if(!email) {
            setError('Invalid Email');
            return;
        } else if(!otpCode || otpCode.length !== 6) {
            setError('Invalid OTP Code');
            return;
        }

        setError('')
        setIsLoading(true);
        let response = await sendPost(links.VerifyCode, {email: email, code: otpCode, purpose: purpose}, false);
        if(response.error) {
            setError(response.error);
        } else {
            if(purpose === 'reset') {
                navigation(`/reset-password/${email}/${otpCode}`)
            }
            else if (purpose === 'register') {
                navigation(`/login`)
            }
        }
        setIsLoading(false);
    }

    return (
        <div className="page-outer">
            <div className='body'>
                <div className='content'>
                    <div className='rect'></div>
                    <img src={LoginImage} />
                </div>
                <div className='controls'>
                    <div className='title'>
                        Two Factor Authentication
                    </div>
                    <div className='input-group'>
                        <label>Authentication Code</label>
                        <input type='text' placeholder='Enter code' value={otpCode} onChange={e => setOtpCode(e.target.value)}/>
                    </div>
                    {
                        error ? 
                        <div className='mt-1 mb-3 error'>
                            {error}
                        </div> : <></>
                    }
                    <div className='input-group horizontal'>
                        <div className='note dark'>
                        {
                            canResendCode ? <></> : <> Resend Again in {currentTime}s</>
                        }
                        </div>
                        {
                            canResendCode ? 
                            <div className='note right-aligned link' onClick={resendCode}>
                                Resend Code
                            </div> : <></>
                        }
                    </div>
                    <div className='input-group'>
                        <div className='btn btn-primary' onClick={verifyCode}>
                            {
                                isLoading ? <Loader color="white" height={20} barWidth={3}/> : 'Verify'
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TwoFactorAuthenticationPage;