import {useState} from "react";
import {useTranslation} from "react-i18next";

const IntegrationSection = () => {

    const {t} = useTranslation();
    const brokers = [
        t("brokersPage.integrationSection.brokers.0.title"),
        t("brokersPage.integrationSection.brokers.1.title"),
        t("brokersPage.integrationSection.brokers.2.title"),
        t("brokersPage.integrationSection.brokers.3.title"),
        t("brokersPage.integrationSection.brokers.4.title")
    ];
    const [currentBroker, setCurrentBroker] = useState(brokers[0]);

    const integrationData = {
        [brokers[0]]: {
            overview: t("brokersPage.integrationSection.brokers.0.overview"),
            visitPartner: t("brokersPage.integrationSection.brokers.0.visitPartner"),
            supports: [
                t("brokersPage.integrationSection.brokers.0.supports.0"), t("brokersPage.integrationSection.brokers.0.supports.1"), t("brokersPage.integrationSection.brokers.0.supports.2")
            ],
            methods: [
                t("brokersPage.integrationSection.brokers.0.methods.0"), t("brokersPage.integrationSection.brokers.0.methods.1")
            ]
        },
        [brokers[1]]: {
            overview: t("brokersPage.integrationSection.brokers.1.overview"),
            visitPartner: t("brokersPage.integrationSection.brokers.1.visitPartner"),
            supports: [
                t("brokersPage.integrationSection.brokers.1.supports.0")
            ],
            methods: [
                t("brokersPage.integrationSection.brokers.1.methods.0")
            ]
        },
        [brokers[2]]: {
            overview: t("brokersPage.integrationSection.brokers.2.overview"),
            visitPartner: t("brokersPage.integrationSection.brokers.2.visitPartner"),
            supports: [
                t("brokersPage.integrationSection.brokers.2.supports.0")
            ],
            methods: [
                t("brokersPage.integrationSection.brokers.2.methods.0")
            ]
        },
        [brokers[3]]: {
            overview: t("brokersPage.integrationSection.brokers.3.overview"),
            visitPartner: t("brokersPage.integrationSection.brokers.3.visitPartner"),
            supports: [
                t("brokersPage.integrationSection.brokers.3.supports.0")
            ],
            methods: [
                t("brokersPage.integrationSection.brokers.3.methods.0"), t("brokersPage.integrationSection.brokers.3.methods.1")
            ]
        },
        [brokers[4]]: {
            overview: t("brokersPage.integrationSection.brokers.4.overview"),
            visitPartner: t("brokersPage.integrationSection.brokers.4.visitPartner"),
        },
    }

    return (
        <div className='integrations-section front'>
            <div className="content">
                <div className="heading">{t("brokersPage.integrationSection.title")}</div>
                <div className="integration-container">
                    <div className="tabs">
                        {
                            brokers.map((broker, index) => {
                                return (
                                    <div className={`tab-container ${broker === currentBroker ? 'active' : ''}`} key={index} onClick={() => setCurrentBroker(broker)}>
                                        <div className="tab">
                                            {broker}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="tab-content">
                        {
                            integrationData[currentBroker] ?
                                <>
                                    <div className="item">
                                        <div className="header">Overview:</div>
                                        <div className="text" dangerouslySetInnerHTML={{__html: integrationData[currentBroker].overview}}></div>
                                    </div>
                                    <div className="item">
                                        <div className="header">Visit Partner:</div>
                                        <a href={integrationData[currentBroker].visitPartner} className="text">{integrationData[currentBroker].visitPartner}</a>
                                    </div>
                                    {
                                        integrationData[currentBroker].supports ?
                                            <div className="item">
                                                <div className="header">Support:</div>
                                                <div className="tags">
                                                    {
                                                        integrationData[currentBroker].supports.map(t => {
                                                            return (
                                                                <div className="tag">{t}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : <></>
                                    }
                                    {
                                        integrationData[currentBroker].methods ?
                                            <div className="item">
                                                <div className="header">Method:</div>
                                                <div className="tags">
                                                    {
                                                        integrationData[currentBroker].methods.map(t => {
                                                            return (
                                                                <div className="tag">{t}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> : <></>
                                    }
                                </> : <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IntegrationSection