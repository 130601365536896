import { useEffect, useState } from "react";
import {
  MdAdd,
  MdCalendarMonth,
  MdChevronLeft,
  MdChevronRight,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import links from "../../common/links";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import createHeader from  '../../redux/header/createHeader';
import NoResultImage from "../images/no-result.png"
import {useTheme} from "../Providers/ThemeContext";
import permissions from "../data/Permissions.json"
import ActionPermission from "../components/permissions/ActionPermission";
import useSubAccount from "../hooks/useSubAccount";
import {sendGet} from "../../common/request";
import currencyFormat from "../../common/format";

function DailyJournal() {

  const selectedDate = useSelector((state) => state.date.selectedDate);
  const endDate = useSelector((state) => state.date.endDate);
  const dispatch = useDispatch();
  const subAccount = useSubAccount();
  let navigation = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [data, setData] = useState([]);
  const theme = useTheme();
  let { t } = useTranslation();
  const [groupedData, setGroupedData] = useState({});

  const handleDecrement = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(newDate);
  };

  const handleIncrement = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(newDate);
  };

  useEffect(() => {
    if(subAccount) {
      fetchData();
    }
  }, [subAccount, selectedDate, endDate, currentDate]);

  async function fetchData() {
    try {
      const d = new Date(currentDate);
      const response = await sendGet(links.GetMonthData(subAccount.id, {date: d, orderBy: 'closeTime', orderDirection: 'DESC'}), true);
      if(response.error) {
        console.log(response.error)
      }
      else {
        setData(response.trades);
      }
    } catch (error) {
      console.error("Error fetching sub accounts:", error);
    }
  }

  const groupDataByDate = (data) => {
    const groupedData = {};
    data.forEach((e) => {
      let time = new Date(e.data.CloseTime ? e.data.CloseTime : e.data.OpenTime);
      if (time.getMonth() === currentDate.getMonth() && time.getFullYear() === currentDate.getFullYear()) {
        const dateKey = time.toDateString();
        if (!groupedData[dateKey]) {
          groupedData[dateKey] = [];
        }
        groupedData[dateKey].push(e);
      } else {
        console.log("not equal");
      }
    });
    return groupedData;
  };

  dispatch(createHeader(t("daily_journal_page"), true, false))

  const getMonth = (date) => {
    let months = [
      t("month_jan"),
      t("month_feb"),
      t("month_mar"),
      t("month_apr"),
      t("month_may"),
      t("month_jun"),
      t("month_jul"),
      t("month_aug"),
      t("month_sep"),
      t("month_oct"),
      t("month_nov"),
      t("month_dec"),
    ];
    return months[date.getMonth()];
  };

  const getDay = (date) => {
    let days = [
      t("day_sunday"),
      t("day_monday"),
      t("day_tuesday"),
      t("day_wednesday"),
      t("day_thursday"),
      t("day_friday"),
      t("day_saturday"),
    ];
    return days[date.getDay()];
  };

  const renderCard = (date, entries) => {
    const netPnL = parseFloat(entries.reduce((total, e) => total + parseFloat(e?.data?.PnL), 0).toFixed(2));
    let newDate = moment(date).toDate();
    let parsedDate = moment(date);

    return (
      <div key={date} className={`rounded-2xl cursor-pointer h-[100%] border shadow-sm hover:shadow-lg transition duration-300 ease-in-out lg:p-3 xl:p-5 ${theme.theme === 'dark' ? 'border-gray-500 shadow-gray-700 hover:shadow-gray-600' : 'border-gray-100'}`} onClick={() =>
          navigation(`/daily-journal/${parsedDate.format("DD-MM-YYYY")}`)
      }>
        <div className="h-[100%] p-3 xl:p-4 flex flex-col justify-between align-center">
          <div className="flex justify-between items-center mb-3">
            <p className={`font-bold text-base ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
              {getDay(newDate)}, {parsedDate.format("DD MMM YYYY")}
            </p>
            <p className={`text-base rounded-full flex items-center justify-center font-bold cursor-pointer border-none p-1 ${theme.theme === 'dark' ? 'text-gray-100 bg-gray-500' : 'text-gray-700 bg-gray-300'}`}>
              <MdChevronRight />
            </p>
          </div>
          <div className="grid grid-cols-2 lg:gap-x-[0.75em] xl:gap-x-[1em]">
            <div className="flex flex-col gap-2">
              <p className={`${theme.theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                {t("daily_journal_net_pnl")}:{" "}
              </p>
              <p className={`${netPnL > 0 ? "text-green-300" : netPnL === 0 ? (theme.theme === 'dark' ? 'text-gray-200' : 'text-black') : "text-red-300"}`}
                style={{
                  fontWeight: "800",
                  fontSize: "1rem",
                }}
              >
                {currencyFormat.format(netPnL)}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className={`${theme.theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                {t("daily_journal_total_trades")}:
              </p>
              <p className={`${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                {entries.length}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setGroupedData(groupDataByDate(data))
  }, [data]);

  return (
    <div className="page-body">
          <div className="flex items-center justify-end sm:justify-between">
            <div className="flex-col sm:flex-row flex w-full gap-4 justify-end items-end">
              <div className="flex items-center justify-center gap-2">
                <button
                    className={`text-base p-2 rounded-full bg-lightgray flex items-center justify-center font-light cursor-pointer border-none ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}
                    onClick={handleDecrement}>
                  <MdChevronLeft/>
                </button>
                <span
                    className={`flex items-center p-2 border-2 border-primary-500 rounded gap-2 w-[200px] font-light justify-between ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}>
                      <MdCalendarMonth className="text-primary-500"/>
                      {moment(currentDate).format("MMMM YYYY")}
                </span>
                <button
                    className={`text-base p-2 rounded-full bg-lightgray flex items-center justify-center font-light cursor-pointer border-none ${theme.theme === 'dark' ? 'text-gray-200' : 'text-black'}`}
                    onClick={handleIncrement}>
                  <MdChevronRight/>
                </button>
              </div>
              <ActionPermission className="btn btn-primary" onClick={() => navigation("/addtrade")}
                                permission={permissions.PAGE.TRADE_IMPORT}>
                <MdAdd/>
                {t("add_trade_btn")}
              </ActionPermission>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8 mr-8">
            {groupedData && Object.keys(groupedData).length > 0 ? (
                Object.entries(groupedData)
                    .sort((d1, d2) => {
                      return new Date(d2[0]) - new Date(d1[0])
                    })
                    .map(([date, entries]) => {
                      return renderCard(date, entries);
                    })
            ) : (
                <div>
                  <div className="flex flex-col items-center justify-center w-full ">
                    <img src={NoResultImage} alt="No result"/>
                    <p className={`${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-600'}`}>
                      {t("no_data_found_message")}{" "}
                      <strong>
                        {getMonth(currentDate)} {currentDate.getFullYear()}
                      </strong>
                    </p>
                  </div>
                </div>
            )}
          </div>
        </div>
  );
}

export default DailyJournal;
