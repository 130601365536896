import {Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import ThemeContext from "../Providers/ThemeContext";
import {fetchingUser, setError, setUser} from "../../redux/userActions.js/action";
import links from "../../common/links";
import moment from "moment";
import i18next from "i18next";
import {deleteToken} from "../../common/token";
import fr from 'moment/locale/fr';
import ar from 'moment/locale/ar';
import de from 'moment/locale/de';
import {sendGet} from "../../common/request";
import Urls from "../../common/links";
import {getPackages, setPackages, setPackagesError} from "../../redux/data-actions/action";

const AuthenticationLayout = () => {

    const dispatch = useDispatch();
    const themeContext = useContext(ThemeContext);

    async function fetchAccounts() {
        try {
            dispatch(fetchingUser());
            let token = localStorage.getItem("token");
            const response = await fetch(links.GetUser, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                if(data.user.language === 'en') {
                    moment.locale(data.user.language);
                } else if(data.user.language === 'de') {
                    moment.locale(data.user.language, de);
                } else if(data.user.language === 'fr') {
                    moment.locale(data.user.language, fr);
                } else if(data.user.language === 'ar') {
                    moment.locale(data.user.language, ar);
                }

                themeContext.changeTheme(data.user.theme);
                i18next.changeLanguage(data.user.language);
                dispatch(
                    setUser({
                        user: data.user,
                    })
                );
            } else if (response.status === 401) {
                deleteToken()
                dispatch(setError('Unauthorized'))
            } else {
                console.log(response);
                dispatch(setError(response.error));
            }
        } catch (error) {
            console.log("Error fetching accounts:", error);
            dispatch(setError(error.message));
        }
    }

    const loadPackages = async () => {
        dispatch(getPackages());
        const response = await sendGet(Urls.GetPackages, false);
        console.log(response)
        if(response.error) {
            dispatch(setPackagesError(response.error))
        }
        else {
            dispatch(setPackages(response.packages));
        }
    }

    useEffect(() => {
        fetchAccounts();
        loadPackages();
    }, [])

    return (
        <Outlet/>
    )
}

export default AuthenticationLayout;