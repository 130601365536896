import "../styles/privacy.scss";
import {useNavigate} from "react-router-dom";
import {MdWest} from "react-icons/md";
import ScrollToTop from "../components/landing-page/ScrollToTop";
import Navbar from "../components/landing-page/Navbar";
import Footer from "../components/landing-page/Footer";
import {getArray} from "../../common/methods";
import {useTranslation} from "react-i18next";

const TermsAndConditionsPage = () => {
    const navigate = useNavigate();
    const page = "termsConditionsPage"
    const {t} = useTranslation();

    return (
        <div className="website-page">
            <ScrollToTop/>
            <Navbar/>
            <div className="privacy-policy-container">
                <div className="privacy-policy">
                    <div className="header">
                        <button onClick={() => navigate(-1)} className="back-button">
                            <MdWest/>
                            {t("back_button")}
                        </button>
                        <h1 className="page-heading">{t(`${page}.title`)}</h1>
                    </div>
                    <div className="content">
                        <section>
                            {
                                getArray(t("privacyPolicyPage.text.length")).map(i => {
                                    return (
                                        <p>{t(`privacyPolicyPage.text.${i}`)}</p>
                                    )
                                })
                            }
                        </section>
                        {
                            getArray(t(`${page}.sections.length`)).map(sec => {
                                return (
                                    <section>
                                        <h2>{t(`${page}.sections.${sec}.heading`)}</h2>
                                        {
                                            getArray(t(`${page}.sections.${sec}.text.length`)).map(desc => {
                                                return (
                                                    <p>{t(`${page}.sections.${sec}.text.${desc}`)}</p>
                                                )
                                            })
                                        }
                                        {
                                            getArray(t(`${page}.sections.${sec}.subSections.length`)).map(subSec => {
                                                return (
                                                    <div className="sub-section">
                                                        <p className="heading">{t(`${page}.sections.${sec}.subSections.${subSec}.heading`)}</p>
                                                        {
                                                            getArray(t(`${page}.sections.${sec}.subSections.${subSec}.text.length`))
                                                                .map(text => {
                                                                    return (
                                                                        <p>{t(`${page}.sections.${sec}.subSections.${subSec}.text.${text}`)}</p>
                                                                    )
                                                                })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </section>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default TermsAndConditionsPage