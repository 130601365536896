import {
    MdArrowDownward,
    MdArrowUpward,
    MdChevronLeft,
    MdCreateNewFolder,
    MdDelete,
    MdSort,
    MdExpandMore,
    MdMoreHoriz,
    MdNoteAdd,
    MdEdit
} from 'react-icons/md';
import "../styles/notebook.scss";
import Checkbox from "../components/checkbox";
import Select from "../components/selectcontrol";
import RichTextEditor from "../components/RichTextEditor";
import { useCallback, useEffect, useState } from 'react';
import FolderItem from '../components/folder-item';
import FolderCreateModal from '../components/Modal/FolderCreateModal';
import {sendDelete, sendGet, sendPost, sendPut} from '../../common/request';
import Urls from '../../common/links';
import Loader from '../components/Loader';
import moment from 'moment';
import DateInput from '../components/DateInput';
import useSubAccount from "../hooks/useSubAccount";
import currencyFormat from '../../common/format';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../redux/header/action';
import {getGrossPnL, getNetROI} from "../../common/methods";
import momentTimeZone from "moment-timezone";
import {useTheme} from "../Providers/ThemeContext";
import {toast} from "react-toastify";
import permissions from "../data/Permissions.json";
import ViewPermission from "../components/permissions/ViewPermission";
import ContextMenu from "../components/shared/ContextMenu";
import Modal from "../components/Modal/Modal";

function NoteBook() {

    const [isFolderBarExpanded, setIsFolderBarExpanded] = useState(true);
    const [isNotesBarExpanded, setIsNotesBarExpanded] = useState(true);
    const [isFoldersListExpanded, setIsFoldersListExpanded] = useState(true);
    const [isTagsListExpanded, setIsTagsListExpanded] = useState(true);
    const [isBulkActionMenuShown, setIsBulkActionMenuShown] = useState(false);
    const [isSortingMenuShown, setIsSortingMenuShown] = useState(false);
    const [isNotesOptionMenuShown, setIsNotesOptionMenuShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const subAccount = useSubAccount();
    const navigate = useNavigate();
    const [canAddNote, setCanAddNote] = useState(false);

    //Data Items
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [notes, setNotes] = useState([]);
    const [sortedNotes, setSortedNotes] = useState([]);
    const [selectedNoteIndex, setSelectedNoteIndex] = useState(-1);
    const [selectedNote, setSelectedNote] = useState(undefined);
    const [noteTitle, setNoteTitle] = useState('');
    const [notesSelection, setNotesSelection] = useState([false]);
    const [allNotesSelected, setAllNotesSelected] = useState(false);
    const [isBulkActionShown, setIsBulkActionShown] = useState(false);
    const [tags, setTags] = useState([]);
    const [noteDate, setNoteDate] = useState('')
    const [selectedTag, setSelectedTag] = useState({});
    const {t} = useTranslation();
    const theme = useTheme();
    const [tagsUpdateModal, setTagsUpdateModal] = useState({
        shown: false,
        tag: null
    });
    const [newTagName, setNewTagName] = useState('');

    //Sorting Properties
    const [sortingType, setSortingType] = useState('');
    const [sortingDirection, setSortingDirection] = useState('');

    //Modals
    const [isFolderCreateModalShown, setIsFolderCreateModalShown] = useState(false);
    const [folderModalState, setFolderModalState] = useState('add');
    const [editFolder, setEditFolder] = useState(undefined);
    const dispatch = useDispatch();
    dispatch(setTitle(t("notebook_page")))

    const sortingTypes = [
        t('notebook_sort_type_title'),
        t('notebook_sort_type_last_edit'),
        t('notebook_sort_type_created'),
    ]

    const onAddFolder = () => {
        setFolderModalState('add')
        setIsFolderCreateModalShown(true)
    }

    const onModalClose = () => {
        setIsFolderCreateModalShown(false);
        setFolderModalState('add');
        setEditFolder(undefined);
    }

    const onModalSave = () => {
        setIsFolderCreateModalShown(false);
        loadFolders();
        setFolderModalState('add')
        setEditFolder(undefined);
    }

    const sortElementsById = (elements) => {
        return elements.sort(function(a, b) {
            return a.id - b.id;
        });
    }

    const loadFolders = async () => {
        setIsLoading(true);
        const response = await sendGet(Urls.GetFolders, true);
        if(response.error) {
            console.log(response.error);
        } else {
            setFolders(sortElementsById(response.folders));
        }
        setIsLoading(false);
    }

    const onEditFolder = async (id) => {
        setEditFolder(folders.filter(folder => folder.id === id)[0]);
        setFolderModalState('edit');
        setIsFolderCreateModalShown(true);
    }

    const onDeleteFolder = async (id) => {
        setIsLoading(true);
        const response = await sendDelete(Urls.DeleteFolder(id), undefined, true);
        if(response.error) {
            console.log(response.error);
        } else {
            if(id === selectedFolder) {
                setNotes([]);
                setSelectedNote(undefined);
                setSelectedNoteIndex(-1);
                setCanAddNote(false);
            }
            loadFolders();
        }
        setIsLoading(false);
    }

    const resetNote = () => {
        setSelectedNote(undefined)
        setSelectedTag({})
        setNoteDate('');
        setNoteTitle('')
        setSelectedNoteIndex(-1);
    }

    const selectFolder = async (folder) => {
        setSelectedFolder(folder);
        resetNote();
        setAllNotesSelected(false);
        setIsBulkActionShown(false);
        setSortingType('');
        setSortingDirection('');

        //Getting the notes from the server
        if(folder === 'All') {
            setCanAddNote(false);
            setIsLoading(true);
            //Loading All Notes
            const response = await sendGet(Urls.GetAllNotebookNotes, true);
            if(response.error) {
                console.log(response.error);
            } else {
                setNotes(response.notes);
                setNotesSelection(response.notes.map(n => false));
            }
            setIsLoading(false)
        } else if(folder === 'Trades') {
            setIsLoading(true);
            setCanAddNote(false);
            const response = await sendGet(Urls.GetNotesOfType('TradeNote'), true);
            if(response.error) {
                console.log(response.error);
            } else {
                console.log(response.notes)
                setNotesSelection(response.notes.map(n => false));
                setNotes(response.notes);
            }
            setIsLoading(false)
        } else if(folder === 'Daily') {
            setCanAddNote(true);
            setIsLoading(true);
            const response = await sendGet(Urls.GetNotesOfType('JournalDetails'), true);
            if(response.error) {
                console.log(response.error);
            } else {
                setNotesSelection(response.notes.map(n => false));
                setNotes(response.notes);
            }
            setIsLoading(false)
        } else {
            setCanAddNote(true);
            setIsLoading(true);
            const response = await sendGet(Urls.GetFolderNotes(folder), true);
            if(response.error) {
                console.log(response.error)
            } else {
                setNotes(response.notes)
                setNotesSelection(response.notes.map(n => false));
            }
            setIsLoading(false)
        }
    }

    const addNewNote = () => {

        if(!canAddNote) {
            return;
        }

        const noteToAdd = {
            title: 'Untitled 1', 
            dateCreated: new Date(),
            dateUpdated: new Date(),
        };

        if(selectedFolder === 'Daily') {

            const date = moment(new Date()).format('MM-DD-YYYY');
            let noteExists = false;
            notes.filter(note => {
                if(note.date === date) {
                    noteExists = true;
                }
            })

            if(noteExists) {
                toast.error(`A note for the date ${date} already exists`);
                return;
            }

            noteToAdd.type = 'JournalDetails';
            noteToAdd.date = date;
            noteToAdd.title = moment(new Date()).format('ddd MMM DD, YYYY')
        } else {
            noteToAdd.folderId = selectedFolder;
        }

        const newNotes = [noteToAdd ,...notes];
        setNotes(newNotes)
        let newSelections = newNotes.map(n => false);
        newSelections = newSelections.map((s, index) => {
            if(index > 0) {
                return notesSelection[index - 1];
            } else {
                return s;
            }
        })
        setNotesSelection(newSelections);
        setSelectedNoteIndex(selectedNoteIndex > -1 ? selectedNoteIndex + 1 : -1);
    }

    useEffect(() => {
        loadFolders();
        loadTags();
    }, []);

    const sortNotes = useCallback(() => {
        console.log(notes)
        if(sortingType === t('notebook_sort_type_title')) {
            return notes.sort((a, b) => {
                return sortingDirection === 'ASC' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title); 
            })
        } else if(sortingType === t('notebook_sort_type_last_edit')) {
            return notes.sort((a,b) => {
                return sortingDirection === 'ASC' ? a.dateUpdated.localeCompare(b.dateUpdated) : b.dateUpdated.localeCompare(a.dateUpdated);
            })
        } else if(sortingType === t('notebook_sort_type_created')) {
            return notes.sort((a,b) => {
                return sortingDirection === 'ASC' ? a.dateCreated.localeCompare(b.dateCreated) : b.dateCreated.localeCompare(a.dateCreated);
            })
        } else {
            return notes;
        }
    }, [sortingType, sortingDirection, notes]);

    useEffect(() => {
        const newNotes = sortNotes();
        setSortedNotes(newNotes);
        setNotesSelection(newNotes.map(n => false))
    }, [sortNotes])

    const selectNote = async (index) => {
        setSelectedNoteIndex(index);
        setIsNotesOptionMenuShown(false);
        const note = sortedNotes[index];
        if(note.id !== undefined) {
            setIsLoading(true);
            
            //Getting the note details
            const response = await sendGet(Urls.GetNotebookNote(note.id), true);
            if(response.error) {
                console.log(response.error);
            } else {
                setNoteTitle(response.note.title);
                setNoteDate(response.note.date);
                setSelectedNote(response.note);
            }
            setIsLoading(false);
        } else {
            setNoteTitle(note.title);
            setSelectedNote(note);
            setNoteDate(note.date);
        }
    }

    const saveNote = async (content) => {
        setIsLoading(true);
        if(selectedNote) {
            const response = await sendPost(Urls.CreateOrUpdateNote(selectedNote.id ? selectedNote.id : ''),
                {
                    title: noteTitle,
                    date: noteDate,
                    content: content, 
                    folderId: selectedNote.folderId,
                    tradeId: selectedNote.trade ? selectedNote.trade.id : undefined,
                    subAccountId: subAccount.id,
                    type: selectedNote.type ? selectedNote.type : 'NotebookNote',
                    timezone: momentTimeZone.tz.guess(),
                    tagId: selectedNote.tagId,
                }, true, 'application/json', true);

            if(response.error) {
                console.log(response.error);
                toast.error(response.error)
            } else {
                setNoteTitle(response.note.title);
                setNotes(notes.map((note, index) => {
                    if(index === selectedNoteIndex) {
                        return response.note;
                    } else {
                        return note;
                    }
                }));
                setSelectedNote(response.note)
            }
        }
        setIsLoading(false);
    }

    const onNoteCheckedChanged = (index, value) => {
        setNotesSelection(notesSelection.map((selection, i) => {
            if(index === i) {
                return value;
            } else {
                return selection;
            }
        }))
    }

    useEffect(() => {
        const checkedNotes = notesSelection.filter(selection => selection);

        if(checkedNotes.length > 0) {
            setIsBulkActionShown(true);
        } else {
            setIsBulkActionShown(false);
        }

        if(checkedNotes.length === notesSelection.length && notesSelection.length > 0) {
            setAllNotesSelected(true);
        } else {
            setAllNotesSelected(false);
        }
    }, [notesSelection])

    const onSelectAllCheckboxChange = (value) => {
        setNotesSelection(notesSelection.map(n => value));
    }

    const onDeleteNote = async (id) => {
        setIsNotesOptionMenuShown(false);
        setIsLoading(true);
        setSelectedNoteIndex(-1);
        //If the id is undefined then remove the selected note based on the index
        if(!id) {
            setNoteTitle('');
            setSelectedNote(undefined);
            setNotes(notes.filter((n, index) => index !== selectedNoteIndex));
            setNotesSelection(notesSelection.filter((s, index) => index !== selectedNoteIndex));
        } else {
            if(selectedNote) {
                const response = await sendDelete(Urls.DeleteNotebookNote(id), undefined, true);
                if(response.error) {
                    console.log(response.error)
                } else {
                    setNotes(notes.filter((note, index) => {
                        if(note.id === id) {
                            //Removing the note selection
                            setNotesSelection(notesSelection.filter((n, i) => i !== index));

                            return false;
                        } else {
                            return true;
                        }
                    }));
                    setSelectedNote(undefined);
                    setNoteTitle('');
                }
            }
        }
        setIsLoading(false);
    }

    const onBulkDelete = async () => {
        setIsLoading(true);
        setIsBulkActionMenuShown(false);
        const ids = [];
        const indexes = [];
        notesSelection.forEach((selection, index) => {
            if(selection) {
                const note = sortedNotes[index];
                //If the id exists than the note is saved on the server else its local only
                if(note.id !== undefined) {
                    ids.push(note.id);
                } else {
                    indexes.push(index);
                }

            }
        });

        if(selectedNote && ((selectedNote.id && ids.includes(selectedNote.id) || (!selectedNote.id && indexes.includes(selectedNoteIndex))))) {
            resetNote();
        }

        let newNotesSelection = notesSelection;
        let newNotes = notes;
        //Removing the elements based on indexes
        if(indexes.length > 0) {
            newNotes = newNotes.filter((n, index) => !indexes.includes(index));
            newNotesSelection = newNotesSelection.filter((s, index) => !indexes.includes(index));
        }
        if(ids.length > 0) {

            const response = await sendDelete(Urls.DeleteNotebookNotes, {ids: ids}, true);
            if(response.error) {
                console.log(response.error)
            } else {

                //If the selected note is in to be deleted clear the editor
                const selectedNote = sortedNotes[selectedNoteIndex]

                if(selectedNote) {
                    const id = selectedNote.id;
                    if(id && ids.includes(id)) {
                        setSelectedNoteIndex(-1);
                        setSelectedNote(undefined);
                        setNoteTitle('');
                    }
                }

                newNotes = newNotes.filter((note, index) => {
                    if(ids.includes(note.id)) {
                        //Removing the note selection
                        newNotesSelection = newNotesSelection.filter((n, i) => i !== index);
                        return false;
                    } else {
                        return true;
                    }
                });
            }
        }
        setNotes(newNotes);
        setNotesSelection(newNotesSelection);
        setIsLoading(false);
    }

    const onDiscardChanges = async () => {
        const note = sortedNotes[selectedNoteIndex];

        //If the note is stored on the server load it from the server again
        if(note && note.id) {
            const response = await sendGet(Urls.GetNotebookNote(note.id), true);
            if(response.error) {
                console.log(response.error);
            } else {
                setSelectedNote(response.note);
                setNoteTitle(response.note.title);
                setNoteDate(response.note.date);
                setNotes(notes.map((note, index) => {
                    if(index === selectedNoteIndex) {
                        return response.note;
                    } else {
                        return note;
                    }
                }))
            }
        }
        else {
            const newNote = {...selectedNote, title: 'Untitled 1', content: ''};
            setNoteDate(new Date());
            setSelectedNote(newNote)
            setNoteTitle(newNote.title);
            setNotes(notes.map((note, index) => {
                if(index === selectedNoteIndex) {
                    return newNote;
                } else {
                    return note;
                }
            }))
        }

    }

    const onNoteContentChange = (content) => {
        setSelectedNote({...selectedNote, content: content});
        setNotes(notes.map((note, index) => {
            if(index === selectedNoteIndex) {
                note.content = content;
                return note;
            } else {
                return note;
            }
        }))
    }

    const loadTags = async () => {
        setIsLoading(true);
        const response = await sendGet(Urls.GetNotebookTagsWithUsage, true);
        if(response.error) {
            console.log(response.error)
        } else {
            setTags(response.tags)
        }
        setIsLoading(false);
    }

    const addTag = async (name) => {
        setIsLoading(true);
        const response = await sendPost(Urls.CreateNotebookTag, {name: name}, true, 'application/json', true);
        if(response.error) {
            console.log(response.error);
        } else {
            setTags([...tags, response.tag])
            //Assigning tag to the current trade
            await assignTag(response.tag, selectedNote)
        }
        setIsLoading(false);
    }

    const onTagAssigned = async (index) => {
        const tag = tags[index];
        await assignTag(tag, selectedNote);
    }

    const assignTag = async (tag, note) => {
        const noteId = note.id;
        if(noteId) {
            const response = await sendPost(Urls.AssignNotebookTag, {
                tagId: tag.id,
                noteId: noteId
            }, true, 'application/json', true);

            if(response.error) {
                console.log(response.error)
            }
            else {

                const response = await sendGet(Urls.GetNotebookNote(noteId), true);

                if(response.error) {
                    console.log(response.error);
                } else {
                    setNotes(notes.map(note => {
                        if(note.id === noteId) {
                            return response.note;
                        } else {
                            return note;
                        }
                    }))
                }

                await loadTags();
            }

            //If any tag is currently selected then reload notes
            if(selectedTag.name) {
                await selectTag(selectedTag);
            }

        }
        else if(note && !noteId) {
            const updatedNote = {
                ...selectedNote,
                tagId: tag.id,
                tags: [
                    tag
                ]
            };
            setNotes([...notes.map((n, index) => {
                if(index === selectedNoteIndex) {
                    return updatedNote;
                }
                else {
                    return n;
                }
            })])
            setSelectedNote(updatedNote)
        }
    }

    const getSelectedTagIndex = useCallback(() => {
        if(selectedNoteIndex > -1) {
            const note = notes[selectedNoteIndex];
            if(note && note.tags && note.tags.length > 0) {
                return tags.map(tag => tag.name).indexOf(note.tags[0].name);
            }
        }
        return -1;
    }, [selectedNoteIndex, selectedNote, notes, tags])

    const onNoteDateChange = (date) => {
        setNoteDate(date);
    }

    const selectTag = async (tag) => {
        //Resetting state
        setSelectedFolder('');
        setSelectedNote(undefined)
        setNoteDate('');
        setNoteTitle('')
        setAllNotesSelected(false);
        setIsBulkActionShown(false);
        setSelectedNoteIndex(-1);
        setSortingType('');
        setSortingDirection('');
        setSelectedTag(tag);

        setIsLoading(true);
        const response = await sendGet(Urls.GetNotesForTag(tag.id), true);
        if(response.error) {
            console.log(response.error);
        } else {
            setNotes(response.notes);
            setNotesSelection(response.notes.map(n => false));
        }
        setIsLoading(false);
    }

    const onSortItems = (type) => {
        setSelectedNoteIndex(-1);
        setSelectedNote(undefined);
        setNoteTitle('');
        setNoteDate('');
        //If the sorting type is clicked again then toggle the direction
        if(sortingType === type) {
            setSortingDirection(sortingDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortingType(type);
            setSortingDirection('ASC');
        }
    }

    const onEditTag = (tag) => {
        setTagsUpdateModal({
            shown: true,
            tag: tag
        });
        setNewTagName(tag.name)
    }

    const onSaveTagEdit = async () => {
        if(tagsUpdateModal.tag) {
            setIsLoading(true);
            const response = await sendPut(Urls.UpdateNoteBookTag(tagsUpdateModal.tag.id), {
                name: newTagName
            }, true, 'application/json', true);
            if(response.error) {
                toast.error(response.error);
            }
            else {
                onCancelTagEdit();
                if(selectedTag && selectedTag.id) {
                    await selectTag(selectedTag);
                }
                else if (selectedFolder) {
                    await selectFolder(selectedFolder)
                }
                await loadTags();
            }
            setIsLoading(false);
        }
    }

    const onCancelTagEdit = () => {
        setNewTagName('');
        setTagsUpdateModal({
            shown: false,
            tag: null
        })
    }
    
    const onDeleteTag = async (id) => {
        setIsLoading(true);
        const response = await sendDelete(Urls.DeleteNoteBookTag(id), {}, true);
        if(response.error) {
            toast.error(response.error)
        }
        else {

            if(selectedTag && selectedTag.id === id) {
                setSelectedTag({});
                setNotes([]);
                setSelectedNote(null);
                setSelectedNoteIndex(-1);
            }

            setTags([...tags.filter(tag => tag.id !== id)]);
        }
        setIsLoading(false);
    }

    return (
        <>
            {
                isFolderCreateModalShown ? 
                <FolderCreateModal handleCloseModal={onModalClose} handleSaveModal={onModalSave} 
                    isAdd={folderModalState === 'add'} editFolder={editFolder}/> 
                : <></>
            }
            <Modal isShown={tagsUpdateModal.shown} className={'modal-md tag-update-modal'} title={'Update Tag'} onClose={onCancelTagEdit}>
               <Modal.Body>
                   <div className="form-group">
                       <label>Tag Name</label>
                       <input type="text" value={newTagName} onChange={e => setNewTagName(e.currentTarget.value)}/>
                   </div>
               </Modal.Body>
                <Modal.Footer>
                    <div className="horizontal-container">
                        <div className="secondary-btn" onClick={onCancelTagEdit}>
                            Cancel
                        </div>
                        <div className="primary-btn" onClick={onSaveTagEdit}>
                            Save
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {
                isLoading ?
                    <Loader fullScreen={true} text={'Hold Tight! We are loading the notebook'}/> :
                    <ViewPermission permission={permissions.PAGE.NOTEBOOK}>
                        <div className={`page notebook-page ${theme.getThemeClass()}`}>
                            <div className="page-body">
                                <div className={`main-container`}>
                                    <div className={`folders-container ${isFolderBarExpanded ? '' : 'collapsed'}`}>
                                        <div className='inner-container'>
                                            <div className="action-row">
                                                <div className="text-btn enabled" onClick={onAddFolder}>
                                                    <MdCreateNewFolder/>
                                                    <div className='text'>{t("notebook_add_folder")}</div>
                                                </div>
                                                <div className='text-btn toggle-btn'
                                                     onClick={() => setIsFolderBarExpanded(!isFolderBarExpanded)}>
                                                    <MdChevronLeft/>
                                                </div>
                                            </div>
                                            <div className={`folders-list ${isFoldersListExpanded ? '' : 'collapsed'}`}>
                                                <div className='header'>
                                                    {t("notebook_folders")}
                                                    <div className='toggle-btn'
                                                         onClick={() => setIsFoldersListExpanded(!isFoldersListExpanded)}>
                                                        <MdExpandMore/>
                                                    </div>
                                                </div>
                                                <div className='folder-items'>
                                                    <FolderItem isActive={selectedFolder === 'All'}
                                                                onClick={() => selectFolder('All')}
                                                                title={t('notebook_all_notes')} canShowMenu={false}/>
                                                    <FolderItem isActive={selectedFolder === 'Trades'}
                                                                onClick={() => selectFolder('Trades')}
                                                                title={t('notebook_trade_notes')} canShowMenu={false}/>
                                                    <FolderItem isActive={selectedFolder === 'Daily'}
                                                                onClick={() => selectFolder('Daily')}
                                                                title={t("notebook_journal_notes")}
                                                                canShowMenu={false}/>
                                                    <div className='separator'></div>
                                                    {
                                                        folders.map((folder, index) => {
                                                            return (
                                                                <div id={folder.id}>
                                                                    <FolderItem id={folder.id}
                                                                                isActive={selectedFolder === folder.id}
                                                                                color={folder.color}
                                                                                title={folder.name}
                                                                                onDelete={onDeleteFolder}
                                                                                canShowMenu={true} onEdit={onEditFolder}
                                                                                onClick={() => selectFolder(folder.id)}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className={`tags-list ${isTagsListExpanded ? '' : 'collapsed'}`}>
                                                <div className='header'>
                                                    {t('notebook_tags_heading')}
                                                    <div className='toggle-btn'
                                                         onClick={() => setIsTagsListExpanded(!isTagsListExpanded)}>
                                                        <MdExpandMore/>
                                                    </div>
                                                </div>
                                                <div className='tag-items'>
                                                    {
                                                        tags.map((tag, index) => {
                                                            return (
                                                                <ContextMenu>
                                                                    <ContextMenu.Trigger>
                                                                        <div
                                                                            className={`tag-item ${selectedTag.id === tag.id ? 'active' : ''}`}
                                                                            key={index} onClick={() => selectTag(tag)}>
                                                                            <div className='title'>
                                                                                {tag.name}
                                                                            </div>
                                                                            <div className='usage-num'>
                                                                                {tag.count}
                                                                            </div>
                                                                            <div className="action">
                                                                                <div className="button" onClick={e => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation()
                                                                                    onEditTag(tag)
                                                                                }}>
                                                                                    <MdEdit/>
                                                                                </div>
                                                                                <div className="button danger" onClick={async e => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation()
                                                                                    await onDeleteTag(tag.id)
                                                                                }}>
                                                                                    <MdDelete/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu.Trigger>
                                                                    <ContextMenu.Content>
                                                                        <div className="button"
                                                                             onClick={() => onEditTag(tag)}>
                                                                            <MdEdit/>
                                                                            {t("buttons.edit")}
                                                                        </div>
                                                                        <div className="button danger" onClick={() => onDeleteTag(tag.id)}>
                                                                            <MdDelete/>
                                                                            {t("buttons.delete")}
                                                                        </div>
                                                                    </ContextMenu.Content>
                                                                </ContextMenu>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='note-container'>
                                        <div className={`notes-list ${isNotesBarExpanded ? '' : 'collapsed'}`}>
                                            <div className="action-row">
                                                <div className={`text-btn ${canAddNote ? 'enabled' : 'disabled'}`}
                                                     onClick={addNewNote}>
                                                    <MdNoteAdd/>
                                                    {t("notebook_new_note")}
                                                </div>
                                                <div
                                                    className={`action-menu no-border ${isSortingMenuShown ? 'show' : ''}`}>
                                                    <div className='title'
                                                         onClick={() => setIsSortingMenuShown(!isSortingMenuShown)}>
                                                        <MdSort/>
                                                    </div>
                                                    <div className='options'>
                                                        <div className='category-item'>
                                                            {t("notebook_sort_by_heading")}
                                                        </div>
                                                        {
                                                            sortingTypes.map((type, index) => {
                                                                return (
                                                                    <div className='primary-btn' key={index}
                                                                         onClick={() => onSortItems(type)}>
                                                                        <div
                                                                            className={`sorting-btn-container ${sortingType === type ? '' : 'hide'}`}>
                                                                            <div
                                                                                className={`sorting-btn ${sortingDirection === 'ASC' ? 'active' : ''}`}>
                                                                                <MdArrowUpward/>
                                                                            </div>
                                                                            <div
                                                                                className={`sorting-btn ${sortingDirection === 'DESC' ? 'active' : ''}`}>
                                                                                <MdArrowDownward/>
                                                                            </div>
                                                                        </div>
                                                                        {type}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='note-items'>
                                                <div className='header'>
                                                    <Checkbox title={t("notebook.notes.selectAll")} value={allNotesSelected}
                                                              onChange={value => onSelectAllCheckboxChange(value)}/>
                                                    <div
                                                        className={`action-menu ${isBulkActionShown ? '' : 'hide'} ${isBulkActionMenuShown ? 'show' : ''}`}>
                                                        <div className='title'
                                                             onClick={() => setIsBulkActionMenuShown(!isBulkActionMenuShown)}>
                                                            {t("notebook_bulk_action")}
                                                            <div className='menu-icon'>
                                                                <MdExpandMore/>
                                                            </div>
                                                        </div>
                                                        <div className='options'>
                                                            <div className='delete-btn' onClick={onBulkDelete}>
                                                                <MdDelete/>
                                                                {t("delete_action")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    sortedNotes.length > 0 && notesSelection.length > 0 ?
                                                        sortedNotes.map((note, index) => {
                                                            return (
                                                                <div
                                                                    className={`note-item ${index === selectedNoteIndex ? 'active' : ''}`}
                                                                    key={index} onClick={() => selectNote(index)}>
                                                                    <div
                                                                        className={`checkbox ${notesSelection[index] ? 'checked' : ''}`}>
                                                                        <Checkbox
                                                                            onChange={value => onNoteCheckedChanged(index, value)}
                                                                            value={notesSelection[index]}/>
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='title'>{note.title}</div>
                                                                        {
                                                                            note.type === 'TradeNote' && note.trade ?
                                                                                <div className='net-pnl'>NET P&L: <span
                                                                                    className={`pnl ${note.trade.data.PnL >= 0 ? 'positive' : 'negative'}`}>{currencyFormat.format(note.trade.data.PnL)}</span>
                                                                                </div> : <></>
                                                                        }
                                                                        <div className='date'>
                                                                            {moment(note.dateCreated).format('DD-MM-YYYY')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) :
                                                        <div className='empty-message'>
                                                            {t("notebook.notes.empty")}
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={`editor ${isNotesBarExpanded ? 'collapsed' : ''}`}>
                                            {
                                                selectedNote ?
                                                    <>
                                                        <div className='btn-container'>
                                                            <div className={`toggle-btn`}
                                                                 onClick={() => setIsNotesBarExpanded(!isNotesBarExpanded)}>
                                                                <MdChevronLeft/>
                                                            </div>
                                                        </div>
                                                        <div className='editor-container'>
                                                            <div className='header'>
                                                                {
                                                                    selectedNote && selectedNote.type === 'JournalDetails' ?
                                                                        <div className='title'>
                                                                            <DateInput currentDate={noteDate}
                                                                                       onDateChange={onNoteDateChange}/>
                                                                        </div> :
                                                                        <div className='title'>
                                                                            <input type='text'
                                                                                   className='input-focusable'
                                                                                   value={noteTitle}
                                                                                   onChange={e => setNoteTitle(e.currentTarget.value)}
                                                                                   placeholder='Enter Note Title'/>
                                                                        </div>
                                                                }
                                                                <div
                                                                    className={`action-menu no-border ${isNotesOptionMenuShown ? 'show' : ''}`}>
                                                                    <div className='title'
                                                                         onClick={() => setIsNotesOptionMenuShown(!isNotesOptionMenuShown)}>
                                                                        <div className='primary-btn'>
                                                                            <MdMoreHoriz/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='options'>
                                                                        <div className='delete-btn'
                                                                             onClick={() => onDeleteNote(selectedNote.id)}>
                                                                            <MdDelete/>
                                                                            {t("delete_action")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="editor-scrollable-container">
                                                                <div className='update-row'>
                                                                    <div className='item'>
                                                                        <div
                                                                            className='label'>{t('notebook_note_created')}:
                                                                        </div>
                                                                        <div
                                                                            className='value'>{moment(selectedNote.dateCreated).format('MMM DD, YYYY hh:mma')}</div>
                                                                    </div>
                                                                    <div className='item'>
                                                                        <div
                                                                            className='label'>{t('notebook_note_last_updated')}:
                                                                        </div>
                                                                        <div
                                                                            className='value'>{moment(selectedNote.dateUpdated).format('MMM DD, YYYY hh:mma')}</div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    selectedNote.type === 'TradeNote' && selectedNote.trade ?
                                                                        <div className='trade-details'>
                                                                            <div className='header'>
                                                                                <div
                                                                                    className={`pnl ${selectedNote.trade.data.PnL >= 0 ? 'positive' : 'negative'}`}>
                                                                                    {t("notebook_note_net_pnl")} {currencyFormat.format(selectedNote.trade.data.PnL)}
                                                                                </div>
                                                                                <div className='primary-btn'
                                                                                     onClick={() => navigate(`/trade/${selectedNote.trade.id}`)}>
                                                                                    {t('notebook_note_view_details')}
                                                                                </div>
                                                                            </div>
                                                                            <div className='details'>
                                                                                <div className='header-row'>
                                                                                    <div
                                                                                        className='cell'>{t('notebook_note_volume_column')}</div>
                                                                                    <div
                                                                                        className='cell'>{t('notebook_note_commissions_column')}</div>
                                                                                    <div
                                                                                        className='cell'>{t('notebook_note_net_roi_column')}</div>
                                                                                    <div
                                                                                        className='cell'>{t('notebook_note_gross_column')}</div>
                                                                                </div>
                                                                                <div className='value-row'>
                                                                                    <div
                                                                                        className='cell'>{Math.abs(selectedNote.trade.data.CloseVolume)}</div>
                                                                                    <div
                                                                                        className='cell'>{currencyFormat.format(selectedNote.trade.data.Commission)}</div>
                                                                                    <div
                                                                                        className='cell'>{getNetROI(selectedNote.trade.data)}%
                                                                                    </div>
                                                                                    <div
                                                                                        className='cell'>{getGrossPnL(selectedNote.trade.data, true)}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : <></>
                                                                }
                                                                <div className='tag-row'>
                                                                    <Select options={tags.map(tag => tag.name)}
                                                                            placeholder={t("notebook.editor.selectTag")}
                                                                            hasAddButton={true}
                                                                            isSearchable={true}
                                                                            onAddButtonClick={addTag}
                                                                            onChange={option => onTagAssigned(option)}
                                                                            selectedIndex={getSelectedTagIndex()}
                                                                    />
                                                                </div>
                                                                <div className='editor-control'>
                                                                    <RichTextEditor content={selectedNote.content}
                                                                                    onSaveChanges={saveNote}
                                                                                    onDiscardChanges={onDiscardChanges}
                                                                                    onContentChange={onNoteContentChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ViewPermission>
            }
        </>
    )
}

export default NoteBook;