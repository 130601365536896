import {MdCheck, MdClose, MdPriorityHigh} from "react-icons/md";
import "../../styles/tags-modal.scss";
import {useTheme} from "../../Providers/ThemeContext";

const CodeShowModal = ({
    isOpen,
    handleCloseModal,
    code
}) => {

    const theme = useTheme();

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className={`modal ${theme.getThemeClass()} code-show-modal`}>
            <div className="modal-header">
              <div className="title">
                OTP Code
              </div>
              <div className="close-btn" onClick={handleCloseModal}>
                <MdClose />
              </div>
            </div>
              <div className="modal-body">
                  <div className="message">
                      Enter the below code in the ATAS window to enable auto syncing of trades
                  </div>
                  <div className="code">
                      {code}
                  </div>
              </div>
              <div className="modal-footer">
                  <button className="secondary-btn" onClick={handleCloseModal}>
                    Ok
                  </button>
              </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CodeShowModal;
