import Select from "../selectcontrol";
import Loader from "../Loader";
import {useEffect, useState} from "react";
import useSubAccounts from "../../hooks/useSubAccounts";
import {sendGet, sendPost} from "../../../common/request";
import Urls from "../../../common/links";
import {toast} from "react-toastify";
import moment from "moment/moment";
import Checkbox from "../checkbox";
import useSubAccount from "../../hooks/useSubAccount";
import currencyFormat from "../../../common/format";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ManualInsert = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const DIRECTIONS = ['LONG', 'SHORT']
    const TYPES = ["Buy", "Sell"]
    const [isLoading, setIsLoading] = useState(false);
    const subAccounts = useSubAccounts();
    const subAccount = useSubAccount();
    const [selectedSubAccount, setSelectedSubAccount] = useState();
    const [selectedDirection, setSelectedDirection] = useState();
    const [selectedType, setSelectedType] = useState();
    const [isSingleTrade, setIsSingleTrade] = useState(false);
    const [tradeData, setTradeData] = useState({
        securityId: '',
        stopLoss: 0,
        profitTarget: 0,
        commission: 0,
        openDate: '',
        openTime: '',
        openPrice: 0,
        openVolume: 0,
        closeDate: '',
        closeTime: '',
        closePrice: 0,
        closeVolume: 0,
    })
    const [openTrades, setOpenTrades] = useState([]);
    const [isTradesLoading, setIsTradesLoading] = useState(false);
    const state = useLocation().state;
    const [tradeText, setTradeText] = useState('');

    useEffect(() => {
        if(state && state.trade) {
            onTradeClose(state.trade);
        }
        else {
            setSelectedDirection(DIRECTIONS[0]);
            setSelectedType(TYPES[0])
        }
    }, [state])

    const fetchOpenTrades = async () => {
        if(selectedSubAccount) {
            setIsTradesLoading(true);
            const response = await sendGet(Urls.SearchTrades(selectedSubAccount.id, {
                status: 'Closed',
                statusMatch: 'ne'
            }), true);

            if(response.error) {
                console.log(response.error);
                setOpenTrades([]);
            }
            else {
                setOpenTrades(response.trades)
            }
            setIsTradesLoading(false);
        }
    }

    useEffect(() => {
        if(subAccount) {
            setSelectedSubAccount(subAccount)
        }
    }, [subAccount]);

    useEffect(() => {
        fetchOpenTrades();
    }, [selectedSubAccount]);

    const onDataChange = (e) => {
        const {name, value} = e.currentTarget;
        onChange(name,  value, e.currentTarget.type === 'number');
    }

    const onChange = (name, value, isNumber) => {
        const newTradeData = {
            ...tradeData,
            [name]: isNumber ? parseFloat(value) : value
        };
        setTradeData(newTradeData);
    }

    const onDiscard = () => {
        setTradeData({
            securityId: '',
            stopLoss: 0,
            profitTarget: 0,
            commission: 0,
            pnl: 0,
            openDate: '',
            openTime: '',
            openPrice: 0,
            openVolume: 0,
            closeDate: '',
            closeTime: '',
            closePrice: 0,
            closeVolume: 0,
        })
    }

    const getDateTime = (date, time) => {
        return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm").toDate()
    }

    const onSave = async () => {
        setIsLoading(true);

        let error = '';
        if(!tradeData.openDate) {
            error = 'Open Date is required'
        }
        else if (!tradeData.openTime) {
            error = 'Open Time is required';
        }
        else if (!tradeData.closeDate && !isSingleTrade) {
            error = 'Close Date is required';
        }
        else if (!tradeData.closeTime && !isSingleTrade) {
            error = 'Close Time is required'
        }
        else if (!tradeData.securityId) {
            error = 'Security Id is required';
        }
        else if (tradeData.openPrice <= 0) {
            error = 'Open Price must be greater than 0';
        }
        else if (tradeData.closePrice <= 0 && !isSingleTrade) {
            error = 'Close Price must be greater than 0';
        }
        else if (tradeData.openVolume <= 0) {
            error = 'Open Volume must be greater than 0'
        }
        else if (tradeData.closeVolume <= 0 && !isSingleTrade) {
            error = 'Close Volume must be greater than 0'
        }
        else if (!isSingleTrade && tradeData.openVolume !== tradeData.closeVolume) {
            error = 'Open and Close volume for the whole trade must be equal'
        }

        if(error) {
            toast.error(error);
            setIsLoading(false)
            return;
        }

        const response = await sendPost(Urls.InsertTrade, {
            subAccountId: selectedSubAccount.id,
            direction: selectedDirection,
            type: selectedType,
            ...tradeData,
            openTime: getDateTime(tradeData.openDate, tradeData.openTime),
            closeTime: isSingleTrade ? null : getDateTime(tradeData.closeDate, tradeData.closeTime),
            isSingleTrade: isSingleTrade,
        }, true, 'application/json', true);

        if(response.error) {
            toast.error(response.error);
        }
        else {
            toast.success('Trade Inserted Successfully');
            await fetchOpenTrades()
            onDiscard();
            if(selectedSubAccount) {
                dispatch({type: 'CLEAR_INSIGHT_SUB_ACCOUNT', payload: {insightsSubAccount: selectedSubAccount.id}});
            }
        }

        setIsLoading(false);
    }

    const onTradeClose = (trade) => {
        setSelectedDirection(trade.data.Side);
        setSelectedType(trade.data.OpenType === 'Buy' ? 'Sell' : 'Buy');
        const currentDate = new Date();
        setTradeData({
            securityId: trade.data.SecurityId,
            stopLoss: trade.data.StopLoss,
            profitTarget: trade.data.ProfitTarget,
            openDate: moment(currentDate).format("YYYY-MM-DD"),
            openTime: moment(currentDate).format("HH:mm"),
            openPrice: 0,
            openVolume: 0,
        });
        setIsSingleTrade(true)
    }

    // const onTradeDataChange = () => {
    //     const textParts = tradeText.split('\n');
    //     const newTradeData = {};
    //
    //     const updateValue = (field, value, isNumber) => {
    //         newTradeData[field] = isNumber ?  parseFloat(value) : value.trim();
    //     }
    //
    //     const tradeMapping = {
    //         'Security Id': (value) => updateValue('securityId', value),
    //         'Direction': value => updateValue('direction', value),
    //         'Profit Target': value => updateValue('profitTarget', value, true),
    //         'Stop Loss': value => updateValue('stopLoss', value, true),
    //         'Commission': value => updateValue('commission', value, true),
    //         'Open Time': value => {
    //             const date = moment(value, "DD/MM/YYYY HH:mm");
    //             updateValue('openDate', date.format('YYYY-MM-DD'));
    //             updateValue('openTime', date.format('HH:mm'));
    //         },
    //         'Open Price': value => updateValue('openPrice', value, true),
    //         'Open Volume': value => updateValue('openVolume', value, true),
    //         'Close Time': value => {
    //             const date = moment(value, "DD/MM/YYYY HH:mm");
    //             updateValue('closeDate', date.format('YYYY-MM-DD'));
    //             updateValue('closeTime', date.format('HH:mm'));
    //         },
    //         'Close Price': value => updateValue('closePrice', value, true),
    //         'Close Volume': value => updateValue('closeVolume', value, true),
    //     }
    //
    //     for(const text of textParts) {
    //         if(!text) {
    //             continue;
    //         }
    //
    //         const fieldValue = text.split('\t')[1];
    //         if(!fieldValue) {
    //             continue;
    //         }
    //
    //         const fieldParts = fieldValue.split(':');
    //
    //         const setter = tradeMapping[fieldParts[0]]
    //
    //         if(setter) {
    //             setter(fieldParts[1]);
    //         }
    //     }
    //
    //     setTradeData(newTradeData);
    //     setSelectedDirection(newTradeData.direction);
    // }

    return (
        <div
            className={`manual-insert tab-content-item active`}>
            {/*<div className={'input-group'}>*/}
            {/*    <textarea style={{*/}
            {/*        width: '100%',*/}
            {/*        height: '300px',*/}
            {/*        backgroundColor: 'transparent',*/}
            {/*        border: "1px solid #DEDEDE",*/}
            {/*        color: 'white',*/}
            {/*        padding: "10px"*/}
            {/*    }}*/}
            {/*              value={tradeText} onChange={e => setTradeText(e.currentTarget.value)}></textarea>*/}
            {/*    <div className='primary-btn' onClick={onTradeDataChange}>Parse</div>*/}
            {/*    <div className='primary-btn' onClick={onSave}>Save</div>*/}
            {/*</div>*/}
            <div className="limited-rows">
                <div className={`table`}>
                    <div className="header">
                        <div className="cell">{t("add_trade_page.manualInsert.headers.0")}</div>
                        <div className="cell">{t("add_trade_page.manualInsert.headers.1")}</div>
                        <div className="cell">{t("add_trade_page.manualInsert.headers.2")}</div>
                        <div className="cell">{t("add_trade_page.manualInsert.headers.3")}</div>
                        <div className="cell">{t("add_trade_page.manualInsert.headers.4")}</div>
                        <div className="cell">{t("add_trade_page.manualInsert.headers.5")}</div>
                        <div className="cell">{t("add_trade_page.manualInsert.headers.6")}</div>
                        <div className="cell"></div>
                    </div>
                    {
                        isTradesLoading ?
                            <div className="mt-2">
                                <Loader height={30} barWidth={3}/>
                            </div> :
                            openTrades && openTrades.length > 0 ?
                                openTrades.map((trade) => {
                                    return (
                                        <div className="row" key={trade.id}>
                                            <div className="cell">
                                                <div className="text">{trade.data.SecurityId}</div>
                                            </div>
                                            <div className="cell">{trade.data.Side}</div>
                                            <div
                                                className="cell">{moment(trade.data.OpenTime).format('DD-MM-YYYY HH:mm')}</div>
                                            <div
                                                className="cell">{currencyFormat.format(trade.data.OpenPrice)}</div>
                                            <div
                                                className="cell">{Math.abs(trade.data.CloseVolume + trade.data.OpenVolume).toFixed(2)}</div>
                                            <div
                                                className="cell">{currencyFormat.format(trade.data.Commission)}</div>
                                            <div className="cell">{trade.status}</div>
                                            <div className="cell">
                                                <div className="actions">
                                                    <div className="danger-btn transparent"
                                                         onClick={() => onTradeClose(trade)}>
                                                        {t("add_trade_page.manualInsert.action.close")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div className="note">{t("add_trade_page.manualInsert.empty")}</div>
                    }
                </div>
            </div>
            <div className="insert-content">
                <div className="controls">
                    <div className='flex-horizontal'>
                        <div className="heading">{t("add_trade_page.manualInsert.fields.title")}</div>
                        <Checkbox title={t("add_trade_page.manualInsert.fields.singleTrade")} value={isSingleTrade}
                                  onChange={v => setIsSingleTrade(v)}/>
                    </div>
                    <div className="input-group">
                        <label>{t("add_trade_page.manualInsert.fields.subAccount")}</label>
                        <Select options={subAccounts.map(acc => acc.name)}
                                selectedIndex={subAccounts.indexOf(selectedSubAccount)}
                                onChange={index => setSelectedSubAccount(subAccounts[index])}/>
                    </div>
                    <div className="input-group">
                        <label>{t("add_trade_page.manualInsert.fields.securityId")}</label>
                        <input type="text" value={tradeData.securityId} name={'securityId'} onChange={onDataChange}
                               placeholder="Security Id" className="input-group-item"/>
                    </div>
                    {
                        isSingleTrade ? <></> :
                            <div className="input-group">
                                <label>{t("add_trade_page.manualInsert.fields.direction")}</label>
                                <Select options={DIRECTIONS} onChange={index => setSelectedDirection(DIRECTIONS[index])}
                                        selectedIndex={DIRECTIONS.indexOf(selectedDirection)}/>
                            </div>
                    }
                    {
                        isSingleTrade ?
                            <div className="input-group">
                                <label>{t("add_trade_page.manualInsert.fields.type")}</label>
                                <Select options={TYPES} onChange={index => setSelectedType(TYPES[index])}
                                        selectedIndex={TYPES.indexOf(selectedType)}/>
                            </div> : <></>
                    }
                    <div className="input-group">
                        <label>{t("add_trade_page.manualInsert.fields.profitTarget")}</label>
                        <input type="number" value={tradeData.profitTarget} name={'profitTarget'}
                               onChange={onDataChange}
                               placeholder="Profit Target" className="input-group-item"/>
                    </div>
                    <div className="input-group">
                        <label>{t("add_trade_page.manualInsert.fields.stopLoss")}</label>
                        <input type="number" value={tradeData.stopLoss} name={'stopLoss'} onChange={onDataChange}
                               placeholder="Stop Loss" className="input-group-item"/>
                    </div>
                    <div className="input-group">
                        <label>{t("add_trade_page.manualInsert.fields.commission")}</label>
                        <input type="number" value={tradeData.commission} name={'commission'} onChange={onDataChange}
                               placeholder="Commission" className="input-group-item"/>
                        <div className="note">{t("add_trade_page.manualInsert.fields.commissionNote")}
                        </div>
                    </div>
                </div>
                <div className="vr"></div>
                <div className="controls">
                    <div className="group">
                        <div
                            className='heading'>{isSingleTrade ? t("add_trade_page.manualInsert.fields.trade.title") : t("add_trade_page.manualInsert.fields.openTrade.title")}</div>
                        <div className="input-group">
                            <label>{isSingleTrade ? t("add_trade_page.manualInsert.fields.trade.time") : t("add_trade_page.manualInsert.fields.openTrade.time")} Time</label>
                            <div className="horizontal-container">
                                <div className="date-container">
                                    <input type="date" value={tradeData.openDate} name={'openDate'}
                                           onChange={onDataChange}
                                           placeholder="Open Date" className="input-group-item"/>
                                    <div className="custom-icon">&#128197;</div>
                                </div>
                                <div className="time-container">
                                    <input type="time" value={tradeData.openTime} name={'openTime'}
                                           onChange={onDataChange}
                                           placeholder="Open Time" className="input-group-item"/>
                                    <div className="custom-icon">&#128337;</div>
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <label>{isSingleTrade ? t("add_trade_page.manualInsert.fields.trade.price") : t("add_trade_page.manualInsert.fields.openTrade.price")}</label>
                            <input type="number" value={tradeData.openPrice} name={'openPrice'} onChange={onDataChange}
                                   placeholder="Open Price" className="input-group-item"/>
                        </div>
                        <div className="input-group">
                            <label>{isSingleTrade ? t("add_trade_page.manualInsert.fields.trade.volume") : t("add_trade_page.manualInsert.fields.openTrade.volume")}</label>
                            <input type="number" value={tradeData.openVolume} name={'openVolume'}
                                   onChange={onDataChange}
                                   placeholder="Open Volume" className="input-group-item"/>
                        </div>
                    </div>
                    {
                        isSingleTrade ? <></> :
                            <div className="group">
                                <div
                                    className='heading'>{t("add_trade_page.manualInsert.fields.closeTrade.title")}</div>
                                <div className="input-group">
                                    <label>{t("add_trade_page.manualInsert.fields.closeTrade.time")}</label>
                                    <div className="horizontal-container">
                                        <div className="date-container">
                                            <input type="date" value={tradeData.closeDate} name={'closeDate'}
                                                   onChange={onDataChange}
                                                   placeholder="Close Date" className="input-group-item"/>
                                            <div className="custom-icon">&#128197;</div>
                                        </div>
                                        <div className="time-container">
                                            <input type="time" value={tradeData.closeTime} name={'closeTime'}
                                                   onChange={onDataChange}
                                                   placeholder="Close Time" className="input-group-item"/>
                                            <div className="custom-icon">&#128337;</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <label>{t("add_trade_page.manualInsert.fields.closeTrade.price")}</label>
                                    <input type="number" value={tradeData.closePrice} name={'closePrice'}
                                           onChange={onDataChange} placeholder="Close Price"
                                           className="input-group-item"/>
                                </div>
                                <div className="input-group">
                                    <label>{t("add_trade_page.manualInsert.fields.closeTrade.volume")}</label>
                                    <input type="number" value={tradeData.closeVolume} name={'closeVolume'}
                                           onChange={onDataChange} placeholder="Close Volume"
                                           className="input-group-item"/>
                                </div>

                            </div>
                    }
                    <div className='horizontal-container'>
                        <div className="btn btn-secondary w-100" onClick={onDiscard}>
                            {t("add_trade_page.manualInsert.fields.buttons.cancel")}
                        </div>
                        {
                            isLoading ?
                                <Loader height={25} barWidth={3}/> :
                                <div className="btn btn-primary w-100" onClick={onSave}>
                                    {t("add_trade_page.manualInsert.fields.buttons.add")}
                                </div>
                        }
                    </div>
                </div>
                <div className="video input-group">
                    <label>{t("add_trade_page.manualInsert.guide")}</label>
                    <video src={'/videos/manual-trade-insert.mp4'} controls/>
                </div>
            </div>
        </div>
    )
}

export default ManualInsert;