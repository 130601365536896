import React from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import {useTranslation} from "react-i18next";

export default function ChatSettings({
    toggleDeleteModal,
    toggleSettingsVisibility,
}) {

  const {t} = useTranslation();

  return (
    <div className="main-group-settings">
      <div className="group-settings">
        <div className="group-header">
          <div className="user-image-container">
          </div>
          <p>{t("community.chat.settingsTitle")}</p>
          <div className={'back-btn'} onClick={toggleSettingsVisibility}>
            <MdOutlineArrowBack />
          </div>
        </div>
        <button className="delete-btn" onClick={toggleDeleteModal}>
            {t("community.chat.deleteChat")}
        </button>
      </div>
    </div>
  );
}
