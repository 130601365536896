const initialState = {
  user: null,
  insightsSubAccounts: [],
  loading: false,
  error: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCHING_USER":
      return {...state, user: null, loading: true, error: null}
    case "SET_USER":
      return { ...state, user: action.payload, loading: false, error: null };
    case "SET_ERROR":
      return {...state, user: null, loading: false, error: action.payload}
    case "SET_INSIGHTS":
      return state.insightsSubAccounts.includes(action.payload.insightsSubAccount) ? {...state} : {...state, insightsSubAccounts: [...state.insightsSubAccounts, action.payload.insightsSubAccount] };
    case 'CLEAR_INSIGHT_SUB_ACCOUNT':
      return state.insightsSubAccounts.includes(action.payload.insightsSubAccount) ? {...state, insightsSubAccounts: state.insightsSubAccounts.filter(acc => acc !== action.payload.insightsSubAccount) } : {...state};
    default:
      return state;
  }
};

export default userReducer;
