import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Tooltip,
  Title,
  LogarithmicScale
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar, Doughnut, Line, Radar } from "react-chartjs-2";
import {
  MdAdd,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "../../App.scss";
import ExpandedCalendar from "../components/expandedcalendar";
import "../styles/common.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import InfoToolTip from "../components/InfoToolTip";
import DataTooltip from "../components/ToolTip";
import { useTranslation } from "react-i18next";
import links from "../../common/links";
import Loader from "../components/Loader";
import createHeader from "../../redux/header/createHeader";
import {sendGet} from "../../common/request";
import languageData from "../data/languageData.json";
import currencyFormat from '../../common/format';
import {useTheme} from "../Providers/ThemeContext";
import Marquee from "../components/Marquee";
import MessageModal from "../components/Modal/MessageModal";
import ViewPermission from "../components/permissions/ViewPermission";
import permissions from "../data/Permissions.json";
import ActionPermission from "../components/permissions/ActionPermission";
import AddTradePageTabs from "../enums/AddTradePageTabs";
import useSubAccount from "../hooks/useSubAccount";

ChartJS.register(
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Filler,
  RadialLinearScale,
  Tooltip,
  LogarithmicScale
);

function Dashboard() {
  const selectedAccount = useSelector((state) => state.accounts.selectedAccounts);
  let { t } = useTranslation();
  const accounts = useSelector((state) => state?.user?.user);
  const [economicCalendarSettings, setEconomicCalendarSettings] = useState(undefined);
  const [data, setData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [totalPnl, setTotalPnl] = useState(0);
  const [positivePnl, setPositivePnl] = useState(0);
  const [negativePnl, setNegativePnl] = useState(0);
  const [numberOfPositiveDays, setNumberOfPositiveDays] = useState(0);
  const [numberOfNegativeDays, setNumberOfNegativeDays] = useState(0);
  const [numberOfBreakEvenDays, setNumberOfBreakEvenDays] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const endDate = useSelector((state) => state.date.endDate);
  const theme = useTheme();
  const [dailyProfit, setDailyProfit] = useState([]);
  const [dailyLoss, setDailyLoss] = useState([]);
  let navigate = useNavigate();
  const [weeklyStats, setWeeklyStats] = useState({})
  const green = "#29D6A8";
  const red = "#ec787d";
  const [goalObject, setGoalObject] = useState({shown: false, message: "", heading: "", mode: ''})
  const [tradesData, setTradesData] = useState({positive: 0, negative: 0, neutral: 0})
  const [openTrades, setOpenTrades] = useState([]);
  const [marqueeText, setMarqueeText] = useState('');
  const [balanceData, setBalanceData] = useState([]);
  const [calendarDate, setCalendarDate] = useState(new Date());

  const dispatch = useDispatch();
  dispatch(createHeader(t("dashboard_page"), true, false))

  let UsDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  let profitFactorData = {
    labels: [""],
    datasets: [
      {
        data: [
          positivePnl,
          positivePnl === 0 && negativePnl === 0 ? 100 : negativePnl
        ],
        backgroundColor: [green, red],
        circumference: 180,
        rotation: 270,
        borderColor: theme.theme === 'dark' ? '#111013' : '#FFF'
      },
    ],
  };

  let daysWinData = {
    labels: [""],
    datasets: [
      {
        data: [
          numberOfPositiveDays !== 0
            ? (numberOfPositiveDays /
                (numberOfPositiveDays + numberOfNegativeDays)) *
              100
            : 0,
          numberOfNegativeDays !== 0
            ? (numberOfNegativeDays /
                (numberOfPositiveDays + numberOfNegativeDays)) *
              100
            : numberOfPositiveDays === 0
            ? 100
            : 0,
        ],
        backgroundColor: [green, red],
        circumference: 180,
        rotation: 270,
        borderColor: theme.theme === 'dark' ? '#111013' : '#FFF'
      },
    ],
  };

  const subUserId = useSubAccount()?.id;

  const fetchMonthData = async () => {
    const response = await sendGet(links.GetMonthData(subUserId, {date: calendarDate}), true);
    if(response.error) {
      console.log(response.error);
    }
    else {
      const monthWiseData = {}
      for(const trade of response.trades) {
        const date = moment(trade.closeTime ? trade.closeTime : trade.openTime).format("DD-MM-YYYY");
        if(!monthWiseData[date]) {
          monthWiseData[date] = [];
        }
        monthWiseData[date].push(trade);
      }
      setMonthData(monthWiseData);
    }
  }

  useEffect(() => {
    if(subUserId) {
      fetchMonthData();
    }
  }, [subUserId, calendarDate])

  const loadEconomicCalendarSettings = async () => {
    const response = await sendGet(links.GetEconomicCalendar, true);
    if(response.error) {
      console.log(response.error)
    } else {
      setEconomicCalendarSettings(response.settings)
    }
  }

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await sendGet(links.SearchTrades(subUserId, {
        status: 'Closed',
        startDate: selectedDate,
        endDate: endDate,
        type: 'closeTime'
      }), true)

      if(response.error) {
        setTotalPnl(0);
        setPositivePnl(0);
        setNegativePnl(0);
        setNumberOfPositiveDays(0);
        setNumberOfNegativeDays(0);
        setDailyProfit([]);
        setDailyLoss([]);
        setData([]);
      }
      else {
        const data = response.trades;
        let dailyProfitObj = {};
        let dailyLossObj = {};
        let days = [];
        let trades = {
          positive: 0,
          negative: 0,
          neutral: 0,
        };
        // Step 2: Calculate daily profit and loss
        data.forEach((item) => {
          const closeDate = moment(item.data.CloseTime).format("DD/MM/YYYY");
          const pnl = parseFloat(item.data.PnL);
          if (!isNaN(pnl)) {
            if (!days.includes(closeDate)) days.push(closeDate);
            if (pnl > 0) {
              trades.positive += 1;
              // Positive PnL
              dailyProfitObj[closeDate] =
                  (dailyProfitObj[closeDate] || 0) + pnl;
            } else if (pnl < 0) {
              // Negative PnL
              trades.negative += 1;
              dailyLossObj[closeDate] = (dailyLossObj[closeDate] || 0) + pnl;
            }
            else {
              trades.neutral += 1;
            }
          }
        });
        setTradesData(trades);

        // Filter data for the specific subUserId
        const numberOfPositiveDays = [];
        const numberOfNegativeDays = [];
        const numberOfBreakEvenDays = [];
        const newSelectedDate = new Date(selectedDate);
        const NewEndDate = new Date(endDate);
        days.map((t) => {
          let date = moment(t, "DD/MM/YYYY").toDate();
          if (date >= newSelectedDate && date <= NewEndDate) {
            let profit = dailyProfitObj[t];
            let loss = dailyLossObj[t];
            if (profit && !loss) {
              numberOfPositiveDays.push(t);
            } else if (!profit && loss) {
              numberOfNegativeDays.push(t);
            } else {
              if (profit + loss > 0) {
                numberOfPositiveDays.push(t);
              } else if(profit + loss < 0) {
                numberOfNegativeDays.push(t);
              } else {
                numberOfBreakEvenDays.push(t);
              }
            }
          }
        });

        setData(data);
        let positivePnl = 0;
        let negativePnl = 0;

        const totalPNL = data.reduce((total, item) => {
          const closeDate = new Date(item?.data?.CloseTime).getTime();
          let newEndDate = new Date(endDate).getTime();
          const newSelectedDate = new Date(selectedDate).getTime();

          if (endDate === undefined) {
            return total;
          } else if (newEndDate === newSelectedDate) {
            let date = new Date(endDate);
            date.setHours(24);
            newEndDate = date.getTime();
          }

          if (
              item?.data &&
              item?.data?.PnL !== undefined &&
              closeDate >= newSelectedDate &&
              closeDate <= newEndDate
          ) {
            const pnl = parseFloat(item?.data?.PnL);
            // Check if pnl is a number before adding to total
            if (!isNaN(pnl)) {
              if (pnl > 0) {
                positivePnl += pnl;
              } else {
                negativePnl += pnl;
              }
              return total + pnl;
            } else {
              console.error("Invalid PnL value:", pnl);
              return total;
            }
          } else {
            console.error(
                "PnL is not available or not in the selected range:",
                item.data
            );
            return total;
          }
        }, 0);

        const dailyProfitArray = Object.entries(dailyProfitObj).map(
            ([day, pnl]) => ({
              day,
              pnl,
            })
        );

        const dailyLossArray = Object.entries(dailyLossObj).map(
            ([day, pnl]) => ({
              day,
              pnl,
            })
        );

        setNumberOfPositiveDays(numberOfPositiveDays.length);
        setNumberOfNegativeDays(numberOfNegativeDays.length);
        setNumberOfBreakEvenDays(numberOfBreakEvenDays.length)
        profitFactorData = {
          labels: [""],
          datasets: [
            {
              data: [
                positivePnl !== 0 ? positivePnl : 100,
                negativePnl !== 0 ? negativePnl : 100,
              ],
              backgroundColor: ["#FF9326", "#DEDDDF"],
              circumference: 180,
              rotation: 270,
            },
          ],
        };
        daysWinData = {
          labels: [""],
          datasets: [
            {
              data: [
                numberOfPositiveDays.length !== 0
                    ? numberOfPositiveDays.length
                    : 100,
                numberOfNegativeDays.length !== 0
                    ? numberOfNegativeDays.length
                    : 100,
              ],
              backgroundColor: ["#FF9326", "#DEDDDF"],
              circumference: 180,
              rotation: 270,
            },
          ],
        };
        setPositivePnl(positivePnl);
        setNegativePnl(negativePnl);
        setTotalPnl(parseFloat(totalPNL.toFixed(2)));
        setDailyProfit(dailyProfitArray.sort((d1, d2) => {
          return moment(d1.day, "DD/MM/YYYY").toDate() - moment(d2.day, "DD/MM/YYYY").toDate()
        }));
        setDailyLoss(dailyLossArray.sort((d1, d2) => {
          return moment(d1.day, "DD/MM/YYYY").toDate() - moment(d2.day, "DD/MM/YYYY").toDate()
        }));
      }
    } catch (error) {
      console.error("Error fetching sub accounts:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchOpenTrades = async () => {
    setIsLoading(true);
    const response = await sendGet(links.SearchTrades(subUserId,{
      status: 'Closed',
      statusMatch: 'ne',
      startDate: selectedDate,
      endDate: endDate,
      type: 'openTime'
    }), true);
    if(response.error) {
      console.log(response.error);
    }
    else {
      setOpenTrades(response.trades);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    loadEconomicCalendarSettings();
    fetchData();
    fetchOpenTrades();
  }, [
    accounts?.user?.subAccounts,
    data?.length,
    data?.subUserId,
    selectedAccount,
    subUserId,
    selectedDate,
    endDate,
  ]);

  let donutData = {
    labels: [""],
    datasets: [
      {
        data: [
            tradesData.positive,
            tradesData.negative + tradesData.neutral + tradesData.positive === 0 ? 100 : tradesData.negative + tradesData.neutral
        ],
        backgroundColor: [green, red],
        borderColor: theme.theme === 'dark' ? '#111013' : '#FFF'
      },
    ],
  };

  const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
  let lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          tickColor: gridColor,
        },
      },
      y: {
        border: {
          color: gridColor
        },
        grid: {
          color: gridColor,
          tickColor: gridColor,
        },
        ticks: {
          callback: function(value, index, ticks) {
            return currencyFormat.format(value);
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const primary = "#00ffb3";

  let labels = dailyProfit
      // .filter(day => {
      //   const date = moment(day.day, "DD/MM/YYYY").toDate()
      //   return date >= newSelectedDate && date <= newEndDate
      // })
      .map(entry => entry.day);

  dailyLoss
      // .filter(day => {
      //   const date = moment(day.day, "DD/MM/YYYY").toDate()
      //   return date >= newSelectedDate && date <= newEndDate
      // })
      .forEach(day => {
        if(!labels.includes(day.day)) {
          labels.push(day.day);
        }
      });

  labels = labels.sort((d1, d2) => {
    return moment(d1, "DD/MM/YYYY").toDate() - moment(d2, "DD/MM/YYYY").toDate();
  })

  const p = {};
  const n = {};
  dailyProfit?.forEach((day) => {
    p[day.day] = day.pnl;
  });

  dailyLoss?.forEach((day) => {
    n[day.day] = day.pnl;
  });

  const balanceLineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          tickColor: gridColor,
        },
      },
      y: {
        border: {
          color: gridColor
        },
        grid: {
          color: gridColor,
          tickColor: gridColor,
        },
        ticks: {
          callback: function(value, index, ticks) {
            return currencyFormat.format(value);
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const balanceLineData = {
    labels: balanceData.map(d => d.date),
    datasets: [
      {
        label: "Balance",
        data: balanceData.map(d => d.balance),
        borderColor: green,
        pointBackgroundColor: green,
      },
      {
        label: "Deposit/Withdrawal",
        data: balanceData.map(d => d.deposit),
        borderColor: red,
        pointBackgroundColor: red,
      },
    ],
  };

  let barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          tickColor: gridColor
        },
      },
      y: {
        grace: "1",
        border: {
          color: gridColor
        },
        grid: {
          color: gridColor,
          tickColor: gridColor,
        },
        ticks: {
          callback: function(value, index, ticks) {
            return currencyFormat.format(value);
          }
        }
      },
    },
    barThickness: 20,
    barPercentage: 0.9,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const formatData = (labels, p, n, cumulative = false) => {
    const data = [];
    for (let i = 0; i < labels.length; i++) {

      let lastData = data[i - 1];

      if(!lastData) {
        lastData = 0;
      }

      if(!cumulative) {
        lastData = 0;
      }

      const profit = p[labels[i]] || 0
      const loss = n[labels[i]] || 0;
      data.push(profit + loss + lastData);
    }
    return data;
  };

  const barChartData = {
    labels,
    datasets: [
      {
        label: "Daily P",
        data: formatData(labels, p, n),
        backgroundColor: [],
        borderRadius: 5,
      },
    ],
  };

  // Dynamically set the background color based on positive or negative value
  barChartData.datasets[0].backgroundColor = barChartData.datasets[0].data.map(
    (value) => (value > 0 ? green : red)
  );

  let radarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      r: {
        min: 0,
        max: 100,
        grace: 1,
        beginAtZero: true,
        angleLines: {
          color: gridColor
        },
        grid: {
          color: gridColor,
        },
        ticks: {
          display: false,
          stepSize: 20,
        },
        pointLabels: {
          color: gridColor,
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const netCumulativeData = {
    labels,
    datasets: [
      {
        label: "Balance",
        data: formatData(labels, p, n, true),
        borderColor: primary,
        pointBackgroundColor: primary,
      },
    ],
  };

  const areaData = {
    labels,
    datasets: [
      {
        label: "Balance",
        data: formatData(labels, p, n, true).map(d => Math.min(d, 0)),
        borderColor: red,
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        pointBackgroundColor: red,
        fill: true,
      },
    ],
  };


  // calculate win percentage between positivePnl and negativePnl
  const winPercentage = tradesData.neutral + tradesData.positive + tradesData.negative > 0 ? parseFloat(((tradesData.positive / (tradesData.negative + tradesData.positive + tradesData.neutral)) * 100).toFixed(2)) : 0;

  const clipValue = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
  };

  const avgWin = tradesData.positive > 0 ? positivePnl / tradesData.positive : positivePnl;
  const avgLoss  = tradesData.negative > 0 ? Math.abs(negativePnl) / tradesData.negative : Math.abs(negativePnl);
  let averageWinLoss = avgLoss > 0 ? avgWin / avgLoss : avgWin;
  const MAX_AVERAGE_WIN_LOSS = 7
  const ratio = averageWinLoss;
  averageWinLoss = parseFloat((clipValue(averageWinLoss, 0, MAX_AVERAGE_WIN_LOSS) / MAX_AVERAGE_WIN_LOSS).toFixed(2));
  let profitFactor = negativePnl === 0 ? positivePnl : Math.abs(positivePnl / negativePnl);
  const MAX_PROFIT_FACTOR = 6;
  const normalizedProfitFactor = parseFloat((clipValue(profitFactor, 0, MAX_PROFIT_FACTOR) / MAX_PROFIT_FACTOR).toFixed(2));

  const radarData = {
    labels: [t("dashboard.radar_chart.labels.0"), t("dashboard.radar_chart.labels.1"), t("dashboard.radar_chart.labels.2")],
    datasets: [
      {
        label: "",
        data: [
          averageWinLoss * 100,
          normalizedProfitFactor * 100,
          winPercentage,
        ],
        backgroundColor:
          positivePnl >= 0
            ? "rgba(41, 214, 168, 0.2)"
            : "rgba(236, 120, 125, 0.2)",
        borderColor:
          positivePnl >= 0 ? "rgba(41, 214, 168, 1)" : "rgba(41, 214, 168, 1)",
        borderWidth: 1,
      },
    ],
  };

  const weights = [0.3, 0.4, 0.3];
  let score = 0;
  const normalizedWinPercentage = parseFloat((winPercentage / 100).toFixed(2))
  score = ((normalizedWinPercentage * weights[0] + averageWinLoss * weights[1] + normalizedProfitFactor * weights[2]) * 100).toFixed(2);

  const filteredData = data.filter((item) => {
    const closeDate = new Date(item.data.CloseTime);
    let calendarSelectedDate = new Date(selectedDate);
    let calendarEndDate = new Date(endDate);
    return closeDate >= calendarSelectedDate && closeDate <= calendarEndDate;
  });

  const dateCounts = {};

  data.forEach((item) => {
    const closeDate = moment(item.data.CloseTime).format("DD/MM/YYYY");
    if (new Date(closeDate) >= new Date(selectedDate) && new Date(closeDate) <= new Date(endDate)) {
      // Increment count for close date
      dateCounts[closeDate] = (dateCounts[closeDate] || 0) + 1;
    }
  });

  const sortedData = data
    .filter((item) => item?.data?.PnL !== undefined)
    .sort((a, b) => {
      const dateA = new Date(a?.data?.CloseTime).setHours(0, 0, 0, 0);
      const dateB = new Date(b?.data?.CloseTime).setHours(0, 0, 0, 0);
      return dateB - dateA;
  });

  const getColor = (value, comparisonValue = 0) => {
    const positiveColor = '#29D6A8';
    const negativeColor = '#ec787d'
    return value > comparisonValue ? positiveColor : (value < comparisonValue ? negativeColor : (comparisonValue !== 0 ? positiveColor : ''));
  }

  const getWeeklyTradingStats = async () => {
    const response = await sendGet(links.GetWeeklyTradingStats(subUserId), true);
    if(response.error) {
      setWeeklyStats({});
      console.log(response.error)
    }
    else {
      const weeklyStats = response.stats;
      setWeeklyStats(weeklyStats);
      let text = t("dashboard.marquee")
      text = text.replace("{pnl}", `<span style="color: ${getColor(weeklyStats.pnl)}">${currencyFormat.format(weeklyStats.pnl)}</span>`);
      text = text.replace('{growth}', `<span style="color: ${getColor(weeklyStats.growth)}">${weeklyStats.growth}</span>%`);
      text = text.replace('{rrr}', `<span style="color: ${getColor(weeklyStats.rrr, 1)}">${weeklyStats.rrr}</span>`);
      text = text.replace('{trades}', `${weeklyStats.trades}`);
      text = text.replace('{long_trades}', `${weeklyStats.long}`);
      text = text.replace('{short_trades}', `${weeklyStats.short}`);
      text = text.replace('{winning_trades}', `${weeklyStats.winningTrades}`);
      text = text.replace('{losing_trades}', `${weeklyStats.losingTrades}`);
      text = text.replace('{hit_rate}', `<span style="color: ${getColor(weeklyStats.hitRate)}">${weeklyStats.hitRate}%</span>`);
      setMarqueeText(text)
    }
  }

  const onModalClose = () => {
    setGoalObject({
      ...goalObject,
      shown: false,
    })

    let modalCloseData = localStorage.getItem("modal_closed");
    if(modalCloseData) {
      modalCloseData = JSON.parse(modalCloseData);
    }
    else {
      modalCloseData = {};
    }
    modalCloseData[subUserId] = moment(new Date()).format("DD-MM-YYYY");
    localStorage.setItem("modal_closed", JSON.stringify(modalCloseData));
  }

  const getDailyGoalStatus = async () => {
    const response = await sendGet(links.GetDailyProfitGoalStatus(subUserId), true);
    if(response.error) {
      console.log(response.error)
    }
    else {

      let canShowModal = false;
      let modalCloseData = localStorage.getItem("modal_closed");
      let modalCloseDate = undefined;

      if(modalCloseData) {
        try {
          modalCloseData = JSON.parse(modalCloseData);
        }
        catch (error) {
          localStorage.removeItem('modal_closed');
          return;
        }
        modalCloseDate = modalCloseData[subUserId];
      }

      if(modalCloseDate) {
        const date = moment(modalCloseDate, "DD-MM-YYYY").toDate();
        const currentDate = new Date();
        canShowModal = !(date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear());
      }
      else {
        canShowModal = true;
      }

      if(canShowModal) {
        if(response.profitGoalAchieved) {
          setGoalObject({
            shown: true,
            message: t("dashboard.daily_goals_message.profit"),
            heading: t("dashboard.daily_goals_message.headings.profit"),
            mode: 'success'
          })
        }
        else if (response.lossGoalAchieved) {
          setGoalObject({
            shown: true,
            message: t("dashboard.daily_goals_message.loss"),
            heading: t("dashboard.daily_goals_message.headings.loss"),
            mode: 'error'
          })
        }
        else if (response.maxTradedContractsGoalAchieved) {
          setGoalObject({
            shown: true,
            message: t("dashboard.daily_goals_message.contracts"),
            heading: t("dashboard.daily_goals_message.headings.contracts"),
            mode: 'warning'
          })
        }
        else if (response.tradesGoalAchieved) {
          setGoalObject({
            shown: true,
            message: t("dashboard.daily_goals_message.trades"),
            heading: t("dashboard.daily_goals_message.headings.trades"),
            mode: 'warning'
          })
        }
      }
    }
  }

  const getBalanceData = async () => {
    const response = await sendGet(links.GetBalanceData(subUserId, { startDate: selectedDate, endDate: endDate }), true);
    if(response.error) {
      console.log(response.error);
    }
    else {
      setBalanceData(response.balanceData)
    }
  }

  useEffect(() => {
    getBalanceData()
  }, [subUserId, selectedDate, endDate]);

  useEffect(() => {
    if(subUserId) {
      getWeeklyTradingStats();
      getDailyGoalStatus();
    }
  }, [subUserId]);

  const onCloseTrade = (e, trade) => {
    e.stopPropagation();
    e.preventDefault();
    navigate('/addtrade', {state: {tab: AddTradePageTabs.ManualInsert, trade: trade}});
  }

  return (
    <>
      <MessageModal message={goalObject.message} isOpen={goalObject.shown} heading={goalObject.heading} handleCloseModal={onModalClose} mode={goalObject.mode} />
      {isLoading ? (
        <Loader
          fullScreen={true}
          text={"Hang Tight! We are loading the data"}
        />
      ) : (
          <ViewPermission permission={permissions.PAGE.DASHBOARD}>
            <div className={`page-body ${theme.getThemeClass()}`}>
              <div className='dashboard-header-container'>
                <div className="flex-horizontal">
                  <div className='dashboard-header'>
                    <div className="header">
                      {t("welcome_text")} {accounts?.user?.lastName}
                    </div>
                  </div>
                  <ActionPermission className="btn btn-primary" onClick={() => navigate("/addtrade")} permission={permissions.PAGE.TRADE_IMPORT}>
                    <MdAdd/>
                    {t("add_trade_btn")}
                  </ActionPermission>
                </div>
                <div className="marquee-container">
                  {
                    weeklyStats.trades ?
                        <Marquee>
                          <div className="message" dangerouslySetInnerHTML={{__html: marqueeText}}></div>
                        </Marquee> : <></>
                  }
                </div>
              </div>
              {/* Top 5 Components */}
              <div className="grid grid-col-3 mt-2 top-row">
                <div className="grid grid-row-2">
                  <ViewPermission permission={permissions.FEATURES.NET_PNL}>
                    <div className="stat-item item">
                      <div className="label">
                        {t("net_p&l_heading")}
                        <InfoToolTip message={t("net_p&l_tooltip")}/>
                        <DataTooltip message={t("net_p&l_total_trades")}>
                          <div className="dark">
                            {filteredData?.length ? filteredData?.length : 0}
                          </div>
                        </DataTooltip>
                      </div>
                      <div
                          className={`value ${
                              totalPnl !== undefined
                                  ? totalPnl >= 0
                                      ? "green"
                                      : "red"
                                  : ""
                          }`}
                      >
                        {currencyFormat.format(totalPnl)}
                      </div>
                    </div>
                  </ViewPermission>
                  <ViewPermission permission={permissions.FEATURES.AVG_WIN_LOSS}>
                    <div className="stat-item item">
                      <div className="label">
                        {t("average_win_loss_heading")}
                        <InfoToolTip message={t("average_win_loss_tooltip")}/>
                      </div>
                      <div className="flex-horizontal">
                        <div
                            className={`value ${
                                ratio !== undefined ? (ratio >= 0 ? "green" : "red") : ""
                            }`}
                        >
                          {ratio.toFixed(2)}
                        </div>
                        <div className="bar">
                          <div
                              className={`fill-bar ${
                                  ratio !== undefined
                                      ? ratio >= 0
                                          ? "green"
                                          : "red"
                                      : ""
                              }`}
                              style={{
                                width: `${winPercentage}%`,
                              }}
                          ></div>
                          <div className="flex-horizontal mnt-3 chart-label">
                            <div>{currencyFormat.format(positivePnl)}</div>
                            <div>{currencyFormat.format(negativePnl)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ViewPermission>
                </div>
                <div className="stat-item item">
                  <div className="chart-item-container">
                    <div className="chart-item">
                      <Doughnut
                          data={donutData}
                          options={{
                            cutout: 80,
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                      />
                      <div className="legend">
                        <div className="label">
                          {t("trade_win_percentage_heading")}{" "}
                          <InfoToolTip
                              message={t("trade_win_percentage_tooltip")}
                          />
                        </div>
                        <div
                            className={`value ${
                                winPercentage !== undefined
                                    ? winPercentage >= 0
                                        ? "green"
                                        : "red"
                                    : ""
                            }`}
                        >
                          {winPercentage !== 0 || winPercentage !== "NaN"
                              ? winPercentage
                              : 0}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-row-2">
                  <ViewPermission permission={permissions.FEATURES.PROFIT_FACTOR}>
                    <div className="stat-item item">
                      <div className="flex-horizontal">
                        <div className="flex-vertical">
                          <div className="label">
                            {t("profit_factor_heading")}
                            <InfoToolTip message={t("profit_factor_tooltip")}/>
                          </div>
                          <div className={`value ${profitFactor === 0 ? 'gray' : profitFactor > 1 ? "green" : "red" }`}>
                            {profitFactor.toFixed(2)}
                          </div>
                        </div>
                        <div className="chart-item-container">
                          <div className="flex-vertical">
                            <div className="chart-item-sm">
                              <Doughnut
                                  data={profitFactorData}
                                  options={{
                                    cutout: 26,
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                  height={"70px"}
                                  width={"150px"}
                              />
                            </div>
                            <div className="flex-horizontal px-1">
                              <DataTooltip
                                  message={t("positive_trades_tooltip")}
                              >
                                <div className="chart-label green">
                                  {tradesData && tradesData.positive ? tradesData.positive : 0}
                                </div>
                              </DataTooltip>
                              <DataTooltip message={t("neutral_trades_tooltip")}>
                                <div className="chart-label">{tradesData && tradesData.neutral ? tradesData.neutral : 0}</div>
                              </DataTooltip>
                              <DataTooltip
                                  message={t("negative_trades_tooltip")}
                                  left={true}
                              >
                                <div className="chart-label red">
                                  {tradesData && tradesData.negative ? tradesData.negative : 0}
                                </div>
                              </DataTooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ViewPermission>
                  <ViewPermission permission={permissions.FEATURES.WINNING_DAYS}>
                    <div className="stat-item item">
                      <div className="flex-horizontal">
                        <div className="flex-vertical">
                          <div className="label">
                            {t("days_win_heading")}
                            <InfoToolTip message={t("days_win_tooltip")}/>
                          </div>
                          <div
                              className={`value ${
                                  numberOfNegativeDays !== undefined &&
                                  numberOfPositiveDays !== undefined
                                      ? numberOfPositiveDays >= numberOfNegativeDays
                                          ? "green"
                                          : "red"
                                      : ""
                              }`}
                          >
                            {numberOfPositiveDays ? Math.round(((numberOfPositiveDays / (numberOfPositiveDays + numberOfNegativeDays + numberOfBreakEvenDays)) * 100) * 100) / 100 : 0}%
                          </div>
                        </div>
                        <div className="chart-item-container">
                          <div className="flex-vertical">
                            <div className="chart-item-sm">
                              <Doughnut
                                  data={daysWinData}
                                  options={{
                                    cutout: 26,
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                  height={"70px"}
                                  width={"150px"}
                              />
                            </div>
                            <div className="flex-horizontal px-1">
                              <DataTooltip
                                  message={t("positive_number_of_days_tooltip")}
                              >
                                <div className="chart-label green">
                                  {numberOfPositiveDays ? numberOfPositiveDays : 0}
                                </div>
                              </DataTooltip>
                              <DataTooltip message={t("break_even_tooltip")}>
                                <div className="chart-label">{numberOfBreakEvenDays ? numberOfBreakEvenDays : 0}</div>
                              </DataTooltip>
                              <DataTooltip
                                  message={t("negative_number_of_days_tooltip")}
                                  left={true}
                              >
                                <div className="chart-label red">
                                  {numberOfNegativeDays ? numberOfNegativeDays : 0}
                                </div>
                              </DataTooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ViewPermission>
                </div>
              </div>
              {/* Daily P&L, PNL and Score */}
              <div className="grid grid-col-3 grid-expand-1 center-row mt-2">
                <ViewPermission permission={permissions.FEATURES.ACCOUNT_BALANCE}>
                  <div className="item">
                    <div className="flex-horizontal item-header">
                      <div className="heading">
                        {t("account_balance_heading")}{" "}
                        <InfoToolTip message={t("account_balance_tooltip")}/>
                      </div>
                      <div className="legend">
                        <div className="legend-item">
                          <div className="color red"></div>
                          {t("deposit_withdrawl_legend")}
                        </div>
                        <div className="legend-item">
                          <div className="color green"></div>
                          {t("balance_legend")}
                        </div>
                      </div>
                    </div>
                    <div className="chart">
                      <Line
                          data={balanceLineData}
                          options={balanceLineOptions}
                      />
                    </div>
                  </div>
                </ViewPermission>
                <ViewPermission permission={permissions.FEATURES.DAILY_PNL}>
                  <div className="item">
                    <div className="flex-horizontal item-header">
                      <div className="heading">
                        {t("daily_pnl_heading")}{" "}
                        <InfoToolTip message={t("daily_pnl_tooltip")}/>
                      </div>
                    </div>
                    <div className="chart">
                      <Bar
                          data={barChartData}
                          options={barChartOptions}
                      />
                    </div>
                  </div>
                </ViewPermission>
                <div className="item">
                  <div className="flex-horizontal item-header">
                    <div className="heading">
                      {t("score_heading")}{" "}
                      <InfoToolTip message={t("score_tooltip")}/>
                    </div>
                    <div className="score">{score}</div>
                  </div>
                  <div className="chart">
                    <Radar
                        data={radarData}
                        options={radarOptions}
                    />
                  </div>
                </div>
              </div>
              {/* Positions, Net Cumulative P&L and Drawdown */}
              <div className="grid grid-col-2 bottom-row mt-2 ">
                <div className="item list">
                  <div className="flex-horizontal item-header">
                    <div className="heading">
                      {t("recently_closed_positions_heading")}
                    </div>
                  </div>
                  <div className={`table table-col-3`}>
                    <div className="header">
                      <div className="cell">
                        {t("recently_closed_positions_close_date")}
                      </div>
                      <div className="cell">
                        {t("recently_closed_positions_symbol")}
                      </div>
                      <div className="cell">
                        {t("recently_closed_positions_net_p&l")}
                      </div>
                    </div>
                    <div className="rows">
                      {sortedData?.map((item) => {
                        const newSelectedDate = new Date(selectedDate).setHours(0, 0, 0, 0);
                        const newEndDate = new Date(endDate).setHours(0, 0, 0, 0);

                        const days = [];
                        let currentDate = new Date(newSelectedDate);

                        while (currentDate <= newEndDate) {
                          days.push(moment(currentDate).format("DD/MM/YYYY"));
                          currentDate.setDate(currentDate.getDate() + 1);
                        }

                        const closeDate = new Date(item?.data?.CloseTime);

                        if (
                            item?.data &&
                            item?.data?.PnL !== undefined &&
                            moment(closeDate).format("DD/MM/YYYY") !== "Invalid date" &&
                            days.includes(moment(closeDate).format("DD/MM/YYYY"))
                        ) {
                          return (
                              <div key={item.id} className="row" onClick={() => navigate(`/trade/${item.id}`)}>
                                <div className="cell">
                                  {moment(item?.data?.CloseTime).format("DD/MM/YYYY")}
                                </div>
                                <div className="cell"><div className="limited">{item?.data?.SecurityId}</div></div>
                                <div
                                    className={`cell ${
                                        item?.data?.PnL > 0 ? "green" : "red"
                                    }`}
                                >
                                  {UsDollar.format(item?.data?.PnL)}
                                </div>
                              </div>
                          );
                        }

                        return null;
                      })}
                    </div>
                  </div>
                </div>
                <div className="item list">
                  <div className="flex-horizontal item-header">
                    <div className="heading">
                      {t("dashboard.openPositions.title")}
                    </div>
                  </div>
                  <div className={`table table-col-4`}>
                    <div className="header">
                      <div className="cell">
                        {t("dashboard.openPositions.headers.openDate")}
                      </div>
                      <div className="cell">
                        {t("dashboard.openPositions.headers.symbol")}
                      </div>
                      <div className="cell">
                        {t("dashboard.openPositions.headers.openPrice")}
                      </div>
                      <div className="cell">{t("dashboard.openPositions.headers.action")}</div>
                    </div>
                    <div className="rows">
                      {openTrades?.map((trade) => {
                        return (
                            <div key={trade.id} className="row" onClick={() => navigate(`/trade/${trade.id}`)}>
                              <div className="cell">
                                {moment(trade?.data?.OpenTime).format("DD/MM/YYYY")}
                              </div>
                              <div className="cell"><div className="limited">{trade?.data?.SecurityId}</div></div>
                              <div className={`cell`}>
                                {currencyFormat.format(trade?.data?.OpenPrice)}
                              </div>
                              <div className="cell">
                                <div className="danger-btn transparent" onClick={e => onCloseTrade(e, trade)}>
                                  {t("dashboard.openPositions.action.close")}
                                </div>
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="charts">
                  <ViewPermission permission={permissions.FEATURES.NET_CUMULATIVE}>
                    <div className="item">
                      <div className="flex-horizontal item-header">
                        <div className="heading">
                          {t("net_cumulative_p&l_heading")}{" "}
                          <InfoToolTip message={t("net_cumulative_p&l_tooltip")}/>
                        </div>
                      </div>
                      <div className="chart">
                        <Line
                            data={netCumulativeData}
                            options={lineOptions}
                        />
                      </div>
                    </div>
                  </ViewPermission>
                  <ViewPermission permission={permissions.FEATURES.DRAW_DOWN}>
                    <div className="item">
                      <div className="flex-horizontal item-header">
                        <div className="heading">
                          {t("drawdown_heading")}{" "}
                          <InfoToolTip message={t("drawdown_tooltip")}/>
                        </div>
                      </div>
                      <div className="chart">
                        <Line
                            data={areaData}
                            options={lineOptions}
                        />
                      </div>
                    </div>
                  </ViewPermission>
                </div>
              </div>
              <div className="item mt-2">
                <ViewPermission permission={permissions.FEATURES.CALENDAR_VIEW}>
                  <ExpandedCalendar
                      title={t("calendar_title")}
                      data={monthData}
                      onDateChange={setCalendarDate}
                  />
                </ViewPermission>
              </div>
              <div className="grid grid-col-2 mt-2 last-row">
                {
                  economicCalendarSettings && accounts ?
                      <ViewPermission permission={permissions.FEATURES.ECONOMIC_CALENDAR}>
                        <div className="economic-calendar">
                          <div className="item">
                            <iframe id="frame"
                                    src={`https://sslecal2.investing.com?columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&importance=${economicCalendarSettings.newsImportance.map(imp => imp.id).join(',')}&features=datepicker,timezone,timeselector,filters&countries=${economicCalendarSettings.countries.map(country => country.id).join(',')}&calType=day&timeZone=16&lang=${languageData[accounts.user.language]}`}
                                    width="650" height="467" allowTransparency="true">
                            </iframe>
                            <div className="poweredBy" style={{fontFamily: "Arial, Helvetica, sans-serif"}}>
                      <span style={{fontSize: "11px", color: "#333333", textDecoration: "none"}}>
                        Der Wirtschaftskalender wird Ihnen von
                        <a href="https://de.investing.com/" rel="nofollow" target="_blank"
                           style={{fontSize: "11px", color: "#06529D", fontWeight: "bold"}}
                           className="underline_link">Investing.com Deutschland
                        </a>, dem führenden Finanzportal, zur Verfügung gestellt.
                      </span>
                            </div>
                          </div>
                        </div>
                      </ViewPermission> : <></>
                }
                <div className="coming-soon item">
                  <div className="text">
                    <div className="content">
                      {t("dashboard.new_feature_message")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ViewPermission>
      )}
    </>
  );
}

export default Dashboard;
