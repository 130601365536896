import {MdArrowDownward} from "react-icons/md";
import {useTranslation} from "react-i18next";

const HeroSection = () => {

    const onViewMore = () => {
        const nextSection = document.getElementById("next-section");
        nextSection.scrollIntoView({behavior: "smooth"});
    }

    const {t} = useTranslation();

    return (
        <div className='hero-section front' id={'home'}>
            <div className="content">
                <h1 className="heading">
                    {t("brokersPage.heroSection.heading")}
                </h1>
                <p className='description'>
                    {t("brokersPage.heroSection.description")}
                </p>
                <div className="horizontal-container">
                    <div className="button outline-btn" onClick={onViewMore}>
                        {t("brokersPage.heroSection.viewMore")} <MdArrowDownward />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;