import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { sendGet } from "../../../common/request";
import links from "../../../common/links";
import { useTranslation } from "react-i18next";
import currencyFormat from "../../../common/format";
import {useTheme} from "../../Providers/ThemeContext";
import permissions from "../../data/Permissions.json";
import ViewPermission from "../permissions/ViewPermission";
import useSubAccount from "../../hooks/useSubAccount";
import {toast} from "react-toastify";
import moment from "moment/moment";

const MonthsReport = () => {
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const endDate = useSelector((state) => state.date.endDate);
  const [accumulatedData, setAccumulatedData] = useState([]);
  let { t } = useTranslation();
  const theme = useTheme();
  const subAccount = useSubAccount();

  async function fetchData() {
    try {
      const d = new Date(selectedDate);
      const lastDate = new Date(endDate);
      const tradesEndDate = new Date(endDate);

      while(lastDate?.getMonth() === tradesEndDate?.getMonth()) {
        lastDate.setDate(lastDate.getDate() + 1);
      }

      lastDate.setDate(lastDate.getDate() - 1);

      const response = await sendGet(links.SearchTrades(subAccount.id, {
        status: 'Closed',
        startDate: new Date(d.getFullYear(), d?.getMonth(), 1, 0, 0, 0),
        endDate: lastDate
      }), true);

      if(response.error) {
        toast.error(response.error);
        setAccumulatedData([]);
      }
      else {

        const groupedData = accumulateDataByMonth(response.trades);

        const monthsOrder = [
          t("month_jan"),
          t("month_feb"),
          t("month_mar"),
          t("month_apr"),
          t("month_may"),
          t("month_jun"),
          t("month_jul"),
          t("month_aug"),
          t("month_sep"),
          t("month_oct"),
          t("month_nov"),
          t("month_dec"),
        ];

        const orderedData = monthsOrder.map(month => {
          return groupedData.filter(t => t.month === month)[0];
        })
            .filter(t => t)

        setAccumulatedData(orderedData)
      }
    } catch (error) {
      console.error("Error fetching sub accounts:", error);
    }
  }

  const getMonth = (date) => {

    if(!date) {
      return null;
    }

    let months = [
      t("month_jan"),
      t("month_feb"),
      t("month_mar"),
      t("month_apr"),
      t("month_may"),
      t("month_jun"),
      t("month_jul"),
      t("month_aug"),
      t("month_sep"),
      t("month_oct"),
      t("month_nov"),
      t("month_dec"),
    ];
    return months[date.getMonth()];
  };

  const accumulateDataByMonth = (trades) => {
    const accumulatedData = {};

    for(const trade of trades) {
      const monthString = moment(trade.closeTime).format("MMMM YYYY");
      const month = getMonth(moment(trade.closeTime).toDate());
      if(!accumulatedData[monthString]) {
        accumulatedData[monthString] = {
          date: "",
          month: "",
          netProfit: 0,
          winning: 0,
          totalProfit: 0,
          totalLoss: 0,
          trades: 0,
          winningTrades: 0,
          volume: 0
        }
      }

      const pnl = trade.data.PnL;

      accumulatedData[monthString].date = monthString;
      accumulatedData[monthString].month = month;
      accumulatedData[monthString].netProfit += pnl;
      accumulatedData[monthString].trades += 1;
      accumulatedData[monthString].volume += Math.abs(trade.data.OpenVolume);

      if(pnl > 0) {
        accumulatedData[monthString].totalProfit += pnl;
        accumulatedData[monthString].winningTrades += 1;
      }
      else if (pnl < 0) {
        accumulatedData[monthString].totalLoss += pnl;
      }
      accumulatedData[monthString].winning = parseFloat(((accumulatedData[monthString].winningTrades / accumulatedData[monthString].trades) * 100).toFixed(2));
    }

    return Object.values(accumulatedData)
  };

  useEffect(() => {
    fetchData();
  }, [subAccount, selectedDate, endDate]);

  const green = "#29D6A8";
  const red = "#ec787d";
  const blue = '#2d90cc'
  const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';

  const barSecondHighChartOptionsMonths = {
    chart: {
      type: "bar",
      backgroundColor: theme.theme === 'dark' ? "transparent" : 'white'
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: accumulatedData.map(acc => acc.date),
      title: {
        text: "MonthsReport",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      lineColor: gridColor
    },
    yAxis: {
      title: {
        text: "Accumulated PnL",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      gridLineColor: gridColor,
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        },
        formatter: (data) => {
          return currencyFormat.format(data.value)
        }
      }
    },
    series: [
      {
        pointWidth: 30,
        showInLegend: false,
        data: accumulatedData.map(acc => acc.netProfit),
        color: green,
        negativeColor: red,
        borderColor: 'transparent'
      },
    ],
  };

  const barHighChartOptionsMonths = {
    chart: {
      type: "bar",
      backgroundColor: theme.theme === 'dark' ? "transparent" : 'white'
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: accumulatedData.map(acc => acc.date),
      title: {
        text: "MonthsReport",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      lineColor: gridColor
    },
    yAxis: {
      title: {
        text: "Accumulated PnL",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      gridLineColor: gridColor,
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        },
      }
    },
    series: [
      {
        pointWidth: 30,
        showInLegend: false,
        data: accumulatedData.map(acc => acc.trades),
        color: blue,
        borderColor: 'transparent'
      },
    ],
  };

  return (
      <ViewPermission permission={permissions.PAGE.REPORTS.MONTHS}>
        <div className="w-full flex flex-col items-center pt-4">
          <div className="items-center w-11/12 justify-center m-0 sm:m-2 md:m-4 p-0">
            <div className="flex gap-4 mb-4 w-full items-center">
              <div
                  className="flex flex-col mt-1 w-full md:flex-col sm:flex-col xs:flex-col lg:flex-row xl:flex-row 2xl:flex-row gap-4">
                <div
                    className={`rounded-md p-4 gap-4 w-full overflow-hidden ${theme.theme === 'dark' ? 'transparent' : 'bg-white'}`}
                    style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
                  <h3 className={`text-base font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                    {t("months_report_distribution_heading")} <br/> ({t("reports.all_trades")})
                  </h3>
                  <div className="high-chart">
                    <HighchartsReact
                        containerProps={{style: {width: "100%"}}}
                        highcharts={Highcharts}
                        options={barHighChartOptionsMonths}
                    />
                  </div>
                </div>
                <div
                    className={`rounded-md p-4 gap-4 w-full overflow-hidden ${theme.theme === 'dark' ? 'transparent' : 'bg-white'}`}
                    style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
                  <h3 className={`text-base font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                    {t("months_report_performance_heading")} <br/> ({t("reports.months_report.monthly_pnl")})
                  </h3>
                  <div className="high-chart">
                    <HighchartsReact
                        containerProps={{style: {width: "100%"}}}
                        highcharts={Highcharts}
                        options={barSecondHighChartOptionsMonths}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
                className={`rounded-md p-4 gap-4 w-full overflow-hidden ${theme.theme === 'dark' ? 'transparent' : 'bg-white'}`}
                style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
              <h1 className={`text-lg font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-gray-800'}`}>
                {t("months_report_summary_heading")}
              </h1>
              <hr className={`border-t my-2 ${theme.theme === 'dark' ? 'border-gray-500' : 'border-gray-200'}`}/>
              <div className="rounded-sm p-5 mt-5 overflow-hidden overflow-x-auto w-full">
                <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      fontWeight: "bold",
                      minWidth: "1000px"
                    }}
                    className={`bg-primary-base border-b ${theme.theme === 'dark' ? 'border-b-gray-500' : 'border-b-gray-200'}`}>
                  <div className="flex-1">{t("months_report_label_month")}</div>
                  <div className="flex-1">{t("months_report_label_net_profit")}</div>
                  <div className="flex-1">{t("months_report_label_winning")}</div>
                  <div className="flex-1">{t("months_report_label_total_profit")}</div>
                  <div className="flex-1">{t("months_report_label_total_loss")}</div>
                  <div className="flex-1">{t("months_report_label_trades")}</div>
                  <div className="flex-1">{t("months_report_label_volume")}</div>
                </div>
                {accumulatedData.map((entry, index) => (
                    <div key={index}
                         className={`flex items-center justify-center border-b ${theme.theme === 'dark' ? 'border-b-gray-500 text-gray-100' : 'border-b-gray-200 text-gray-700'} ${theme.theme === 'dark' ? (index % 2 === 0 ? "bg-gray-600" : "bg-gray-650") : (index % 2 === 0 ? "bg-white" : "bg-gray-75")}`}
                         style={{
                           display: "flex",
                           padding: '10px',
                           minWidth: "1000px",
                           cursor: 'pointer',
                         }}>
                      <div className="flex-1">{entry.date}</div>
                      <div
                          className={`flex-1 ${theme.theme === 'dark' ? (entry.netProfit >= 0 ? "text-green-200" : "text-red-200") : (entry.netProfit >= 0 ? "text-green-400" : "text-red-400")}`}>
                        {currencyFormat.format(entry.netProfit)}
                      </div>
                      <div className="flex-1">{entry.winning.toFixed(2)}%</div>
                      <div
                          className={`flex-1 ${theme.theme === 'dark' ? (entry.totalProfit > 0 ? "text-green-200" : "text-gray-100") : (entry.totalProfit > 0 ? "text-green-400" : "text-black")}`}>
                        {currencyFormat.format(entry.totalProfit)}
                      </div>
                      <div
                          className={`flex-1 ${theme.theme === 'dark' ? (entry.totalLoss >= 0 ? "text-gray-100" : "text-red-200") : (entry.totalLoss >= 0 ? "text-black" : "text-red-400")}`}>
                        {currencyFormat.format(entry.totalLoss)}
                      </div>
                      <div className="flex-1">{entry.trades}</div>
                      <div className="flex-1">{entry.volume.toFixed(2)}</div>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ViewPermission>
  );
};

export default MonthsReport;
