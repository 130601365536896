import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./assets/pages/dashboard";
import DailyJournal from "./assets/pages/dailyjournal";
import AddTrade from "./assets/pages/addtradepage";
import LoginPage from "./assets/pages/login";
import RegisterPage from "./assets/pages/register";
import ForgotPasswordPage from "./assets/pages/forgotpassword";
import TwoFactorAuthenticationPage from "./assets/pages/twofactorauthentication";
import ResetPasswordPage from "./assets/pages/resetpassword";
import JournalDetails from "./assets/pages/journalDetails";
import Settings from "./assets/pages/settings";
import ManageTagsPage from "./assets/pages/setting/ManageTagsPage";
import ProfilePage from "./assets/pages/setting/ProfilePage";
import AccountsPage from "./assets/pages/setting/AccountsPage";
import SecurityPage from "./assets/pages/setting/SecurityPage";
import Reports from "./assets/pages/Reports";
import OverviewReport from "./assets/components/report/overview-report";
import DaysReport from "./assets/components/report/days-report";
import MonthsReport from "./assets/components/report/months-report";
import SetupsReport from "./assets/components/report/setups-report";
import TradePage from "./assets/pages/TradePage";
import NoteBook from "./assets/pages/NoteBook";
import Playbook from "./assets/pages/Playbook";
import PlaybookLayout from "./assets/pages/playbook-pages/playbook-layout";
import PlaybookOverviewPage from "./assets/pages/playbook-pages/playbook-overview";
import PlaybookRulesPage from "./assets/pages/playbook-pages/playbook-rules";
import PlaybookExecutions from "./assets/pages/playbook-pages/playbook-executions";
import ShareTradePage from "./assets/pages/share-trade-page";
import Community from "./assets/pages/community";
import EconomicCalendar from "./assets/pages/setting/EconomicCalendar";
import LandingPage from "./assets/pages/LandingPage";
import PricingPage from "./assets/pages/PricingPage";
import PaymentPage from "./assets/pages/PaymentPage";
import ConfirmationPage from "./assets/pages/ConfirmationPage";
import AuthenticateUser from "./assets/components/AuthenticateUser";
import SubscriptionPage from "./assets/pages/setting/SubscriptionPage";
import WinLossDistributionReport from "./assets/components/report/win-loss-distribution-report";
import RiskRewardRatioReport from "./assets/components/report/risk-reward-ratio-report";
import DailyGoalsPage from "./assets/pages/setting/DailyGoalsPage";
import Root from "./root";
import AuthenticationLayout from "./assets/layouts/AuthenticationLayout";
import BrokersPage from "./assets/pages/BrokersPage";
import CommissionsPage from "./assets/pages/setting/CommissionsPage";
import PrivacyPage from "./assets/pages/PrivacyPage";
import TermsAndConditionsPage from "./assets/pages/TermsAndConditionsPage";
import AccountActivationPage from "./assets/pages/AccountActivation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <AuthenticationLayout />,
        children: [
          {
            path: "/",
            element:
              <LandingPage />
          },
          {
            path: "/brokers",
            element: <BrokersPage />
          },
          {
            path: "/pricing",
            element:
              <PricingPage />
          },
          {
            path: "/payment/:packageName/:period/:purpose",
            element:
              <AuthenticateUser shallRedirect={true}>
                <PaymentPage />
              </AuthenticateUser>
          },
          {
            path: "/privacy-policy",
            element: <PrivacyPage />
          },
          {
            path: "/terms-conditions",
            element: <TermsAndConditionsPage />
          },
          {
            path: "/confirm",
            element:
              <AuthenticateUser shallRedirect={true}>
                <ConfirmationPage />
              </AuthenticateUser>
          },
          {
            path: "/",
            element: <Root />,
            children: [
              {
                path: "/dashboard",
                element: <Dashboard />,
                meta: {
                  name: "dashboard",
                },
              },
              {
                path: "/daily-journal",
                element: <DailyJournal />,
              },
              {
                path: "/daily-journal/:date",
                element: <JournalDetails />,
              },
              {
                path: "/trade/:id",
                element: <TradePage />,
              },
              {
                path: "/notebook",
                element: <NoteBook />,
              },
              {
                path: "/playbook",
                element: <Playbook />,
              },
              {
                path: "/playbook-details",
                element: <PlaybookLayout />,
                children: [
                  {
                    path: "overview/:id",
                    element: <PlaybookOverviewPage />,
                  },
                  {
                    path: "rules/:id",
                    element: <PlaybookRulesPage />,
                  },
                  {
                    path: "executions/:id",
                    element: <PlaybookExecutions />,
                  },
                ],
              },
              {
                path: "/community",
                element: <Community />,
              },
              {
                path: "/reports",
                element: <Reports />,
                children: [
                  { path: "/reports/overview", element: <OverviewReport /> },
                  {
                    path: "/reports/days",
                    element: <DaysReport />,
                  },
                  {
                    path: "/reports/months",
                    element: <MonthsReport />,
                  },
                  {
                    path: "/reports/setups",
                    element: <SetupsReport />,
                  },
                  {
                    path: "/reports/winLossDistribution",
                    element: <WinLossDistributionReport />,
                  },
                  {
                    path: "/reports/riskRewardRatio",
                    element: <RiskRewardRatioReport />
                  }
                ],
              },
              {
                path: "/addtrade",
                element: <AddTrade />,
              },
              {
                path: "/settings",
                element: <Settings />,
                children: [
                  {
                    path: "manage/tags",
                    element: <ManageTagsPage />,
                  },
                  {
                    path: "profile",
                    element: <ProfilePage />,
                  },
                  {
                    path: "accounts",
                    element: <AccountsPage />,
                  },
                  {
                    path: "security",
                    element: <SecurityPage />,
                  },
                  {
                    path: "economic-calendar",
                    element: <EconomicCalendar />,
                  },
                  {
                    path: "subscription",
                    element:
                      <SubscriptionPage />
                  },
                  {
                    path: 'daily-goals',
                    element: <DailyGoalsPage />
                  },
                  {
                    path: "commission",
                    element: <CommissionsPage />
                  }
                ],
              },
            ],
          },
        ]
      }
    ]
  },
  {
    path: "/activate/:otp/:email",
    element: <AccountActivationPage />,
  },
  {
    path: "/share/trade/:subAccountId/:tradeId",
    element: <ShareTradePage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/two-factor/:email/:purpose",
    element: <TwoFactorAuthenticationPage />,
  },
  {
    path: "/reset-password/:email/:code",
    element: <ResetPasswordPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
