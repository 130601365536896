import "../styles/common.scss";
import { MdClose, MdExpandMore } from "react-icons/md";
import { useEffect, useState } from "react";
import useOutsideClick from "../hooks/useOutsideClick";
import {useTheme} from "../Providers/ThemeContext";
import {useTranslation} from "react-i18next";

function Select({
    options,
    selectedIndex,
    onChange,
    allowMultiple,
    itemName = "value",
    placeholder,
    onOptionRender,
    onSelectedOptionRender,
    selectedOptionBackground,
    isSearchable,
    hasAddButton,
    onAddButtonClick,
    onSearch,
    themeClass,
    disabledValues,
    convertToUppercase,
    coverUpValues,
}) {
  let [dropdownOpen, setDropDownOpen] = useState(false);
  let [value, setValue] = useState();
  const [optionToShow, setOptionsToShow] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(true);
  const {t} = useTranslation();
  const theme = useTheme();

  const convertText = (text) => {
    if(coverUpValues) {
      return coverUpValues[text] || text;
    }
    else if(convertToUppercase) {
      return text[0].toUpperCase() + text.slice(1);
    }
    else {
      return text;
    }
  }

  useEffect(() => {
    setDropDownOpen(false);
    setIsComponentVisible(true);
  }, [isComponentVisible, setIsComponentVisible]);

  useEffect(() => {
    setValue(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    setOptionsToShow(options)
  }, [options])

  const handleOptionClick = (index) => {
    setSearchValue('');
    if (allowMultiple) {
      let newValue = [...value, index];

      if (onChange) {
        onChange(newValue);
      }

      setValue(newValue);
    } else {
      if (onChange) {
        onChange(index);
      }
      setValue(index);
      setDropDownOpen(false);
    }
  };

  let unSelectIndex = (index) => {
    if (allowMultiple && value) {
      let newValue = value.filter((val) => val !== index);

      if (onChange) {
        onChange(newValue);
      }

      setValue(newValue);
    }
  };

  const onInputSelect = (e) => {
    e.stopPropagation();
  }

  const onSearchValueChange = e => {
    setSearchValue(e.currentTarget.value);
  }

  const addButtonClick = e => {
    if(onAddButtonClick) {
      onAddButtonClick(searchValue);
    }
  }

  useEffect(() => {
    if(searchValue) {
      const searchText = searchValue.toLowerCase();
      if(onSearch) {
        setOptionsToShow(onSearch(options, searchText))
      } else {
        setOptionsToShow(options.filter(option => option.toLowerCase().includes(searchText)))
      }
    } else {
      setOptionsToShow(options);
    }
  }, [searchValue, options]);

  const onDropdownToggle = () => {
    setSearchValue('');
    setDropDownOpen(!dropdownOpen);
  }

  return (
    <div
      ref={ref}
      className={`select ${themeClass ? themeClass : theme.getThemeClass()}`}
      onClick={onDropdownToggle}
    >
      <div className={`title ${dropdownOpen ? "active" : ""}`}>
        <div className="multiple-value">
          {
            allowMultiple ? 
              (
                <>
                  {
                    value && value.length > 0 ? (
                      value.map((index) => {
                        return (
                          <div className={`value ${selectedOptionBackground ? selectedOptionBackground : ''}`}>
                            {
                              onSelectedOptionRender ? 
                              onSelectedOptionRender(options[index]) : 
                              options[index]
                            }
                            <div className="close-icon">
                              <MdClose
                                onClick={(e) => {
                                  e.stopPropagation();
                                  unSelectIndex(index);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className={`hideable ${isSearchable && dropdownOpen ? 'hide' : ''}`}> {placeholder ? placeholder : `Select a ${itemName}`}</div>
                    )
                  }
                  <div className={`search-input multi-search ${isSearchable && dropdownOpen ? 'show' : 'hide'}`}>
                    <input type="text" 
                      value={searchValue} 
                      onChange={e => onSearchValueChange(e)} 
                      onClick={e => onInputSelect(e)} />
                  </div>
                </>
              ) : 
              <>
              {
                options[value] ? 
                (
                  <div className={`hideable ${isSearchable && dropdownOpen ? 'hide' : ''}`}>
                    {
                      onSelectedOptionRender ? 
                      convertText(onSelectedOptionRender(options[value])) :
                      convertText(options[value])
                    }
                  </div>
                ) : 
                (
                  <div className={`hideable ${isSearchable && dropdownOpen ? 'hide' : ''}`}>{placeholder ? placeholder : `Select a ${itemName}`}</div>
                )
              }
                <div className={`search-input ${isSearchable && dropdownOpen ? 'show' : 'hide'}`}>
                  <input type="text" 
                    value={searchValue} 
                    placeholder={options[value]}
                    onChange={e => onSearchValueChange(e)} 
                    onClick={e => onInputSelect(e)} />
                </div>
              </>
          }
        </div>
        <MdExpandMore />
      </div>
      <div className={`options ${dropdownOpen ? "active" : ""}`}>
        {allowMultiple
          ? optionToShow.map((option, index) => {
              if (value?.includes(options.indexOf(option))) return <div></div>;
              else
                return (
                  <div
                    id={`option-${index}`}
                    key={index}
                    className="option"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOptionClick(options.indexOf(option));
                    }}
                  >
                    {
                      onOptionRender ? 
                        onOptionRender(option) : 
                        option
                    }
                  </div>
                );
            })
          : optionToShow.map((option, index) => {
              const isDisabled = disabledValues && disabledValues.includes(option);
              const onOptionClick = () => {
                if(!isDisabled) {
                  handleOptionClick(options.indexOf(option))
                }
              }

              return (
                <div id={`option-${index}`} key={index} className={`option ${isDisabled ? 'disabled' : ''}`} onClick={onOptionClick}>
                  {
                    onOptionRender ? 
                      onOptionRender(option) : 
                      convertText(option)
                  }
                </div>
              );
            })}
          {
            hasAddButton ? 
              <div className="primary-btn" onClick={addButtonClick}>
                {t("select.add")} {searchValue ? `"${searchValue}"` : ''}
              </div> : 
              <></>
          }
      </div>
    </div>
  );
}

export default Select;
