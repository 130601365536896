import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import {useTheme} from "../../Providers/ThemeContext";

function CriteriaGroupAddModal({criteriaToEdit, mode, handleCloseModal, handleSaveModal}) {

    const [name, setName] = useState('');
    const {t} = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        setName(criteriaToEdit.title)
    }, [criteriaToEdit]);

    const handleSave = () => {
        if(handleSaveModal) {
            handleSaveModal(name);
        }
    }

    return (
        <div>
            <div className="modal-overlay">
                <div className={`modal modal-md ${theme.getThemeClass()}`}>
                    <div className="modal-header">
                        <div className="title">{t("create_criteria_heading")}</div>
                        <div className="close-btn" onClick={handleCloseModal}>
                            <MdClose />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>{t("create_criteria_name")}</label>
                            <input type="text" name="criteriaName" 
                                placeholder={t("create_criteria_name_placeholder")} 
                                value={name}
                                onChange={e => setName(e.currentTarget.value)}
                                />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="danger-btn" onClick={handleCloseModal}>
                            {t("cancel")}
                        </button>
                        <button className={`primary-btn`} onClick={handleSave}>
                            {t("save")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CriteriaGroupAddModal;