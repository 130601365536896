import Modal from "./Modal";
import {useTranslation} from "react-i18next";

function DeleteConfirmationModalComponent({
    isShown,
    onClose,
    message
}) {
    const {t} = useTranslation();

    return (
        <Modal isShown={isShown} onClose={onClose} title={t("modal.delete_confirmation.title")}>
            <Modal.Body>
                <div className="note">
                    {message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="button-container">
                    <div className="danger-btn" onClick={() => onClose(true)}>
                        {t("buttons.confirmation")}
                    </div>
                    <div className="secondary-btn" onClick={() => onClose(false)}>
                        {t("buttons.cancel")}
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmationModalComponent;