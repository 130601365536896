import { MdCached, MdClose } from "react-icons/md";
import "../../styles/tags-modal.scss";
import Select from "../selectcontrol";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";

const AccountEditModal = ({
  isOpen,
  handleSave,
  handleCloseModal,
  mode,
  name,
  broker,
  profitCalculationMethod,
  status,
  balance,
  setName,
  setBroker,
  setStatus,
  setBalance,
  setProfitCalculationMethod,
  error,
  isLoading,
}) => {
  let brokers = ["Rithmic", "CQG", "Atas", "Ninja Trader"];
  let { t } = useTranslation();
  let profitMethods = ["FIFO", "Weighted Average"];
  const profitMethodsCoverUps = {
    'FIFO': t("coverUps.profitCalculation.fifo"),
    'Weighted Average': t("coverUps.profitCalculation.weightedAverage")
  }
  let statuses = ["active", "inactive"];
  const statusCoverUps = {
    'active': t("coverUps.status.active"),
    'inactive': t("coverUps.status.inActive")
  }
  let [canCreate, setCanCreate] = useState(false);
  const theme = useTheme()

  useEffect(() => {
    if (mode === "Add") {
      if (name && broker && profitCalculationMethod && balance) {
        setCanCreate(true);
      } else {
        setCanCreate(false);
      }
    } else {
      if (name && broker && profitCalculationMethod && status && balance) {
        setCanCreate(true);
      } else {
        setCanCreate(false);
      }
    }
  }, [name, broker, profitCalculationMethod, status, balance]);

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className={`modal modal-sm ${theme.getThemeClass()}`}>
            <div className="modal-header">
              <div className="title">
                {mode === "add"
                  ? t("account_modal_add_heading")
                  : t("account_modal_edit_heading")}
              </div>
              <div className="close-btn" onClick={handleCloseModal}>
                <MdClose />
              </div>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="name">{t("account_modal_name_label")}:</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={t("account_modal_name_placeholder")}
                    className="w-full"
                />
              </div>
              <div className="form-group">
                <label htmlFor="type">{t("account_modal_name_broker")}:</label>
                <Select
                    placeholder={t("account_modal_broker_placeholder")}
                    options={brokers}
                    selectedIndex={brokers.indexOf(broker)}
                    onChange={(index) => setBroker(brokers[index])}
                    itemName="Broker"
                />
              </div>
              <div className="form-group">
                <label htmlFor="type">{t("account_modal_name_profit")}:</label>
                <Select
                    coverUpValues={profitMethodsCoverUps}
                    placeholder={t("account_modal_profit_placeholder")}
                    options={profitMethods}
                    selectedIndex={profitMethods.indexOf(profitCalculationMethod)}
                    onChange={(index) =>
                        setProfitCalculationMethod(profitMethods[index])
                    }
                    itemName="Profit Calculation Method"
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">{t("account_modal_balance_name")}:</label>
                <input
                    type="number"
                    id="balance"
                    value={balance}
                    onChange={(e) => setBalance(e.target.value)}
                    placeholder={t("account_modal_balance_name")}
                    className="w-full"
                />
              </div>
              {mode !== "Add" ? (
                  <div className="form-group">
                    <label htmlFor="type">
                      {t("account_modal_name_status")}:
                    </label>
                    <Select
                        coverUpValues={statusCoverUps}
                        placeholder={t("account_modal_stats_placeholder")}
                        options={statuses}
                        selectedIndex={statuses.indexOf(status)}
                        onChange={(index) => setStatus(statuses[index])}
                        itemName="Status"
                    />
                  </div>
              ) : (
                  <></>
              )}
              {error ? <div className="error">{error}</div> : <></>}
            </div>
            <div className="modal-footer">
              <button className="danger-btn" onClick={handleCloseModal}>
                {t("account_modal_cancel_btn")}
              </button>
              {isLoading ? (
                  <Loader height={20} barWidth={3}/>
              ) : (
                  <button
                      className={`primary-btn ${canCreate ? "" : "disabled"}`}
                      onClick={() => {
                        if (canCreate) {
                      handleSave();
                    }
                  }}
                >
                  {mode === "Add"
                    ? t("account_modal_create_btn")
                    : t("account_modal_save_btn")}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountEditModal;
