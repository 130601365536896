import React, { useEffect, useMemo, useState } from "react";
import { MdSearch, MdSell } from "react-icons/md";
import TagsModal from "../../components/Modal/Tags_Modal";
import Select from "../../components/selectcontrol";
import Checkbox from "../../components/checkbox";
import { sendDelete, sendGet, sendPost } from "../../../common/request";
import links from "../../../common/links";
import Loader from "../../components/Loader";
import NoResultImage from "../../images/no-result.png";
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";

const ManageTagsPage = () => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsToDisplay, setTagsToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterCategory, setFilterCategory] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  let { t } = useTranslation();
  let types = useMemo(() => ["All", "Setup", t("tag_type_mistake"), t("tag_type_custom")], [t]);
  const theme = useTheme();

  let loadTags = async () => {
    setIsLoading(true);
    let response = await sendGet(links.Tags, true);
    console.log(response);
    if (!response.error) {
      setTags(response.tags);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadTags();
  }, []);

  useEffect(() => {
    let tagsSelected = [];
    let filteredTags = tags.filter((tag) =>
      tag.name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (filterCategory !== 0) {
      filteredTags = filteredTags.filter(
        (tag) => tag?.type === types[filterCategory]
      );
    }

    filteredTags.forEach((tag) => {
      tagsSelected.push(false);
    });
    setSelectedTags([...tagsSelected]);
    setAllSelected(false);
    setTagsToDisplay(filteredTags);
  }, [tags, searchText, filterCategory, types]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setName("");
    setType("");
    setDescription("");
    setError("");
    setIsOpen(false);
  };

  const handleSave = async () => {
    setIsModalLoading(true);
    let response = await sendPost(
      links.Tags,
      {
        name: name,
        type: type,
        description: description,
      },
      true
    );
    if (response.error) {
      setError(response.error);
    } else {
      await loadTags();
      handleCloseModal();
    }
    setIsModalLoading(false);
  };

  let deleteTags = async () => {
    setIsLoading(true);
    let tagIdsToDelete = [];
    selectedTags.forEach((tag, index) => {
      if (tag) {
        tagIdsToDelete.push(tagsToDisplay[index].id);
      }
    });
    let response = await sendDelete(
      links.Tags,
      { tagIds: tagIdsToDelete },
      true
    );
    if (!response.error) {
      await loadTags();
    }
    setIsLoading(false);
  };

  let selectionChanged = (index, value) => {
    let tagSelection = [...selectedTags];
    tagSelection[index] = value;

    if (tagSelection.filter((t) => t).length === tagsToDisplay.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setSelectedTags(tagSelection);
  };

  let selectAll = (value) => {
    let tagSelection = [...selectedTags];
    tagSelection = tagSelection.map((tag) => value);
    setSelectedTags(tagSelection);
  };

  return (
    <>
      <div className={`data-page ${theme.getThemeClass()}`}>
        <div className="page-header">
          <div className="title">
            {t("tags_page_heading")}
            <div className="info">
              <p>{t("tags_page_note")}</p>
            </div>
          </div>
        </div>
        <div className="filter-bar">
          <div className="filter">
            <div className="title">{t("tags_page_filter_by_category")}</div>
            <div className="filter-input">
              <Select
                options={types}
                selectedIndex={filterCategory}
                onChange={(index) => setFilterCategory(index)}
              />
            </div>
          </div>
          <div className="search-input">
            <MdSearch />
            <input
              type="text"
              placeholder={t("tags_page_search_tags")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="primary-btn" onClick={handleOpenModal}>
            {t("tags_page_add_tag_text")}
          </div>
          {selectedTags.filter((tag) => tag).length > 0 ? (
            <div className="danger-btn" onClick={deleteTags}>
              {t("tags_page_delete_tag_text")} (
              {selectedTags.filter((tag) => tag).length})
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="table-tags-container">
          <div className="table table-tags">
            <div className="header">
              <div className="">
                <Checkbox
                  value={allSelected}
                  onChange={(value) => selectAll(value)}
                  className="hover:bg-gray-200 cursor-pointer"
                />
              </div>
              <div className="">{t("tags_page_label_name")}</div>
              <div className="">{t("tags_page_label_type")}</div>
              <div className="">{t("tags_page_label_count")}</div>
              <div className="">
                {t("tags_page_label_description")}
              </div>
            </div>
            {isLoading ? (
                <Loader />
              ) : tagsToDisplay.length > 0 ? (
                tagsToDisplay.map((tag, index) => {
                  let type = tag?.type.toUpperCase();
                  return (
                    <div key={index} className="row">
                      <div className="">
                        <Checkbox
                          value={selectedTags[index]}
                          onChange={(val) => selectionChanged(index, val)}
                          className="hover:bg-gray-200 cursor-pointer"
                        />
                      </div>
                      <div
                        className="
                      flex items-center 
                        whitespace-nowrap "
                      >
                        <div
                          className={`horizontal-container tag ${
                            type === "SETUP"
                              ? "tag-setup"
                              : type === "MISTAKE"
                              ? "tag-mistake"
                              : "tag-custom"
                          }  flex items-start 
                          `}
                        >
                          <div className={`${type === "SETUP"
                              ? "setup"
                              : type === "MISTAKE"
                              ? "mistake"
                              : "custom"}`}>
                            <MdSell />
                          </div>
                          {tag.name}
                        </div>
                      </div>
                      <div className="">{type}</div>
                      <div className="">{tag.count}</div>
                      <div className="">
                        {tag.description ? tag.description : t("settings.tags.noDescription")}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="empty-message text-center p-4">
                  <img src={NoResultImage} alt="No Results" />
                  <p dangerouslySetInnerHTML={{__html: t("settings.tags.empty")}}></p>
                </div>
              )}
          </div>
        </div>
      </div>
      <TagsModal
        handleCloseModal={handleCloseModal}
        handleSave={handleSave}
        isOpen={isOpen}
        name={name}
        setName={setName}
        setType={setType}
        type={type}
        description={description}
        setDescription={setDescription}
        isLoading={isModalLoading}
        error={error}
      />
    </>
  );
};

export default ManageTagsPage;
