import ScrollToTop from "../components/landing-page/ScrollToTop";
import HeroSection from "../components/brokers-page/HeroSection";
import Navbar from "../components/landing-page/Navbar";
import "../styles/brokers-page.scss";
import Lights from "../components/landing-page/Lights";
import BrokersSection from "../components/brokers-page/BrokersSection";
import Footer from "../components/landing-page/Footer";
import IntegrationSection from "../components/brokers-page/IntegrationSection";

const BrokersPage = () => {
    return (
        <div className='website-page brokers-page'>
            <Lights/>
            <ScrollToTop />
            <Navbar currentPage='brokers' />
            <HeroSection />
            <BrokersSection />
            <IntegrationSection />
            <Footer />
        </div>
    )
}

export default BrokersPage;