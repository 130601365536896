import { MdClose, MdSell } from "react-icons/md";
import "../../styles/tags-modal.scss";
import Select from "../selectcontrol";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";

const TagSelectionModal = ({
  isOpen,
  handleAddModal,
  handleCloseModal,
  trade,
  tags = [],
  error,
  isLoading,
}) => {
  let [setupTags, setSetupTags] = useState([]);
  let [mistakeTags, setMistakeTags] = useState([]);
  let [customTags, setCustomTags] = useState([]);
  let [setupIndices, setSelectedSetupIndices] = useState([]);
  let [mistakeIndices, setSelectedMistakeIndices] = useState([]);
  let [customIndices, setSelectedCustomIndices] = useState([]);
  let {t} = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setSetupTags(
      tags.filter((tag) => tag?.type === "Setup").map((tag) => tag?.name)
    );
    setMistakeTags(
      tags.filter((tag) => tag?.type === "Mistake").map((tag) => tag?.name)
    );
    setCustomTags(
      tags.filter((tag) => tag?.type === "Custom").map((tag) => tag?.name)
    );
  }, [tags]);

  useEffect(() => {
    if (trade && trade.tags && trade.tags.length > 0) {
      let selectedSetupTags = trade.tags.filter((tag) => tag?.type === "Setup");
      let selectedMistakeTags = trade.tags.filter(
        (tag) => tag?.type === "Mistake"
      );
      let selectedCustomTags = trade.tags.filter(
        (tag) => tag?.type === "Custom"
      );

      if (selectedSetupTags.length > 0 && setupTags.length > 0) {
        setSelectedSetupIndices(
          selectedSetupTags.map((tag) => setupTags.indexOf(tag?.name))
        );
      } else {
        setSelectedSetupIndices([]);
      }

      if (selectedMistakeTags.length > 0 && mistakeTags.length > 0) {
        let indices = selectedMistakeTags.map((tag) =>
          mistakeTags.indexOf(tag?.name)
        );
        setSelectedMistakeIndices(indices);
      } else {
        setSelectedMistakeIndices([]);
      }

      if (selectedCustomTags.length > 0 && customTags.length > 0) {
        setSelectedCustomIndices(
          selectedCustomTags.map((tag) => customTags.indexOf(tag?.name))
        );
      } else {
        setSelectedCustomIndices([]);
      }
    } else {
      setSelectedCustomIndices([]);
      setSelectedMistakeIndices([]);
      setSelectedSetupIndices([]);
    }
  }, [trade, customTags, mistakeTags, setupTags]);

  let onAddClick = () => {
    if (handleAddModal) {
      let allTags = [
        ...setupIndices.map((index) => setupTags[index]),
        ...mistakeIndices.map((index) => mistakeTags[index]),
        ...customIndices.map((index) => customTags[index]),
      ];
      let allTagIds = allTags.map(
        (tag) => tags.filter((t) => t.name === tag)[0].id
      );
      handleAddModal(trade.id, allTagIds);
    }
  };

  let tagChanges = (value, type) => {
    if (type === "Setup") {
      setSelectedSetupIndices(value);
    } else if (type === "Mistake") {
      setSelectedMistakeIndices(value);
    } else if (type === "Custom") {
      setSelectedCustomIndices(value);
    }
  };

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className={`modal modal-md ${theme.getThemeClass()}`}>
            <div className="modal-header">
              <div className="title">
                <p>{t('tag_selection_modal_heading')}</p>
                {trade ? (
                  <p className="description">
                    {trade.data.SecurityId} -{" "}
                    {moment(trade.data.OpenTime).format("ddd, MMM, DD yyyy")}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className="close-btn" onClick={handleCloseModal}>
                <MdClose />
              </div>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="type">{t('tag_selection_setup_label')}:</label>
                <Select
                  options={setupTags}
                  selectedIndex={setupIndices}
                  allowMultiple={true}
                  onChange={(indices) => tagChanges(indices, "Setup")}
                  itemName="Tag"
                  selectedOptionBackground={'tag-bg-setup'}
                  onOptionRender={option => {
                      return (
                          <div className="horizontal-container">
                              <div className="setup">
                                  <MdSell />
                              </div>
                              {option}
                          </div>
                      )
                  }}
                  onSelectedOptionRender={option => {
                      return (
                          <div className="horizontal-container">
                              <div className="setup">
                                  <MdSell />
                              </div>
                              {option}
                          </div>
                      )
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="type">{t('tag_selection_mistake_label')}:</label>
                <Select
                  options={mistakeTags}
                  selectedIndex={mistakeIndices}
                  allowMultiple={true}
                  onChange={(indices) => tagChanges(indices, "Mistake")}
                  itemName="Tag"
                  selectedOptionBackground={'tag-bg-mistake'}
                  onOptionRender={option => {
                      return (
                          <div className="horizontal-container">
                              <div className="mistake">
                                  <MdSell />
                              </div>
                              {option}
                          </div>
                      )
                  }}
                  onSelectedOptionRender={option => {
                      return (
                          <div className="horizontal-container">
                              <div className="mistake">
                                  <MdSell />
                              </div>
                              {option}
                          </div>
                      )
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="type">{t('tag_selection_custom_label')}:</label>
                <Select
                  options={customTags}
                  selectedIndex={customIndices}
                  allowMultiple={true}
                  onChange={(indices) => tagChanges(indices, "Custom")}
                  itemName="Tag"
                  selectedOptionBackground={'tag-bg-custom'}
                  onOptionRender={option => {
                      return (
                          <div className="horizontal-container">
                              <div className="custom">
                                  <MdSell />
                              </div>
                              {option}
                          </div>
                      )
                  }}
                  onSelectedOptionRender={option => {
                      return (
                          <div className="horizontal-container">
                              <div className="custom">
                                  <MdSell />
                              </div>
                              {option}
                          </div>
                      )
                  }}
                />
              </div>
              {error ? <div className="form-group error">{error}</div> : <></>}
            </div>
            <div className="modal-footer">
              <button className="danger-btn" onClick={handleCloseModal}>
              {t('tag_selection_modal_cancel_text')}
              </button>
              {isLoading ? (
                <Loader height={20} barWidth={3} />
              ) : (
                <button className={`primary-btn`} onClick={onAddClick}>
                  {t('tag_selection_modal_save_text')}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagSelectionModal;
