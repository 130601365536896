import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTheme} from "../../Providers/ThemeContext";
import {useTranslation} from "react-i18next";

const ViewPermission = ({permission, children, shallHide}) => {

    const {user} = useSelector((state) => state.user)
    const navigate = useNavigate();
    const theme = useTheme();
    const {t} =  useTranslation();

    return (
        <>
            {
                user && user.user && user.user.subscription && user.user.subscription.permissions.includes(permission) ?
                    children :
                    shallHide ?
                        <></> :
                        <div className={`view-container auto ${theme.getThemeClass()}`}>
                            <div className="view-bg">
                                {children}
                            </div>
                            <div className="view-card">
                                <div className="info">
                                    {t("no_permission_display.message.0")} <br />
                                    <span dangerouslySetInnerHTML={{__html: t("no_permission_display.message.1")}}></span>
                                </div>
                                <div className="buttons">
                                    <div className="primary-outline-btn" onClick={() => navigate('/pricing')}>
                                        {t("no_permission_display.buttons.upgrade")}
                                    </div>
                                </div>
                            </div>
                        </div>
            }
        </>
    )
}

export default ViewPermission;