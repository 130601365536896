import Logo from "../../images/logo/logo-dark-small.png";
import {FaFacebookF, FaInstagram, FaLinkedinIn} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FaYoutube} from "react-icons/fa6";

const Footer = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div className="footer-container front">
            <div className="footer-background">
                <div className="footer">
                    <div className="info-section">
                        <div className="logo">
                            <img src={Logo} alt="Logo"/>
                        </div>
                        <div className="description">
                            {t("landing_page.footer.description")}
                        </div>
                    </div>
                    <div className="links-section">
                        <div className="links-content">
                            <div className="heading">
                                {t("landing_page.footer.links.heading")}
                            </div>
                            <div className="links">
                                <div className="link"
                                     onClick={() => navigate('/')}>{t("landing_page.footer.links.items.0")}</div>
                                <div className="link"
                                     onClick={() => navigate('/pricing')}>{t("landing_page.footer.links.items.1")}</div>
                                <div className="link"
                                     onClick={() => navigate('/dashboard')}>{t("landing_page.footer.links.items.2")}</div>
                                <div className="link" onClick={() => navigate('/privacy-policy')}>{t("landing_page.footer.links.items.3")}</div>
                                <div className="link" onClick={() => navigate('/terms-conditions')}>{t("landing_page.footer.links.items.4")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-section">
                        <div className="contact-content">
                            <div className="heading">{t("landing_page.footer.contact.heading")}</div>
                            <div className="contact-item">
                                <div className="label">{t("landing_page.footer.contact.items.0.heading")}</div>
                                <a className="value" href='mailto:info@tradingprozess.com'>info@tradingprozess.com</a>
                            </div>
                            <div className="contact-item">
                                <div className="label">{t("landing_page.footer.contact.items.1.heading")}</div>
                                <div className="social-icons">
                                    <a className="social-icon" href={'https://www.facebook.com/profile.php?id=61571096824521'} target={"_blank"}>
                                        <FaFacebookF/>
                                    </a>
                                    <a className="social-icon" href={'https://www.youtube.com/channel/UCkuXEqY8gUvGrKWeCPV7jPw'} target={"_blank"}>
                                        <FaYoutube />
                                    </a>
                                    <a className="social-icon" href={'https://www.instagram.com/tradingprozess/'} target={"_blank"}>
                                        <FaInstagram/>
                                    </a>
                                    <a className="social-icon" href={'https://www.linkedin.com/company/tradingprozess/about/?viewAsMember=true'} target={"_blank"}>
                                        <FaLinkedinIn/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;