import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import {useTheme} from "../../Providers/ThemeContext";

function DeleteConfirmationModal({handleCloseModal, handleConfirmModal, message}) {
    const {t} = useTranslation();
    
    const closeModal = () => {
        if(handleCloseModal) {
            handleCloseModal();
        }
    }

    const confirmModal = () => {
        if(handleConfirmModal) {
            handleConfirmModal();
        }
    }

    const theme = useTheme();

    return (
        <div>
            <div className="modal-overlay">
                <div className={`modal ${theme.getThemeClass()}`}>
                    <div className="modal-header">
                        <div className="title">{t("delete_confirmation_modal_heading")}</div>
                        <div className="close-btn" onClick={closeModal}>
                            <MdClose />
                        </div>
                    </div>
                    <div className="modal-body">
                        {message}
                    </div>
                    <div className="modal-footer">
                        <button className="primary-btn" onClick={closeModal}>
                            {t("cancel")}
                        </button>
                        <button className={`danger-btn`} onClick={confirmModal}>
                            {t("confirm_action")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfirmationModal;