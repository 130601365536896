import Select from "../selectcontrol";
import Loader from "../Loader";
import {MdChevronRight, MdDelete} from "react-icons/md";
import Brokers from "../../enums/brokers";
import ATASLogo from "../../images/add-trade/atas-logo.png";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useSubAccounts from "../../hooks/useSubAccounts";
import useSubAccount from "../../hooks/useSubAccount";
import {sendDelete, sendGet, sendPost} from "../../../common/request";
import Urls from "../../../common/links";
import {toast} from "react-toastify";
import CodeShowModal from "../Modal/CodeShowModal";
import {getArray} from "../../../common/methods";

const AutoSync = () => {

    const {t} = useTranslation();
    const subAccounts = useSubAccounts();
    const subAccountNames = subAccounts ? subAccounts.map(acc => acc.name) : [];
    const [selectedSubAccount, setSelectedSubAccount] = useState();
    const autoSyncBrokers = [
        Brokers.Atas,
        Brokers.NinjaTrader
    ];
    const [selectedBroker, setSelectedBroker] = useState(Brokers.Atas);
    const [isLoading, setIsLoading] = useState(false);
    const [accountId, setAccountId] = useState('');
    const [codeModal, setCodeModal] = useState({shown: false, code: ''})
    const [accountReferences, setAccountReferences] = useState([]);
    const subAccount = useSubAccount();

    useEffect(() => {
        if(subAccount) {
            setSelectedSubAccount(subAccount)
            if(autoSyncBrokers.includes(subAccount.broker)) {
                setSelectedBroker(subAccount.broker)
            }
            else {
                setSelectedBroker(autoSyncBrokers[0])
            }
        }
    }, [subAccount])

    useEffect(() => {
        if(selectedSubAccount) {
            loadAccountReferences();
        }
    }, [selectedSubAccount, selectedBroker]);

    const loadAccountReferences = async () => {
        setIsLoading(true);
        const response = await sendGet(Urls.GetAllSubAccountReferences(selectedSubAccount.id, selectedBroker), true);
        if(response.error) {
            console.log(response.error)
        }
        else {
            setAccountReferences(response.references)
        }
        setIsLoading(false);
    }

    const onAutoSyncClick = async () => {
        setIsLoading(true);
        const response = await sendPost(Urls.CreateSubAccountReference, {
            subAccountId: selectedSubAccount.id,
            accountId: accountId,
            broker: selectedBroker
        }, true, 'application/json', true);

        if(response.error) {
            toast.error(response.error);
        }
        else {
            setAccountReferences([...accountReferences, response.accountReference]);
            if(response.otp) {
                setCodeModal({
                    shown: true,
                    code: response.otp
                });
            }
            setAccountId('');
        }
        setIsLoading(false);
    }

    const onDeleteAccountReference = async (id) => {
        setIsLoading(true);
        const response = await sendDelete(Urls.DeleteSubAccountReference(id), {}, true);
        if(response.error) {
            toast.error(response.error);
        }
        else {
            toast.success(response.message);
            setAccountReferences(accountReferences.filter(acc => acc.id !== id));
        }
        setIsLoading(false);
    }

    return (
        <>
            <CodeShowModal isOpen={codeModal.shown} code={codeModal.code} handleCloseModal={() => setCodeModal({shown: false, code: ''})}/>
            <div
                className={`auto-sync tab-content-item active`}>
                <div className="controls">
                    <div className="input-group">
                        <label>{t("add_trade_page.autoSync.fields.selectAccount")}</label>
                        <Select
                            onChange={index => setSelectedSubAccount(subAccounts[index])}
                            options={subAccountNames}
                            selectedIndex={subAccountNames.indexOf(selectedSubAccount?.name)}/>
                    </div>
                    <div className="input-group">
                        <label>{t("add_trade_page.autoSync.fields.selectBroker")}</label>
                        <Select
                            options={autoSyncBrokers}
                            selectedIndex={autoSyncBrokers.indexOf(selectedBroker)}
                            onChange={(index) => {
                                setSelectedBroker(autoSyncBrokers[index])
                            }}
                        />
                    </div>
                    <div className="input-group">
                        <label>{t("add_trade_page.autoSync.fields.accountId")}</label>
                        <input type="text" placeholder="Account Id" className="input-group-item" value={accountId}
                               onChange={e => setAccountId(e.currentTarget.value)}/>
                    </div>
                    {
                        isLoading ?
                            <Loader height={25} barWidth={3}/> :
                            <div className="btn btn-primary w-100" onClick={onAutoSyncClick}>
                                {t("add_trade_page.autoSync.autoSyncBtn")}
                            </div>
                    }
                    <div className="table">
                        <div className="heading">
                            {t("add_trade_page.autoSync.table.title")}
                        </div>
                        <div className="header">
                            <div className="cell">{t("add_trade_page.autoSync.table.headers.0")}</div>
                            <div className="cell">{t("add_trade_page.autoSync.table.headers.1")}</div>
                            <div className="cell"></div>
                        </div>
                        {
                            accountReferences && accountReferences.length > 0 ?
                                accountReferences.map((ref, index) => {
                                    return (
                                        <div className="row" key={index}>
                                            <div className="cell">{ref.accountId}</div>
                                            <div className="cell">{ref.status}</div>
                                            <div className="cell">
                                                <div className="actions">
                                                    <div className="action delete"
                                                         onClick={() => onDeleteAccountReference(ref.id)}>
                                                        <MdDelete/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div className="note">{t("add_trade_page.autoSync.table.empty")}</div>
                        }
                    </div>
                </div>
                <div className="vr"></div>
                <div>
                    {selectedBroker === Brokers.Atas ? (
                        <div className="guide">
                            <div className="broker-logo">
                                <img src={ATASLogo} alt="ATAS"/>
                            </div>
                            <div className="title">
                                {t("add_trade_page.autoSync.guides.atas.title")}
                            </div>
                            <video src={'/videos/auto-sync-atas.mp4'} controls />
                            <div className="note">{t("add_trade_page.autoSync.guides.atas.note")}</div>
                            <div className="steps">
                                {
                                    getArray(t("add_trade_page.autoSync.guides.atas.steps.length")).map(number => {
                                        return (
                                            <div className="step">
                                                <MdChevronRight/>
                                                <div dangerouslySetInnerHTML={{__html: t(`add_trade_page.autoSync.guides.atas.steps.${number}`)}}></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : selectedBroker === Brokers.NinjaTrader ? (
                        <div className="guide">
                            <div className="name">{selectedBroker}</div>
                            <div className="note">Help Me Import</div>
                            <div className="title">
                                How to import trades from Atas Broker{selectedBroker}
                            </div>
                            <div className="note">
                                To Import your trades from Ninja Traders, follow these steps!
                            </div>
                            <div className="steps">
                                <div className="step">
                                    <MdChevronRight/>
                                    <div>
                                        In NinjaTrader's Control Center, go to the Account Performance Tab
                                    </div>
                                </div>
                                <div className="step">
                                    <MdChevronRight/>
                                    <div>
                                        Generate a performance history for the account and
                                        Instrument you want.
                                    </div>
                                </div>
                                <div className="step">
                                    <MdChevronRight/>
                                    <div>
                                        When Ninja Traders has finished, and you see data in the
                                        Summary, switch to the Executions tab. You see a list of
                                        the trade executions you want to import into Tradingprozess
                                    </div>
                                </div>
                                <div className="step">
                                    <div className="font-bold mt-4 mb-2">Note</div>
                                </div>
                                <div className="step">
                                    <MdChevronRight/>
                                    <div>
                                        For Ninja Trade 8-instead of the Account performance tab,
                                        instead go to the Trader Summary then the Execution tab.{" "}
                                        <br/>
                                        Please Export your file into a CSV file format instead of
                                        EXCEL.
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="guide">
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default AutoSync;