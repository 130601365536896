import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {sendPost} from "../../common/request";
import Loader from "../components/Loader";
import links from "../../common/links";
import {toast} from "react-toastify";

const AccountActivationPage = () => {

    const {otp, email} = useParams();
    const [loading, setLoading] = useState(true);
    const [activated, setActivated] = useState(false);

    const activateAccount = async () => {
        setLoading(true);

        try {
            let response = await sendPost(links.VerifyCode, {email: email, code: otp, purpose: 'register'}, false);
            if(response.error) {
                toast.error(response.error);
            } else {
                setActivated(true);
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        activateAccount();
    }, [otp, email]);

    return (
        <div className='confirmation-page'>
            {
                loading ?
                    <Loader fullScreen={true} text={'Hang Tight! We are activating your account. Please do not close this window'} /> :
                    activated ?
                        <div>Your account has been activated successfully</div> : <></>
            }
        </div>
    )
}

export default AccountActivationPage