import {Line} from "react-chartjs-2";
import {useTheme} from "../../Providers/ThemeContext";
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {sendGet} from "../../../common/request";
import Urls from "../../../common/links";
import useSubAccount from "../../hooks/useSubAccount";
import {toast} from "react-toastify";
import moment from "moment";
import currencyFormat from "../../../common/format";
import {useTranslation} from "react-i18next";
import permissions from "../../data/Permissions.json";
import ViewPermission from "../permissions/ViewPermission";

const WinLossDistributionReport = () => {

    const {t} = useTranslation();
    const theme = useTheme();
    const [winLineData, setWinLineData] = useState(null);
    const [lossLineData, setLossLineData] = useState(null);
    const {selectedDate: startDate, endDate} = useSelector(state => state.date)
    const [data, setData] = useState({win: {}, loss: {}});
    const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.3,
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false,
                    tickColor: gridColor,
                },
            },
            y: {
                border: {
                    color: gridColor
                },
                grid: {
                    color: gridColor,
                    tickColor: gridColor,
                },
                ticks: {
                    callback: function(value, index, ticks) {
                        return currencyFormat.format(value);
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };
    const green = "#29D6A8";
    const red = "#ec787d";
    const subAccount = useSubAccount();

    const fetchData = useCallback(async () => {
        if(subAccount) {
            const response = await sendGet(Urls.SearchTrades(subAccount.id, {startDate, endDate, status: 'Closed', sorting: 'ASC', type: 'closeTime'}), true);
            console.log(response)
            if(response.error) {
                toast.error(response.error);
            }
            else if(response.trades && response.trades.length > 0) {
                const newData = {
                    win: {
                        count: 0,
                        pnl: 0,
                        volume: 0,
                        days: [],
                        tradeCount: 0,
                        commission: 0,
                        consecutive: 0,
                        netCumulativeData: [],
                        netCumulativeLabels: [],
                    },
                    loss: {
                        count: 0,
                        pnl: 0,
                        volume: 0,
                        days: [],
                        tradeCount: 0,
                        commission: 0,
                        consecutive: 0,
                        netCumulativeData: [],
                        netCumulativeLabels: [],
                    }
                }
                let lastMode = ''
                let consecutiveCount = 0;
                const trades = response.trades;
                for(const trade of trades) {
                    let mode = '';
                    if(trade.data.PnL > 0) {
                        mode = 'win';
                    }
                    else if (trade.data.PnL < 0) {
                        mode = 'loss';
                    }

                    if(mode === '' || !newData[mode])
                        continue;

                    newData[mode].count += 1;
                    newData[mode].pnl += trade.data.PnL;
                    newData[mode].volume += Math.abs(trade.data.CloseVolume);
                    newData[mode].tradeCount += 1;
                    newData[mode].commission += trade.data.Commission;
                    const closeDate = moment(trade.data.CloseTime).format('DD-MM-YYYY');
                    if(!newData[mode].days.includes(closeDate)) {
                        newData[mode].days.push(closeDate);
                    }

                    // Calculating the Net Cumulative Data
                    if(newData[mode].netCumulativeData.length === 0) {
                        newData[mode].netCumulativeData.push(trade.data.PnL);
                        newData[mode].netCumulativeLabels.push(closeDate);
                    }
                    else {
                        // Finding whether the date exists or not
                        const index = newData[mode].netCumulativeLabels.findIndex(p => p === closeDate);

                        let prevPnL = 0;

                        if(newData[mode].netCumulativeData.length > 0) {
                            prevPnL = newData[mode].netCumulativeData[newData[mode].netCumulativeData.length - 1];
                        }

                        if(index >= 0) {
                            newData[mode].netCumulativeData[index] += trade.data.PnL;
                        }
                        else {
                            newData[mode].netCumulativeData.push(trade.data.PnL + prevPnL);
                            newData[mode].netCumulativeLabels.push(closeDate);
                        }
                    }

                    // Finding Consecutive Trades
                    if(lastMode === '') {
                        newData[mode].consecutive += 1;
                        consecutiveCount += 1;
                    }
                    else if (lastMode === mode) {
                        consecutiveCount += 1;
                        if(consecutiveCount > newData[mode].consecutive) {
                            newData[mode].consecutive = consecutiveCount;
                        }
                    }
                    else {
                        consecutiveCount = 1;
                    }
                    lastMode = mode;
                }

                setWinLineData({
                    labels: newData.win.netCumulativeLabels,
                    datasets: [
                        {
                            label: "Net Cumulative P&L",
                            data: newData.win.netCumulativeData,
                            borderColor: green,
                            pointBackgroundColor: green,
                        },
                    ],
                })
                setLossLineData({
                    labels: newData.loss.netCumulativeLabels,
                    datasets: [
                        {
                            label: "Net Cumulative P&L",
                            data: newData.loss.netCumulativeData,
                            borderColor: red,
                            pointBackgroundColor: red,
                        },
                    ],
                })

                setData(newData);
            }
            else {
                setWinLineData({
                    labels: [],
                    datasets: [
                        {
                            label: "Net Cumulative P&L",
                            data: [],
                            borderColor: green,
                            pointBackgroundColor: green,
                        },
                    ],
                })
                setLossLineData({
                    labels: [],
                    datasets: [
                        {
                            label: "Net Cumulative P&L",
                            data: [],
                            borderColor: red,
                            pointBackgroundColor: red,
                        },
                    ],
                })
                setData({
                    win: {
                        count: 0,
                        pnl: 0,
                        volume: 0,
                        days: [],
                        tradeCount: 0,
                        commission: 0,
                        consecutive: 0,
                        netCumulativeData: [],
                        netCumulativeLabels: [],
                    },
                    loss: {
                        count: 0,
                        pnl: 0,
                        volume: 0,
                        days: [],
                        tradeCount: 0,
                        commission: 0,
                        consecutive: 0,
                        netCumulativeData: [],
                        netCumulativeLabels: [],
                    }
                });
            }
        }
    }, [subAccount, startDate, endDate])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const formatDate = (date) => {
        return moment(date).format("MMM DD, YYYY")
    }

    const findAverage = (value, count) => {
        const finalValue = value || 0;
        return count === 0 ? finalValue : finalValue / count;
    }

    return (
        <ViewPermission permission={permissions.PAGE.REPORTS.WIN_LOSS}>
            <div className={`report-data-page win-loss-distribution-report ${theme.getThemeClass()}`}>
                <div className="content">
                    <div className="data">
                        <div className="header">
                            {t("reports.win_loss_distribution_report.wins.heading")} ({data.win.count} {t("reports.win_loss_distribution_report.matched")})
                        </div>
                        <div className="stats">
                            <div className="header">
                                <div
                                    className="heading">{t("reports.win_loss_distribution_report.statistics")} ({t("reports.win_loss_distribution_report.wins.heading")})
                                </div>
                                <div className="tag-line">({formatDate(startDate)} - {formatDate(endDate)})</div>
                            </div>
                            <div className="horizontal-line"></div>
                            <div className="stat-rows">
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.0")}</div>
                                    <div className="row-item">{currencyFormat.format(data.win.pnl)}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.1")}</div>
                                    <div
                                        className="row-item">{findAverage(data.win.volume, data.win.days?.length || 0).toFixed(2)}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.2")}</div>
                                    <div
                                        className="row-item">{currencyFormat.format(findAverage(data.win.pnl, data.win.count))}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.3")}</div>
                                    <div className="row-item">N/A</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.4")}</div>
                                    <div className="row-item">{data.win.count}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.5")}</div>
                                    <div className="row-item">0</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.6")}</div>
                                    <div className="row-item">{currencyFormat.format(data.win.commission)}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.7")}</div>
                                    <div className="row-item">{data.win.consecutive}</div>
                                </div>
                            </div>
                        </div>
                        <div className="chart-container">
                            <div className="heading">
                                {t("reports.win_loss_distribution_report.chart.heading")} ({t("reports.win_loss_distribution_report.wins.heading")})
                                ({t("reports.all_dates")})
                            </div>
                            <div className="chart">
                                {
                                    winLineData && (
                                        <Line
                                            data={winLineData}
                                            options={lineOptions}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="data">
                        <div className="header">
                            {t("reports.win_loss_distribution_report.losses.heading")} ({data.loss.count} {t("reports.win_loss_distribution_report.matched")})
                        </div>
                        <div className="stats">
                            <div className="header">
                                <div
                                    className="heading">{t("reports.win_loss_distribution_report.statistics")} ({t("reports.win_loss_distribution_report.losses.heading")})
                                </div>
                                <div className="tag-line">({formatDate(startDate)} - {formatDate(endDate)})</div>
                            </div>
                            <div className="horizontal-line"></div>
                            <div className="stat-rows">
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.0")}</div>
                                    <div className="row-item">{currencyFormat.format(data.loss.pnl)}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.1")}</div>
                                    <div
                                        className="row-item">{findAverage(data.loss.volume, data.loss.days?.length || 0).toFixed(2)}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.2")}</div>
                                    <div
                                        className="row-item">N/A
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.3")}</div>
                                    <div
                                        className="row-item">{currencyFormat.format(findAverage(data.loss.pnl, data.loss.count || 0))}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.4")}</div>
                                    <div className="row-item">0</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.5")}</div>
                                    <div className="row-item">{data.loss.count}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.6")}</div>
                                    <div className="row-item">{currencyFormat.format(data.loss.commission)}</div>
                                </div>
                                <div className="row">
                                    <div className="row-item">{t("reports.win_loss_distribution_report.items.8")}</div>
                                    <div className="row-item">{data.loss.consecutive}</div>
                                </div>
                            </div>
                        </div>
                        <div className="chart-container">
                            <div className="heading">
                                {t("reports.win_loss_distribution_report.chart.heading")} ({t("reports.win_loss_distribution_report.losses.heading")})
                                ({t("reports.all_dates")})
                            </div>
                            <div className="chart">
                                {
                                    winLineData && (
                                        <Line
                                            data={lossLineData}
                                            options={lineOptions}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ViewPermission>
    )
}

export default WinLossDistributionReport