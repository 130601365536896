import "../../App.scss";
import "../styles/addpage.scss";
import "../styles/common.scss";
import {useDispatch} from "react-redux";
import createHeader from "../../redux/header/createHeader";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "../Providers/ThemeContext";
import permissions from "../data/Permissions.json";
import ViewPermission from "../components/permissions/ViewPermission";
import AddTradePageTabs from "../enums/AddTradePageTabs";
import ManualImport from "../components/add-trade-page/ManualImport";
import AutoSync from "../components/add-trade-page/AutoSync";
import ManualInsert from "../components/add-trade-page/ManualInsert";
import {useLocation} from "react-router-dom";

function AddTrade() {

  const [currentTab, setCurrentTab] = useState(AddTradePageTabs.Manual);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const state = useLocation().state;
  const theme = useTheme();
  dispatch(createHeader(t("add_trade_btn"), true, true));

  useEffect(() => {
    if(state && state.tab) {
      setCurrentTab(state.tab)
    }
  }, [state]);

  return (
      <>
        <ViewPermission permission={permissions.PAGE.TRADE_IMPORT}>
          <div className="page-body">
            <div className={`add-page ${theme.getThemeClass()}`}>
              <div className="tabs">
                <div className={`tab ${currentTab === AddTradePageTabs.Manual ? 'active' : ''}`}
                     onClick={() => setCurrentTab(AddTradePageTabs.Manual)}>{t("add_trade_page.tabs.0")}
                </div>
                <div className={`tab ${currentTab === AddTradePageTabs.AutoSync ? 'active' : ''}`}
                     onClick={() => setCurrentTab(AddTradePageTabs.AutoSync)}>{t("add_trade_page.tabs.1")}
                </div>
                <div className={`tab ${currentTab === AddTradePageTabs.ManualInsert ? 'active' : ''}`}
                     onClick={() => setCurrentTab(AddTradePageTabs.ManualInsert)}>{t("add_trade_page.tabs.2")}
                </div>
              </div>
              <div className="tab-content">
                {
                  currentTab === AddTradePageTabs.Manual ?
                      <ManualImport /> : <></>
                }
                {
                  currentTab === AddTradePageTabs.AutoSync ?
                      <AutoSync /> : <></>
                }
                {
                  currentTab === AddTradePageTabs.ManualInsert ?
                      <ManualInsert /> : <></>
                }
              </div>
            </div>
          </div>
        </ViewPermission>
      </>
  );
}

export default AddTrade;
