import {useTranslation} from "react-i18next";
import AnalyticsDashboardImage from "../../../assets/images/feature-images/Analytics Dashboard.png";
import CommunityImage from "../../../assets/images/feature-images/Community.jpg";
import DailyJournalImage from "../../../assets/images/feature-images/Daily Journal.jpg";
import NotebookImage from "../../../assets/images/feature-images/Notebook.jpg";
import PlaybookImage from "../../../assets/images/feature-images/Playbook.jpg";
import ReportsImage from "../../../assets/images/feature-images/Reports.jpg";

const FeaturesSection = () => {

    const {t} = useTranslation();

    const features = [
        {
            heading: t("landing_page.features_section.features.0.title"),
            description: t("landing_page.features_section.features.0.description"),
            image: AnalyticsDashboardImage,
            reversed: false,
            imagePosition: 'bottom left',
            objectPosition: "top left",
        },
        {
            heading: t("landing_page.features_section.features.1.title"),
            description: t("landing_page.features_section.features.1.description"),
            image: DailyJournalImage,
            reversed: true,
            imagePosition: 'top right',
            objectPosition: "bottom left",
        },
        {
            heading: t("landing_page.features_section.features.2.title"),
            description: t("landing_page.features_section.features.2.description"),
            image: ReportsImage,
            reversed: true,
            imagePosition: 'top center',
            objectPosition: "v-center h-center",
        },
        {
            heading: t("landing_page.features_section.features.3.title"),
            description: t("landing_page.features_section.features.3.description"),
            image: PlaybookImage,
            reversed: false,
            imagePosition: 'bottom left',
            objectPosition: "v-center h-center",
        },
        {
            heading: t("landing_page.features_section.features.4.title"),
            description: t("landing_page.features_section.features.4.description"),
            image: NotebookImage,
            reversed: false,
            imagePosition: 'bottom center',
            objectPosition: "v-center h-center",
        },
        {
            heading: t("landing_page.features_section.features.5.title"),
            description: t("landing_page.features_section.features.5.description"),
            image: CommunityImage,
            reversed: true,
            imagePosition: 'top center',
            objectPosition: "v-center h-center",
        }
    ]

    return (
        <div className='features-section front' id={'next-section'}>
            <div className="content">
                <h1 className="heading">
                    {t("landing_page.features_section.title")}
                </h1>
                <div className="features">
                    {
                        features.map((feature, index) => {
                            return (
                                <div className={`feature ${feature.reversed ? 'reverse' : ''}`} key={index}>
                                    <div className="content">
                                        <div className="heading">{feature.heading}</div>
                                        <div className="description">{feature.description}</div>
                                    </div>
                                    <div className={`image ${feature.imagePosition}`}>
                                        <img src={feature.image} className={feature.objectPosition} alt={feature.heading}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FeaturesSection;