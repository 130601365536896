import {useEffect, useState} from "react";
import {useTheme} from "../../Providers/ThemeContext";
import {sendDelete, sendGet, sendPost, sendPut} from "../../../common/request";
import links from "../../../common/links";
import {toast} from "react-toastify";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import Loader from "../../components/Loader";
import currencyFormat from "../../../common/format";
import DataToolTip from "../../components/ToolTip";
import {MdDelete, MdEdit} from "react-icons/md";
import useSubAccount from "../../hooks/useSubAccount";
import CommissionEditModal from "../../components/Modal/CommissionEditModal";
import {useTranslation} from "react-i18next";

const CommissionsPage = () => {
    const theme = useTheme();
    const [commissions, setCommissions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [error, setError] = useState("");
    const [commissionData, setCommissionData] = useState({});
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
        shown: false,
        commissionId: null
    });
    const subAccount = useSubAccount();
    const [totalPages, setTotalPages] = useState(1);
    const [mode, setMode] = useState('Add');
    const [symbolsData, setSymbolsData] = useState({});
    const {t} = useTranslation();
    const commissionModeCoverUps = {
        "per Contract": t("coverUps.commissionMode.perContract"),
        "per Share": t("coverUps.commissionMode.perShare"),
        "per Execution": t("coverUps.commissionMode.perExecution"),
    }
    const executionCoverUps = {
        "on entry executions": t("coverUps.commissionExecution.onEntry"),
        "on exit executions": t("coverUps.commissionExecution.onExit"),
        "on all executions": t("coverUps.commissionExecution.onAll"),
    }

    const loadCommissions = async () => {
        if(subAccount) {
            setIsLoading(true);
            let response = await sendGet(links.GetCommissions(subAccount.id), true);
            if(response.error) {
                console.log(response.error)
            }
            else {
                setCommissions(response.commissions)
                setTotalPages(response.pages)
            }
            setIsLoading(false);
        }
    };

    const loadSymbolsData = async () => {
        let response = await sendGet(links.GetSymbols, true);
        if(response.error) {
            console.log(response.error)
        }
        else {
            setSymbolsData(response.symbols)
        }
    }

    const handleCloseModal = () => {
        setCommissionData({});
        setIsAddModalOpen(false);
    };

    const handleSaveModal = async () => {
        setIsModalLoading(true);
        setError('');
        const data = {
            ...commissionData,
            subAccountId: subAccount.id
        }
        if (mode === "Add") {
            let response = await sendPost(
                links.CreateCommission,
                data,
                true
            );

            if (response.error) {
                setError(response.error);
            } else {
                toast.success("Commission Added Successfully");
                await loadCommissions();
                handleCloseModal();
            }
        } else {
            setIsModalLoading(true);
            let response = await sendPut(
                links.UpdateCommission(data.id),
                data,
                true
            );

            if (response.error) {
                setError(response.error);
            } else {
                await loadCommissions();
                toast.success("Commission Updated Successfully");
                handleCloseModal();
            }
        }
        setIsModalLoading(false);
    };

    const openModal = (mode, commission) => {
        if (mode !== "Add") {
            setCommissionData(commission);
        }
        else {
            setCommissionData({});
        }
        setMode(mode);
        setIsAddModalOpen(true);
    };

    const confirmDeleteCommission = async () => {
        const id = deleteConfirmationModal.commissionId;
        if(id) {
            setIsLoading(true);
            let response = await sendDelete(links.DeleteCommission(id), "", true);
            if (!response.error) {
                await loadCommissions();
                toast.success("Commission deleted Successfully");
            } else {
                toast.error(response.error)
            }
            setDeleteConfirmationModal({
                shown: false,
                commissionId: null
            })
            setIsLoading(false);
        }
    };

    const cancelDeleteCommission = () => {
        setDeleteConfirmationModal({
            shown: false,
            commissionId: null
        })
    }

    const deleteCommission = (id) => {
        setDeleteConfirmationModal({
            shown: true,
            commissionId: id
        })
    }

    useEffect(() => {
        loadCommissions();
    }, [subAccount]);

    useEffect(() => {
        loadSymbolsData();
    }, [])

    return (
        <>
            {
                deleteConfirmationModal.shown ?
                    <ConfirmationModal heading={t("settings.commissions.deleteConfirmation.title")} message={t("settings.commissions.deleteConfirmation.message")}
                                       handleConfirmModal={confirmDeleteCommission} handleCloseModal={cancelDeleteCommission} isDelete={true} />
                    : <></>
            }
            <CommissionEditModal symbolsData={symbolsData} isOpen={isAddModalOpen} handleCloseModal={handleCloseModal} handleSave={handleSaveModal}
                                 commissionData={commissionData}
                                 setCommissionData={setCommissionData}
                                 mode={mode} error={error} isLoading={isModalLoading}
            />
            {
                isLoading ? (
                    <Loader fullScreen={true} />
                ) : (
                    <div className={`data-page commission-page ${theme.getThemeClass()}`}>
                        <div className="page-header">
                            <div className="title">
                                {t("settings.commissions.title")}
                                <p className="info">{t("settings.commissions.description")}</p>
                            </div>
                            <div className="primary-btn" onClick={() => openModal("Add")}>
                                {t("settings.commissions.addBtn")}
                            </div>
                        </div>
                        <div className="data-page-body">
                            <div className={`table ${theme.getThemeClass()}`}>
                                <div className="header">
                                    <div className="item">{t("settings.commissions.headers.0")}</div>
                                    <div className="item">{t("settings.commissions.headers.1")}</div>
                                    <div className="item">{t("settings.commissions.headers.2")}</div>
                                    <div className="item">{t("settings.commissions.headers.3")}</div>
                                    <div className="item">{t("settings.commissions.headers.4")}</div>
                                    <div className="item">{t("settings.commissions.headers.5")}</div>
                                    <div className="item"></div>
                                    <div></div>
                                </div>
                                {
                                    commissions.length === 0 ?
                                        <div className={'empty-message mt-2'}>No commissions found for the sub account {subAccount ? subAccount.name : ''}</div> :
                                        commissions.map((commission, index) => {
                                            return (
                                                <div className="row grid-col-6" key={index}>
                                                    <div className="item">{commission.instrument}</div>
                                                    <div className="item">{commission.symbol}</div>
                                                    <div className="item">{commissionModeCoverUps[commission.mode]}</div>
                                                    <div className="item">{executionCoverUps[commission.apply]}</div>
                                                    <div className="item">{currencyFormat.format(commission.commission)}</div>
                                                    <div className="item">{currencyFormat.format(commission.fee)}</div>
                                                    <div className="item action-btn">
                                                        <DataToolTip message={t("settings.commissions.buttons.edit")}>
                                                            <div className="text-btn" onClick={() => openModal("Edit", commission)}>
                                                                <MdEdit/>
                                                            </div>
                                                        </DataToolTip>
                                                        <DataToolTip message={t("settings.commissions.buttons.delete")}>
                                                            <div className="text-danger-btn" onClick={() => deleteCommission(commission.id)}>
                                                                <MdDelete/>
                                                            </div>
                                                        </DataToolTip>
                                                    </div>
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default CommissionsPage;