import NinjaTrader from "../../images/NinjaTrader_Wordmark_color_RGB.png";
import Kinetick from "../../images/Kinetick_Logo-removebg-preview.png";
import ATAS from "../../images/atas-logo.png";
import Rithmic from "../../images/rithmic-logo.png";
import CQG from "../../images/cqg-logo.png";
import Tradovate from '../../images/tradovate.png'
import {useTranslation} from "react-i18next";

const BrokersSection = () => {

    const brokers = [
        {
            image: NinjaTrader,
            link: "https://ninjatrader.com/"
        },
        {
            image: Kinetick,
            link: "http://kinetick.com/NinjaTrader"
        },
        {
            image: ATAS,
            text: 'ATAS',
            className: 'atas',
            link: "https://atas.net/de/registration-demo/?rs=partners_oft158681"
        },
        {
            image: Rithmic,
            text: 'Rithmic',
            className: 'rithmic',
            link: "https://www.rithmic.com/"
        },
        {
            image: CQG,
            text: 'CQG',
            className: 'cqg',
            link: "https://www.cqg.com/"
        },
        {
            image: Tradovate,
            link: "https://www.tradovate.com/"
        }
    ]
    const {t} = useTranslation()

    return (
        <div className='brokers-section front padded-bottom' id='next-section'>
            <div className="content">
                <div className="brokers-cards">
                    {
                        brokers.map((broker, index) => {
                            return (
                                <a className="broker-card-container" href={broker.link} target={"_blank"}>
                                    <div className={`broker-card ${broker.className ? broker.className : ''}`}
                                         key={index}>
                                        <img src={broker.image} alt={broker.text}/>
                                        {
                                            broker.text ?
                                                <p>{broker.text}</p> : <></>
                                        }
                                    </div>
                                </a>
                            )
                        })
                    }
                </div>
                <p className={'note'}>{t("brokersPage.brokersSection.note")}</p>
            </div>
        </div>
    )
}

export default BrokersSection;