import "../styles/no-subscription-display.scss";
import {useNavigate} from "react-router-dom";
import {useTheme} from "../Providers/ThemeContext";
import {useTranslation} from "react-i18next";

const NoSubscriptionDisplay = () => {
    const navigate = useNavigate();
    const theme = useTheme()
    const {t} = useTranslation();

    return (
        <div className={`card-container ${theme.getThemeClass()}`}>
            <div className="card">
                <div className="info">{t("no_subscription_display.message.0")}<br/>{t("no_subscription_display.message.1")}</div>
                <div className="buttons">
                    <div className="primary-outline-btn" onClick={() => navigate('/pricing')}>
                        {t("no_subscription_display.buttons.viewPlan")}
                    </div>
                    <div className="secondary-btn" onClick={() => navigate(-1)}>
                        {t("no_subscription_display.buttons.goBack")}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoSubscriptionDisplay;