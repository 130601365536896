import tickData from "../assets/data/tick-data.json";
import numberFormat from "./format";

const getTickInfo = (securityId) => {

    if(securityId && !securityId.trim().endsWith("@CME")) {
        return undefined;
    }

    let keys = Object.keys(tickData);
    let biggestMatch = '';
    for (const key of keys) {
        if (securityId?.startsWith(key) && key.length > biggestMatch.length) {
            biggestMatch = key;
        }
    }

    return tickData[biggestMatch];
};

const getAdjustedPrice = (securityId, price) => {
    const tickInfo = getTickInfo(securityId);
    if(tickInfo) {
        return (price / tickInfo.tickSize) *
            tickInfo.tickValue
    }
    else {
        return price;
    }
}

const getAdjustedCost = (trade) => {
    return getAdjustedPrice(trade.SecurityId, trade.OpenPrice) * Math.abs(parseFloat(trade.OpenVolume))
}

const getNetROI = (trade) => {
    const adjustedCost = getAdjustedCost(trade);
    let netROI = parseFloat(((trade.PnL / adjustedCost) * 100).toFixed(2));

    if(netROI === -0) {
        netROI = Math.abs(netROI);
    }
    return netROI.toFixed(2);
};

const getPlannedRMultiple = (trade) => {

    if(trade.OpenPrice === trade.StopLoss) {
        return 0;
    }

    let initialTarget = 0;
    let tradeRisk = 0;

    if(trade.Side === 'LONG') {
        initialTarget = (trade.ProfitTarget - trade.OpenPrice) * Math.abs(trade.OpenVolume);
        tradeRisk = (trade.OpenPrice - trade.StopLoss) * Math.abs(trade.OpenVolume);
    }
    else {
        initialTarget = (trade.OpenPrice - trade.ProfitTarget) * Math.abs(trade.OpenVolume);
        tradeRisk = (trade.StopLoss - trade.OpenPrice) * Math.abs(trade.OpenVolume);
    }

    initialTarget = getAdjustedPrice(trade.SecurityId, initialTarget);
    tradeRisk = getAdjustedPrice(trade.SecurityId, tradeRisk);

    return parseFloat((initialTarget / tradeRisk).toFixed(2))
}

const getRealizedRMultiple = (trade) => {

    let tradeRisk = 0;
    if(trade.Side === 'LONG') {
        tradeRisk = (trade.OpenPrice - trade.StopLoss) * Math.abs(trade.OpenVolume);
    }
    else {
        tradeRisk = (trade.StopLoss - trade.OpenPrice) * Math.abs(trade.OpenVolume);
    }

    const adjustedRisk = getAdjustedPrice(trade.SecurityId, tradeRisk)

    if(adjustedRisk === 0)
        return 0;

    return parseFloat((trade.PnL / adjustedRisk).toFixed(2))
}

const getAveragePlannedRMultiple = (trades) => {
    const rMultiples = [];
    for(let trade of trades) {
        rMultiples.push(getPlannedRMultiple(trade));
    }
    return parseFloat((rMultiples.reduce((total, current) => total + current, 0) / rMultiples.length).toFixed(2));
}

const getAverageRealizedRMultiple = (trades) => {
    const rMultiples = [];
    for(let trade of trades) {
        rMultiples.push(getRealizedRMultiple(trade));
    }
    return parseFloat((rMultiples.reduce((total, current) => total + current, 0) / rMultiples.length).toFixed(2));
}

const getGrossPnL = (trade, isFormatted) => {
    if (isFormatted) {
        return numberFormat.format(trade.PnL + Math.abs(trade.Commission));
    } else {
        return trade.PnL + Math.abs(trade.Commission);
    }
}

const getArray = (number) => {
    return Array.from({length: number}, (_, i) => i)
}

export {
    getAdjustedPrice,
    getNetROI,
    getAdjustedCost,
    getTickInfo,
    getPlannedRMultiple,
    getRealizedRMultiple,
    getAveragePlannedRMultiple,
    getAverageRealizedRMultiple,
    getGrossPnL,
    getArray
}