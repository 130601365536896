import Select from "../selectcontrol";
import Brokers from "../../enums/brokers";
import {IoCloudUploadOutline, IoFileTrayFull} from "react-icons/io5";
import {MdChevronRight, MdClose} from "react-icons/md";
import Loader from "../Loader";
import ATASLogo from "../../images/add-trade/atas-logo.png";
import ATAS1 from "../../images/add-trade/atas-1.png";
import ATAS2 from "../../images/add-trade/atas-2.jpg";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import useSubAccounts from "../../hooks/useSubAccounts";
import momentTimeZone from "moment-timezone";
import {getTimezoneOffset} from "../../utils/date";
import useSubAccount from "../../hooks/useSubAccount";
import {sendPost} from "../../../common/request";
import Urls from "../../../common/links";
import moment from "moment/moment";
import {toast} from "react-toastify";
import ProfitCalculationMethod from "../../enums/profit-calculation-method";
import tickSizeData from "../../data/tick-data.json";
import {getArray} from "../../../common/methods";

const ManualImport = () => {

    const [file, setFile] = useState(undefined);
    const {t} = useTranslation();
    const accounts = useSelector(state => state.user?.user);
    const subAccounts = useSubAccounts();
    const subAccountNames = subAccounts ? subAccounts.map(acc => acc.name) : [];
    const [selectedSubAccount, setSelectedSubAccount] = useState();
    const dispatch = useDispatch();
    const brokers = [
        Brokers.Atas,
        Brokers.CQG,
        Brokers.Rithmic,
        Brokers.NinjaTrader
    ];
    const [selectedBroker, setSelectedBroker] = useState(Brokers.Atas);
    const timeZones = momentTimeZone.tz.names().map(timezone => {
        return {
            timezone: timezone,
            offset: getTimezoneOffset(timezone)
        }
    })
    const timeZoneNames = timeZones.map(tz => tz.timezone);
    const [selectedTimeZone, setSelectedTimeZone] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const subAccount = useSubAccount();

    const requiredFields = {
        [Brokers.Atas]: ['Id', 'SecurityId', "OrderDirection", 'Price', 'Time', 'Volume', 'Commission'],
        [Brokers.Rithmic]: ['Order Number', 'Symbol', 'Status', 'Buy/Sell', 'Qty To Fill', 'Avg Fill Price', 'Update Time (WEDT)', 'Update Time (WEST)', "Commission Fill Rate"],
        [Brokers.CQG]: ['Symbol', 'Status', 'B/S', 'Qty', 'AvgFillP', 'PlaceT'],
        [Brokers.NinjaTrader]: ['ID', 'Instrument', 'Action', 'Quantity', 'Price', 'Time'],
    };

    const fieldMapping = {
        [Brokers.Atas]: {
            "Id": "Id",
            "SecurityId": "SecurityId",
            "OrderDirection": "Type",
            "Price": "Price",
            "Time": "Time",
            "Volume": "Volume",
            "Commission": "Commission"
        },
        [Brokers.Rithmic]: {
            'Order Number': 'Id',
            Symbol: 'SecurityId',
            Status: 'Status',
            'Buy/Sell': 'Type',
            'Avg Fill Price': 'Price',
            'Qty To Fill': 'Volume',
            'Update Time (WEDT)': 'Time',
            'Update Time (WEST)': 'Time',
            "Commission Fill Rate": "Commission"
        } ,
        [Brokers.CQG]: {
            Symbol: "SecurityId",
            Status: "Status",
            'B/S': "Type",
            Qty: 'Volume',
            'AvgFillP': 'Price',
            'PlaceT': 'Time'
        },
        [Brokers.NinjaTrader]: {
            ID: 'Id',
            Instrument: "SecurityId",
            Action: "Type",
            Quantity: 'Volume',
            Price: 'Price',
            Time: 'Time'
        },
    }

    const parseCSVData = (line) => {
        const regex = /"([^"]*)"/g;
        const cleanedValues = line.includes("\"") ? line.match(regex) : line.split(',');
        return cleanedValues.map((value) => value.replaceAll(",", " ").replace(/"/g, "").trim());
    }

    useEffect(() => {
        if(accounts && accounts.user) {
            const index = timeZoneNames.indexOf(accounts.user.timezone);
            if(index >= 0) {
                setSelectedTimeZone(timeZones[index]);
            }
        } else {
            setSelectedTimeZone(timeZones[0])
        }
    }, [accounts])

    useEffect(() => {
        if(subAccount) {
            setSelectedSubAccount(subAccount)
            setSelectedBroker(subAccount.broker)
        }
    }, [subAccount])

    const parseAtasFile = (csvText) => {
        const lines = csvText.split("\n");

        if (lines.length < 4) {
            // Ensure there are enough lines in the CSV
            console.error("Invalid cdb format");
            return [];
        }

        const headers = lines[2].split(",").map((header) => header.trim());

        const parsedData = [];

        for (let i = 3; i < lines.length; i++) {
            const values = lines[i].split(",");
            if (values.length !== headers.length) {
                continue;
            }

            const entry = {};
            for (let j = 0; j < headers.length; j++) {
                const cleanedHeader = headers[j]
                    .replace(/"/g, "")
                    .replace(/Columns: /g, "");
                entry[cleanedHeader] = values[j].trim().replace(/"/g, "");
            }
            parsedData.push(entry);
        }
        return parsedData;
    };

    const parseRithmicFile = (csvText) =>   {
        const lines = csvText.split("\n");
        if (lines.length < 5) {
            console.error("Invalid CSV format");
            return [];
        }

        const headers = lines[5]
            .replace(/"/g, "")
            .split(",")
            .map((header) => header.trim());
        // Using the correct position of headers
        const completedOrders = [];
        for (let i = 6; i < lines.length; i++) {
            const line = lines[i].trim();
            const entry = {};

            if (line) {
                console.log(line)
                const values = parseCSVData(line);
                if (values.length !== headers.length) {
                    continue;
                }

                for (let j = 0; j < headers.length; j++) {
                    entry[headers[j]] = values[j];
                }

                completedOrders.push(entry);
            }
        }
        return completedOrders;
    };

    const parseCQGFile = (csvText) => {
        const lines = csvText.split("\n");

        // Extracting headers
        const headerStartIndex = 2;
        const headers = lines[headerStartIndex]
            .replace(/"/g, "")
            .split(";")
            .map((header) => header.trim());

        // remove spaces from headers
        headers.forEach((header, index) => {
            headers[index] = header.replace(/\s+/g, "");
        });
        // Extract date from the first line
        const dateLine = lines[0].trim();
        const dateMatch = dateLine.match(
            /(\d{1,2}[./]\d{1,2}[./]\d{1,2}) \d{1,2}:\d{1,2}:\d{1,2}/
        );
        const reportDate = dateMatch ? dateMatch[1] : null;

        // Using the correct position of headers
        const completedOrders = [];
        for (let i = headerStartIndex + 1; i < lines.length - 1; i++) {
            const line = lines[i].trim();
            const entry = {};

            if (line) {
                const cleanedLine = line.replace(/"/g, ""); // Replace double quotes
                const values = cleanedLine.split(";").map((value) => value.trim());

                if (values.length !== headers.length) {
                    continue;
                }

                for (let j = 0; j < headers.length; j++) {
                    entry[headers[j]] = values[j];
                }
                // Add date and time to the field
                const date = moment(`${reportDate} ${entry['PlaceT']}`, 'DD.MM.YYYY HH:mm:ss');
                if(!date.isValid()) {
                    throw new Error(`Invalid Report Date Found ${reportDate} ${entry['PlaceT']}`);
                }
                entry["PlaceT"] = date.format('MM/DD/YYYY HH:mm:ss');
                completedOrders.push(entry);
            }
        }

        return completedOrders;
    };

    const parseNinjaFile = (csvText) => {
        const lines = csvText.split("\n");

        if (lines.length < 2) {
            console.error("Invalid CSV format");
            return [];
        }

        let splitChar = ",";

        if(lines[0].includes(';')) {
            splitChar = ';';
        }

        // Extracting headers
        const headers = lines[0].split(splitChar).map((header) => header.trim());

        // Using the correct position of headers
        const completedOrders = [];

        for (let i = 1; i < lines.length - 1; i++) {
            const line = lines[i].trim();
            const entry = {};

            if (line) {
                const values = line.split(splitChar).map(v => v.trim())

                if (values.length !== headers.length) {
                    continue;
                }

                for (let j = 0; j < headers.length; j++) {
                    if(headers[j] === 'Commission') {
                        entry[headers[j]] = parseFloat(values[j].replace("$", ""))
                    } else {
                        entry[headers[j]] = values[j];
                    }
                }

                completedOrders.push(entry);
            }
        }

        return completedOrders;
    };

    const loadFileContent = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (e) => {
                const content = e.target.result;
                resolve({content: content});
            };

            fileReader.readAsText(file);
        })
    }

    const formatData = (data, broker, timeZone) => {
        if(broker === Brokers.Atas) {

            const entries = data.map(entry => {
                const newEntry = {};
                const fieldNames = Object.keys(entry);
                const allowedFieldNames = requiredFields[broker];
                const numberFields = ['Price', 'Volume', "Commission"];
                const fieldNameMapping = fieldMapping[broker];
                const dateFields = ['Time']
                for(const fieldName of fieldNames) {
                    if(!allowedFieldNames.includes(fieldName)) {
                        delete entry[fieldName];
                    }
                    else {
                        const mappedFieldName = fieldNameMapping[fieldName];
                        let value = entry[fieldName];
                        if(dateFields.includes(mappedFieldName)) {
                            const date = moment(value, ["MM/DD/YYYY HH:mm:ss", "MM/DD/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"]);
                            if(!date.isValid()) {
                                throw new Error(`Invalid Date Found at ${fieldName} with value ${value}`);
                            }
                            value = date.format('MM/DD/YYYY HH:mm:ss');
                        } else if(numberFields.includes(mappedFieldName)) {
                            value = parseFloat(parseFloat(value).toFixed(2));
                        }
                        newEntry[mappedFieldName] = value;
                    }
                }

                newEntry['ProfitTarget'] = 0;
                newEntry['StopLoss'] = 0;
                newEntry['Side']= '';
                return newEntry;
            });
            return entries;
        }
        else if(broker === Brokers.Rithmic) {

            const entries = data.map(entry => {
                const newEntry = {};
                const fieldNames = Object.keys(entry);
                const allowedFieldNames = requiredFields[broker];
                const fieldNameMapping = fieldMapping[broker];
                const numberFields = ['Price', 'Volume', "Commission"]
                for(const fieldName of fieldNames) {
                    if(!allowedFieldNames.includes(fieldName)) {
                        delete entry[fieldName];
                    } else {
                        const mappedFieldName = fieldNameMapping[fieldName];
                        let value = entry[fieldName];
                        if(mappedFieldName === 'Type') {
                            value = value === 'B' ? 'Buy' : 'Sell';
                        } else if(mappedFieldName === 'Time') {
                            const date = moment(value, ["DD/MM/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"]);
                            if(!date.isValid()) {
                                throw new Error(`Invalid Date Found at ${fieldName} with value ${value}`);
                            }
                            value = date.format('MM/DD/YYYY HH:mm:ss');
                        } else if(numberFields.includes(mappedFieldName)) {
                            value = parseFloat(parseFloat(value).toFixed(2));
                        }
                        newEntry[mappedFieldName] = value;
                    }
                }

                newEntry['ProfitTarget'] = 0;
                newEntry['StopLoss'] = 0;
                newEntry['Side']= '';
                return newEntry;
            });

            return entries.filter(entry => entry.Status === 'Filled');
        }
        else if(broker === Brokers.CQG && timeZone) {

            const entries = data.map((entry, index) => {
                const newEntry = {};
                const fieldNames = Object.keys(entry);
                const allowedFieldNames = requiredFields[broker];
                const fieldNameMapping = fieldMapping[broker];
                const numberFields = ['Price', 'Volume']
                for(const fieldName of fieldNames) {
                    if(!allowedFieldNames.includes(fieldName)) {
                        delete entry[fieldName];
                    } else {
                        const mappedFieldName = fieldNameMapping[fieldName];
                        let value = entry[fieldName];
                        if(mappedFieldName === 'Type') {
                            value = value === 'BUY' ? 'Buy' : 'Sell';
                        } else if(mappedFieldName === 'Price') {
                            value = value.replaceAll(',', '.');
                            value = parseFloat(value)
                        } else if(numberFields.includes(mappedFieldName)) {
                            value = parseFloat(value);
                        } else if(mappedFieldName === 'Time') {
                            const date = moment.tz(value, "MM/DD/YYYY HH:mm:ss", timeZone.timezone).tz(moment.tz.guess());
                            if(!date.isValid()) {
                                throw new Error(`Invalid Date Found at ${fieldName} with value ${value}`);
                            }
                            value = date.format('MM/DD/YYYY HH:mm:ss');
                        }
                        newEntry[mappedFieldName] = value;
                    }
                }

                newEntry['Id'] = index + 1;
                newEntry['ProfitTarget'] = 0;
                newEntry['StopLoss'] = 0;
                newEntry['Side']= '';
                return newEntry;
            });

            return entries.filter(entry => entry.Status === 'Filled');
        }
        else if(broker === Brokers.NinjaTrader) {

            const entries = data.map(entry => {
                const newEntry = {};
                const fieldNames = Object.keys(entry);
                const allowedFieldNames = requiredFields[broker];
                const fieldNameMapping = fieldMapping[broker];
                const numberFields = ['Price', 'Volume']
                for(const fieldName of fieldNames) {
                    if(!allowedFieldNames.includes(fieldName)) {
                        delete entry[fieldName];
                    } else {
                        const mappedFieldName = fieldNameMapping[fieldName];
                        let value = entry[fieldName];
                        if(mappedFieldName === 'Time') {
                            const date = moment(value, 'DD.MM.YYYY HH:mm:ss');
                            if(!date.isValid()) {
                                throw new Error(`Invalid Date Found at ${fieldName} with value ${value}`);
                            }
                            value = date.format('MM/DD/YYYY HH:mm:ss');
                        } else if(numberFields.includes(mappedFieldName)) {
                            value = parseFloat(value);
                        }
                        newEntry[mappedFieldName] = value;
                    }
                }

                newEntry['ProfitTarget'] = 0;
                newEntry['StopLoss'] = 0;
                newEntry['Side']= '';
                return newEntry;
            });

            return entries;
        } else {
            return data;
        }
    }

    const parseFile = async (file, broker) => {
        const content = await loadFileContent(file);
        let data = undefined;
        if(broker === Brokers.Atas && file.name.endsWith('.cdb')) {
            data = parseAtasFile(content.content);
        } else if(broker === Brokers.Rithmic && file.name.endsWith('.csv')) {
            data = parseRithmicFile(content.content);
        } else if(broker === Brokers.CQG && file.name.endsWith('.csv')) {
            data = parseCQGFile(content.content);
        } else if(broker === Brokers.NinjaTrader && file.name.endsWith('.csv')) {
            data = parseNinjaFile(content.content);
        } else {
            toast.error('Invalid File Type. Please upload the specified file type for a broker')
            return [];
        }

        if(!data) {
            console.error('No Data Found from the file')
            return [];
        } else {
            return data;
        }
    }

    const handleBrowseClick = (e) => {
        const parent = e.currentTarget.parentNode;
        const fileInput = parent.querySelector('input[type="file"]');
        if(fileInput) {
            fileInput.click();
        }
    }

    const handleFileUpload = (e) => {
        const fileInput = e.currentTarget;
        if(fileInput.files.length > 0) {
            const file = fileInput.files[0];
            setFile(file);
        }
    }

    const removeFile = () => {
        setFile(undefined);
    }

    const getAveragePrice = (trades) => {
        let volume = 0;
        let total = 0;
        for(const trade of trades) {
            volume += trade.Volume;
            total += trade.Volume * trade.Price;
        }
        return parseFloat((total / volume).toFixed(2));
    }

    const convertToTrades = (formattedData) => {
        const buyTradesToSettle = {};
        const sellTradesToSettle = {};
        const orderedEntries = formattedData.sort((a, b) => {
            const t1 = moment(a.Time, 'MM/DD/YYYY HH:mm:ss').toDate();
            const t2 = moment(b.Time, 'MM/DD/YYYY HH:mm:ss').toDate();
            const timeDifference = t1.getTime() - t2.getTime();

            if(timeDifference === 0) {
                return parseInt(a.Id) - parseInt(b.Id)
            }
            else {
                return timeDifference;
            }
        });
        const trades = [];

        //Going over each entry
        for(const entry of orderedEntries) {
            const symbol = entry.SecurityId;

            //If the trade is buy
            if(entry.Type === 'Buy') {

                //Checking if we have any trade to settle
                //If no trade to settle against. Put the trade in the list
                if(!sellTradesToSettle[symbol] || sellTradesToSettle[symbol].length === 0) {
                    if(!buyTradesToSettle[symbol]) {
                        buyTradesToSettle[symbol] = [];
                    }
                    buyTradesToSettle[symbol].push(entry);
                    continue;
                }

                let sharesToSettle = entry.Volume;
                let index = 0;
                let tradesSettled = 0;
                //If there are any sell trades available
                for(const sellTrade of sellTradesToSettle[symbol]) {

                    const trade = {
                        OpenId: sellTrade.Id,
                        SecurityId: entry.SecurityId,
                        Status: entry.Status,
                        Type: entry.Type,
                        ProfitTarget: entry.ProfitTarget,
                        StopLoss: entry.StopLoss,
                        Side: entry.Side,
                        CloseTime: entry.Time,
                        ClosePrice: entry.Price,
                        OpenType: 'Sell',
                        CloseType: 'Buy',
                    };

                    if(sharesToSettle === 0) {
                        break;
                    }
                    const availableShares = sellTrade.Volume;
                    const settledShares = sharesToSettle <= availableShares ? sharesToSettle : availableShares;
                    trade.CloseId = entry.Id;
                    trade.CloseVolume = settledShares
                    trade.OpenVolume = -1 * settledShares;
                    trade.OpenTime = sellTrade.Time;
                    trade.Side = 'SHORT';

                    if(selectedSubAccount.ProfitCalculationMethod === ProfitCalculationMethod.FIFO)
                    {
                        trade.PnL = parseFloat(((sellTrade.Price - entry.Price) * settledShares).toFixed(2));
                        trade.OpenPrice = sellTrade.Price;
                    }
                    else if (selectedSubAccount.ProfitCalculationMethod === ProfitCalculationMethod.WeightedAverage) {
                        //Getting the average purchase price
                        const averagePrice = getAveragePrice(sellTradesToSettle[symbol]);
                        trade.PnL = parseFloat(((averagePrice - entry.Price) * settledShares).toFixed(2));
                        trade.OpenPrice = averagePrice;
                    }
                    trade.Commission = (entry.Commission ? (entry.Commission * settledShares) : 0) + (sellTrade.Commission ? (sellTrade.Commission * settledShares) : 0) ;
                    trades.push(trade);

                    if(sharesToSettle === availableShares) {
                        sellTradesToSettle[symbol].shift();
                        for(let i = 0; i < tradesSettled; i += 1) {
                            sellTradesToSettle[symbol].shift()
                        }
                        tradesSettled = 0;
                        break;
                    } else if(sharesToSettle > availableShares) {
                        tradesSettled += 1;
                        if(sellTradesToSettle[symbol].length === tradesSettled) {
                            let totalSharesSettled = 0;
                            for(let i = 0; i < tradesSettled - 1; i += 1) {
                                totalSharesSettled += sellTradesToSettle[symbol][i].Volume
                                buyTradesToSettle[symbol].shift()
                            }
                            totalSharesSettled += settledShares;
                            sellTradesToSettle[symbol].shift();
                            tradesSettled = 0;
                            const updatedEntry = {...entry}
                            updatedEntry.Volume -= totalSharesSettled;
                            buyTradesToSettle[symbol].push(updatedEntry);
                        } else {
                            sharesToSettle -= settledShares;
                        }
                    } else if(sharesToSettle < availableShares) {
                        sellTradesToSettle[symbol][index].Volume -= settledShares;
                        for(let i = 0; i < tradesSettled; i += 1) {
                            sellTradesToSettle[symbol].shift()
                        }
                        tradesSettled = 0;
                        break;
                    }
                    index += 1;
                }

            }
            else {

                //Checking if we have any trade to settle
                //If no trade to settle against. Put the trade in the list
                if(!buyTradesToSettle[symbol] || buyTradesToSettle[symbol].length === 0) {
                    if(!sellTradesToSettle[symbol]) {
                        sellTradesToSettle[symbol] = [];
                    }
                    sellTradesToSettle[symbol].push(entry)
                    continue;
                }

                let sharesToSettle = entry.Volume;
                let tradesSettled = 0;
                let index = 0;
                //If there are any sell trades available
                for(const buyTrade of buyTradesToSettle[symbol]) {

                    const trade = {
                        OpenId: buyTrade.Id,
                        SecurityId: entry.SecurityId,
                        Status: entry.Status,
                        Type: entry.Type,
                        ProfitTarget: entry.ProfitTarget,
                        StopLoss: entry.StopLoss,
                        Side: entry.Side,
                        CloseTime: entry.Time,
                        ClosePrice: entry.Price,
                        OpenType: 'Buy',
                        CloseType: 'Sell',
                    };

                    if(sharesToSettle === 0) {
                        break;
                    }
                    const availableShares = buyTrade.Volume;
                    const settledShares = sharesToSettle <= availableShares ? sharesToSettle : availableShares;
                    trade.CloseId = entry.Id;
                    trade.CloseVolume = -1 * settledShares
                    trade.OpenVolume = settledShares;
                    trade.OpenPrice = buyTrade.Price;
                    trade.OpenTime = buyTrade.Time;
                    trade.Side = 'LONG';
                    trade.PnL = (entry.Price - buyTrade.Price) * settledShares;
                    if(selectedSubAccount.ProfitCalculationMethod === ProfitCalculationMethod.FIFO)
                    {
                        trade.PnL = parseFloat(((entry.Price - buyTrade.Price) * settledShares).toFixed(2))
                        trade.OpenPrice = buyTrade.Price;
                    }
                    else if (selectedSubAccount.ProfitCalculationMethod === ProfitCalculationMethod.WeightedAverage) {
                        //Getting the average purchase price
                        const averagePrice = getAveragePrice(buyTradesToSettle[symbol]);
                        trade.PnL = parseFloat(((entry.Price - averagePrice) * settledShares).toFixed(2));
                        trade.OpenPrice = averagePrice;
                    }
                    trade.Commission = (entry.Commission ? (entry.Commission * settledShares) : 0) + (buyTrade.Commission ? (buyTrade.Commission * settledShares) : 0) ;
                    trades.push(trade);

                    if(sharesToSettle === availableShares) {
                        buyTradesToSettle[symbol].shift();
                        for(let i = 0; i < tradesSettled; i += 1) {
                            buyTradesToSettle[symbol].shift()
                        }
                        tradesSettled = 0;
                        break;
                    } else if(sharesToSettle > availableShares) {
                        tradesSettled += 1;
                        if(buyTradesToSettle[symbol].length === tradesSettled) {
                            let totalSharesSettled = 0;
                            for(let i = 0; i < tradesSettled - 1; i += 1) {
                                totalSharesSettled += buyTradesToSettle[symbol][i].Volume
                                buyTradesToSettle[symbol].shift()
                            }
                            totalSharesSettled += settledShares;
                            buyTradesToSettle[symbol].shift();
                            tradesSettled = 0;
                            const updatedEntry = {...entry}
                            updatedEntry.Volume -= totalSharesSettled;
                            sellTradesToSettle[symbol].push(updatedEntry);
                        } else {
                            sharesToSettle -= settledShares;
                        }
                    } else if(sharesToSettle < availableShares) {
                        buyTradesToSettle[symbol][index].Volume -= settledShares;
                        for(let i = 0; i < tradesSettled; i += 1) {
                            buyTradesToSettle[symbol].shift()
                        }
                        tradesSettled = 0;
                        break;
                    }
                    index += 1;
                }

            }
        }
        return trades;
    }

    const adjustTradeValues = (trades) => {
        return trades.map(trade => {

            const symbol = trade.SecurityId;
            const keys = Object.keys(tickSizeData);

            for(const indexSymbol of keys) {
                if(symbol.startsWith(indexSymbol)) {

                    let tickData = tickSizeData[indexSymbol];
                    trade.PnL = (trade.PnL / tickData.tickSize) * tickData.tickValue;
                }
            }
            return trade;
        });
    }

    const uploadTrades = async (trades) => {

        const selectedAccountIndex = subAccountNames.indexOf(selectedSubAccount?.name);
        const subAccountId = subAccounts[selectedAccountIndex].id;

        const response = await sendPost(Urls.UploadTrades, {
            subUserId: subAccountId,
            TimeZone: selectedTimeZone.timezone,
            data: trades
        }, true, 'application/json', true);

        if(response.error) {
            toast.error(response.error);
        } else {
            toast.success('Trades Added Successfully');
        }
    }

    const handleAddTradeClick = async () => {
        setIsLoading(true);
        let formattedData = undefined;
        let data = undefined;

        if(!file) {
            toast.error('Select file to upload')
            setIsLoading(false);
            return;
        }

        try {
            data = await parseFile(file, selectedBroker);
            formattedData = formatData(data, selectedBroker, selectedTimeZone);
            console.log(formattedData);
        }
        catch (error) {
            toast.error(error.message);
            console.log(error)
            setIsLoading(false)
            return;
        }

        if(!formattedData || formattedData.length === 0) {
            toast.error('No data retrieved from the file. Please ensure that file contains parsable data')
            setIsLoading(false);
            return;
        }

        let trades = []
        trades = convertToTrades(formattedData);
        trades = adjustTradeValues(trades);
        console.log(trades);
        await uploadTrades(trades);
        if(selectedSubAccount) {
            dispatch({type: 'CLEAR_INSIGHT_SUB_ACCOUNT', payload: {insightsSubAccount: selectedSubAccount.id}});
        }
        setIsLoading(false);
    }

    return (
        <div
            className={`manual-import tab-content-item active`}>
            <div className="controls">
                <div className="input-group">
                    <label>{t("add_trade_page.fields.select_account")}</label>
                    <Select
                        onChange={index => setSelectedSubAccount(subAccountNames[index])}
                        options={subAccountNames}
                        selectedIndex={subAccountNames.indexOf(selectedSubAccount?.name)}/>
                </div>
                <div className="input-group">
                    <label>{t("add_trade_page.fields.select_broker")}</label>
                    <Select
                        options={brokers}
                        selectedIndex={brokers.indexOf(selectedBroker)}
                        onChange={(index) => {
                            setSelectedBroker(brokers[index])
                        }}
                    />
                </div>

                <div className="input-group">
                    <label>{t("add_trade_page.fields.timezone")}</label>
                    <div className="note">
                        {t("add_trade_page.fields.timezone_message")}
                    </div>
                    <Select
                        options={timeZones}
                        selectedIndex={timeZoneNames.indexOf(selectedTimeZone?.timezone)}
                        onChange={(index) => setSelectedTimeZone(timeZones[index])}
                        onOptionRender={(item) => {
                            return (
                                <div>
                                    ({item.offset}) {item.timezone}
                                </div>
                            )
                        }}
                        onSelectedOptionRender={(item) => {
                            return (
                                <div>
                                    ({item.offset}) {item.timezone}
                                </div>
                            )
                        }}
                    />
                </div>

                <div className="input-group">
                    {file === undefined ? (
                        <div>
                            <label>{t("add_trade_page.fields.upload.title")}</label>
                            <div className="upload-ctrl">
                                <input
                                    type="file"
                                    onChange={handleFileUpload}
                                    accept={selectedBroker === Brokers.Atas ? ".cdb" : '.csv'}
                                    style={{display: "none"}}
                                    id="fileInput"
                                />
                                <IoCloudUploadOutline onClick={handleBrowseClick}/>
                                <div className="title">
                                    {file ? file.name : t("add_trade_page.fields.upload.description")}
                                </div>
                                <div className="btn btn-secondary-outline" onClick={handleBrowseClick}>
                                    {t("add_trade_page.fields.upload.button")}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h2>Uploaded File</h2>
                            <div className="file-item">
                                <div className="icon">
                                    <IoFileTrayFull/>
                                </div>
                                <div className="name">
                                    {file ? file.name : ''}
                                </div>
                                <div className="close-icon" onClick={removeFile}>
                                    <MdClose/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {
                    isLoading ?
                        <Loader height={25} barWidth={3}/> :
                        <div className="btn btn-primary w-100" onClick={handleAddTradeClick}>
                            {t("add_trade_page.fields.add_button")}
                        </div>
                }
            </div>
            <div className="vr"></div>
            <>
                {selectedBroker === Brokers.Rithmic ? (
                    <div className="guide">
                        <div className="name">{selectedBroker}</div>
                        <div className="note">{t("add_trade_page.manualImport.guides.rithmic.help_note")}</div>
                        <div className="video">
                            <video src={'/videos/rithmic-trade-import.mp4'} controls />
                        </div>
                        <div className="title">{t("add_trade_page.manualImport.guides.rithmic.title")}</div>
                        <div className="note">{t("add_trade_page.manualImport.guides.rithmic.note")}</div>
                        <div className="steps">
                            {
                                getArray(t("add_trade_page.manualImport.guides.rithmic.steps.length")).map(number => {
                                    return (
                                        <div className="step">
                                            <MdChevronRight/>
                                            <div>{t(`add_trade_page.manualImport.guides.rithmic.steps.${number}`)}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : selectedBroker === Brokers.CQG ? (
                    <div className="guide">
                        <div className="name">{selectedBroker}</div>
                        <div className="note">{t("add_trade_page.manualImport.guides.cqg.help_note")}</div>
                        <div className="title">
                            {t("add_trade_page.manualImport.guides.cqg.title")}
                        </div>
                        <div className="note">
                            {t("add_trade_page.manualImport.guides.cqg.note")}
                        </div>
                        <div className="steps">
                            {
                                getArray(t("add_trade_page.manualImport.guides.cqg.steps.length")).map(number => {
                                    return (
                                        <div className="step">
                                            <MdChevronRight/>
                                            <div>{t(`add_trade_page.manualImport.guides.cqg.steps.${number}`)}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : selectedBroker === Brokers.Atas ? (
                    <div className="guide">
                        <div className="broker-logo">
                            <img src={ATASLogo} alt="ATAS"/>
                        </div>
                        <div className="title">{t("add_trade_page.manualImport.guides.atas.title")}</div>
                        <div className="video">
                            <video src={'/videos/atas-trade-import.mp4'} controls/>
                        </div>
                        <div className="note">{t("add_trade_page.manualImport.guides.atas.note")}</div>
                        <div className="steps">
                            <div className="step">
                                <MdChevronRight/>
                                <div
                                    dangerouslySetInnerHTML={{__html: t(`add_trade_page.manualImport.guides.atas.steps.0`)}}></div>
                            </div>
                            <div className="image">
                                <img src={ATAS1} alt="Atas Step 1"/>
                            </div>
                            <div className="step">
                                <MdChevronRight/>
                                <div
                                    dangerouslySetInnerHTML={{__html: t(`add_trade_page.manualImport.guides.atas.steps.1`)}}></div>
                            </div>
                            <div className="image">
                                <img src={ATAS2} alt="Atas Step 2"/>
                            </div>
                            <div className="step">
                                <MdChevronRight/>
                                <div
                                    dangerouslySetInnerHTML={{__html: t(`add_trade_page.manualImport.guides.atas.steps.2`)}}></div>
                            </div>
                        </div>
                    </div>
                ) : selectedBroker === Brokers.NinjaTrader ? (
                    <div className="guide">
                        <div className="name">{selectedBroker}</div>
                        <div className="note">{t("add_trade_page.manualImport.guides.ninjaTrader.help_note")}</div>
                        <div className="title">{t("add_trade_page.manualImport.guides.ninjaTrader.title")}</div>
                        <div className="note">{t("add_trade_page.manualImport.guides.ninjaTrader.note")}</div>
                        <div className="steps">
                            {
                                getArray(t("add_trade_page.manualImport.guides.ninjaTrader.steps.length")).map(number => {
                                    return (
                                        <div className="step">
                                            <MdChevronRight/>
                                            <div>{t(`add_trade_page.manualImport.guides.ninjaTrader.steps.${number}`)}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : (
                    <div className="guide">

                    </div>
                )}
            </>
        </div>
    )
}

export default ManualImport;