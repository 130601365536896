import { MdClose } from "react-icons/md";
import "../../styles/tags-modal.scss";
import Select from "../selectcontrol";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import {useTheme} from "../../Providers/ThemeContext";
import {useTranslation} from "react-i18next";

const CommissionEditModal = ({isOpen, handleSave, handleCloseModal, mode, commissionData, symbolsData, setCommissionData, error, isLoading}) => {

    const [canCreate, setCanCreate] = useState(false);
    const theme = useTheme()
    const INSTRUMENTS = ["All", "Forex", "Futures", "Stocks", "Indices", "Crypto"]
    const DISABLED_INSTRUMENTS = ['Forex', 'Stocks', 'Indices', 'Crypto'];
    const MODES = ["per Contract", "per Share", "per Execution"];
    const {t} = useTranslation();
    const commissionModeCoverUps = {
        "per Contract": t("coverUps.commissionMode.perContract"),
        "per Share": t("coverUps.commissionMode.perShare"),
        "per Execution": t("coverUps.commissionMode.perExecution"),
    }
    const executionCoverUps = {
        "on entry executions": t("coverUps.commissionExecution.onEntry"),
        "on exit executions": t("coverUps.commissionExecution.onExit"),
        "on all executions": t("coverUps.commissionExecution.onAll"),
    }
    const APPLY = ["on entry executions", "on exit executions", "on all executions"]
    const [symbols, setSymbols] = useState([]);

    useEffect(() => {
        if (mode === "Add") {
            if (commissionData && commissionData.instrument && commissionData.symbol && commissionData.mode && commissionData.apply && commissionData.commission > 0) {
                setCanCreate(true);
            } else {
                setCanCreate(false);
            }
        } else {
            if (commissionData && commissionData.instrument && commissionData.symbol && commissionData.mode && commissionData.apply && commissionData.commission > 0) {
                setCanCreate(true);
            } else {
                setCanCreate(false);
            }
        }
    }, [commissionData]);

    useEffect(() => {
        if(commissionData && commissionData.instrument && commissionData.instrument !== 'All') {
            setSymbols(symbolsData[commissionData.instrument] ? ['All', ...symbolsData[commissionData.instrument]] : []);
        }
        else if (commissionData && commissionData.instrument && commissionData.instrument === 'All') {
            let symbols = ['All'];
            for(const instrument of Object.keys(symbolsData)) {
                symbols = [...symbols, ...symbolsData[instrument]];
            }
            setSymbols(symbols);
        }
    }, [commissionData]);

    const onChange = (field, value) => {
        const newCommissionData = {
            ...commissionData,
            [field]: value
        }
        setCommissionData(newCommissionData)
    }

    return (
        <div>
            {isOpen && (
                <div className="modal-overlay">
                    <div className={`modal modal-sm ${theme.getThemeClass()}`}>
                        <div className="modal-header">
                            <div className="title">
                                {mode === 'Add' ? t("settings.commissionModal.title.add") : t("settings.commissionModal.title.edit")}
                            </div>
                            <div className="close-btn" onClick={handleCloseModal}>
                                <MdClose />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="type">{t("settings.commissionModal.fields.instrument")}</label>
                                <Select
                                    options={INSTRUMENTS}
                                    selectedIndex={INSTRUMENTS.indexOf(commissionData?.instrument)}
                                    itemName="Instrument"
                                    disabledValues={DISABLED_INSTRUMENTS}
                                    onChange={index => onChange('instrument', INSTRUMENTS[index])}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="type">{t("settings.commissionModal.fields.symbol")}</label>
                                <Select
                                    isSearchable={true}
                                    options={symbols}
                                    selectedIndex={symbols.indexOf(commissionData?.symbol)}
                                    itemName="Symbol"
                                    onChange={index => onChange('symbol', symbols[index])}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="type">{t("settings.commissionModal.fields.mode")}</label>
                                <Select
                                    coverUpValues={commissionModeCoverUps}
                                    options={MODES}
                                    selectedIndex={MODES.indexOf(commissionData?.mode)}
                                    itemName="Mode"
                                    onChange={index => onChange('mode', MODES[index])}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="type">{t("settings.commissionModal.fields.apply")}</label>
                                <Select
                                    coverUpValues={executionCoverUps}
                                    options={APPLY}
                                    selectedIndex={APPLY.indexOf(commissionData?.apply)}
                                    itemName="Application"
                                    onChange={index => onChange('apply', APPLY[index])}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">{t("settings.commissionModal.fields.commission")}:</label>
                                <input
                                    type="number"
                                    id="commission"
                                    value={commissionData?.commission}
                                    onChange={(e) => onChange('commission', parseFloat(e.currentTarget.value))}
                                    placeholder='Enter Commission'
                                    className="w-full"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">{t("settings.commissionModal.fields.fee")}:</label>
                                <input
                                    type="number"
                                    id="fee"
                                    value={commissionData?.fee}
                                    onChange={(e) => onChange('fee', parseFloat(e.currentTarget.value))}
                                    placeholder="Enter fee"
                                    className="w-full"
                                />
                            </div>
                            {error ? <div className="error">{error}</div> : <></>}
                        </div>
                        <div className="modal-footer">
                            <button className="danger-btn" onClick={handleCloseModal}>
                                {t("settings.commissionModal.fields.buttons.cancel")}
                            </button>
                            {isLoading ? (
                                <Loader height={20} barWidth={3}/>
                            ) : (
                                <button className={`primary-btn ${canCreate ? "" : "disabled"}`} onClick={() => {
                                    if (canCreate) { handleSave(); } }}>
                                    {mode === "Add" ? t("settings.commissionModal.fields.buttons.create") : t("settings.commissionModal.fields.buttons.save") }
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommissionEditModal;
