import PropTypes from "prop-types";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import {useTheme} from "../../Providers/ThemeContext";

const TriggerContext = createContext(null);
const ContentContext = createContext(null);

const ContextMenuTrigger = ({children}) => {
    return (
        <TriggerContext.Provider value={children}>
            <div className={'trigger'}>
                {children}
            </div>
        </TriggerContext.Provider>
    )
}

const ContextMenuContent = ({children}) => {
    return (
        <ContentContext.Provider value={children}>
            <div className="content">
                {children}
            </div>
        </ContentContext.Provider>
    )
}

const ContextMenu = ({children}) => {

    const [isShown, setIsShown] = useState(false);
    const contextMenuRef = useRef();
    const {_, isComponentVisible, setIsComponentVisible } = useOutsideClick(false, contextMenuRef);

    useEffect(() => {
        if(contextMenuRef && contextMenuRef.current) {
            const trigger = contextMenuRef.current.querySelector('.trigger');
            if(trigger) {
                trigger.addEventListener('contextmenu', handleContextMenu)

                return () => {
                    trigger.removeEventListener('contextmenu', handleContextMenu);
                }
            }
        }
    }, []);

    const handleContextMenu = e => {
        e.preventDefault();
        setIsComponentVisible(true);
        setIsShown(prev => !prev)
    }

    useEffect(() => {
        if(!isComponentVisible) {
            setIsShown(false);
        }
    }, [isComponentVisible]);

    const theme = useTheme();

    return (
        <div className={`context-menu ${theme.getThemeClass()} ${isShown ? 'show' : 'hide'}`} ref={contextMenuRef}>
            {children}
        </div>
    )
}

ContextMenu.Trigger = ContextMenuTrigger;
ContextMenu.Content = ContextMenuContent;

ContextMenu.propTypes = {
    children: PropTypes.node,
    isShown: PropTypes.bool
}

export default ContextMenu;